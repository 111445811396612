define("discourse/plugins/discourse-custom-ap-profile/discourse/connectors/composer-fields/diaper-topic", ["exports", "I18n", "discourse/models/category", "discourse/plugins/discourse-custom-ap-profile/discourse/lib/discourse_custom_ap_profile", "discourse/lib/ajax", "discourse-common/lib/get-owner", "@ember/template", "@ember/object", "@ember/runloop"], function (_exports, _I18n, _category, _discourse_custom_ap_profile, _ajax, _getOwner, _template, _object, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = dt7948.p({
    setupComponent(args, component) {
      // console.log('setupComponent from connectors\\composer-fields\\diaper-js.es6');
      component.set("isCAPPAvailable", (0, _discourse_custom_ap_profile.isCAPPAvailable)(this.currentUser));
      const topic = args.topic;
      if (component.isCAPPAvailable && topic !== null) {
        // console.log('args:');
        // console.log(args);
        // console.log('component:');
        // console.log(component);

        // const model = args.composer;
        const model = args.model;
        // console.log('model:');
        // console.log(model);

        (0, _discourse_custom_ap_profile.showLog)({
          args,
          topic,
          model
        }, 'args, topic and model from composer-fields\\diaper-topic.js.es6');
        console.log({
          args,
          topic,
          model
        });
        const siteSettings = component.siteSettings;
        const isTopicFirstPost = model.topicFirstPost;
        const fetchManufacturerTopics = function (url) {
          return (0, _ajax.ajax)(url).then(content => {
            // Filter out hidden topics
            const visibleTopics = content.topic_list.topics.filter(topic => topic.visible);
            const topics = visibleTopics.map(topic => ({
              title: topic.title,
              slug: topic.slug,
              id: topic.id
            }));
            if (content.topic_list.more_topics_url) {
              // If more topics are available, recursively fetch them after a timeout
              return new Promise(resolve => {
                setTimeout(() => {
                  fetchManufacturerTopics(content.topic_list.more_topics_url).then(moreTopics => {
                    // Concatenate the current visible topics with the fetched additional visible topics
                    resolve({
                      topics: topics.concat(moreTopics.topics)
                    });
                  });
                }, 200); // 200 milliseconds timeout
              });
            } else {
              // No more topics, just return the current visible topics
              return {
                topics
              };
            }
          }).catch(error => {
            console.error('Error fetching topics:', error);
          });
        };
        if (isTopicFirstPost) {
          const isAdmin = this.currentUser.admin;
          const isModerator = this.currentUser.moderator;
          const isSelf = args.model && this.currentUser && args.model.id === this.currentUser.id;
          const isStaff = this.currentUser.staff;

          // if (model.get('action') === 'privateMessage') {
          //   model.disableTitleInput = !isStaff || true;
          //   //skal benytte custom_ap_profile_min_age_pm og flyttes
          // }

          //Hvad handler diskutionen om i korte træk, når man skriver en privat besked, dette bør ændres
          //Hvad handler beskeden om i korte træk, er formentligt bedre...

          // const CAPPEnabledGroups = component.siteSettings.custom_ap_profile_enabled_groups;
          const diaper_brandgroups = ('' + siteSettings.custom_ap_profile_diaper_brandgroups).split("|").map(brandgroup => {
            return {
              name: brandgroup,
              value: brandgroup
            };
          }).sort((a, b) => a.name > b.name ? 1 : a.name < b.name ? -1 : 0);
          // const diaper_manufacturers = ('' + siteSettings.custom_ap_profile_diaper_manufacturers).split("|").map((manufacturer) => {
          //   return { name: manufacturer, value: manufacturer };
          // }).sort((a,b) => ((a.name > b.name) ? 1 : (a.name < b.name) ? -1 : 0));;

          let manufacturers_array = ('' + siteSettings.custom_ap_profile_diaper_manufacturers).split("|");
          // console.log(manufacturers_array.length);
          // console.log(manufacturers_array);

          const library_manufacturers = component.siteSettings.custom_ap_profile_diaper_library_manufacturers;
          console.log('library_manufacturers');
          console.log(library_manufacturers);
          if (typeof library_manufacturers !== 'undefined' && library_manufacturers !== '') {
            console.log('searching library_manufacturers');
            let filter = 'c/library_manufacturers/' + library_manufacturers;
            const url = '/c/library_manufacturers/' + library_manufacturers + '/none.json';
            fetchManufacturerTopics(url).then(result => {
              const visibleManufacturerTopics = result.topics.map(item => {
                console.log('item');
                console.log(item);
                if (!manufacturers_array.includes(item.title.split(' :')[0])) {
                  manufacturers_array.push(item.title.split(' :')[0]);
                }
              });
              const diaper_manufacturers = manufacturers_array.map(manufacturer => {
                return {
                  name: manufacturer,
                  value: manufacturer
                };
              }).sort((a, b) => a.name > b.name ? 1 : a.name < b.name ? -1 : 0);
              component.set('diaper_manufacturers', diaper_manufacturers);
              component.set('diaper_manufacturer_topics', result.topics);
            });

            // model.store.findFiltered('topicList', { filter, params: {}}).then(list => {
            // // model.store.findAll('topicList').then(list => {
            //   list.topics.slice(1).forEach(function(item) {

            //     if (!manufacturers_array.includes(item.title.split(' :')[0])) {
            //       manufacturers_array.push(item.title.split(' :')[0]);
            //     }
            //   });
            //   const diaper_manufacturers = manufacturers_array.map((manufacturer) => {
            //     return { name: manufacturer, value: manufacturer };
            //   }).sort((a,b) => ((a.name > b.name) ? 1 : (a.name < b.name) ? -1 : 0));;
            //   component.set('diaper_manufacturers', diaper_manufacturers);
            // });
          }

          // const diaper_manufacturers = manufacturers_array.map((manufacturer) => {
          //   return { name: manufacturer, value: manufacturer };
          // }).sort((a,b) => ((a.name > b.name) ? 1 : (a.name < b.name) ? -1 : 0));;

          // console.log('diaper_manufacturers');
          // console.log(diaper_manufacturers);

          const diaper_protections = ('' + siteSettings.custom_ap_profile_diaper_protections).split("|");
          const diaper_closure_types = siteSettings.custom_ap_profile_diaper_closure_types;
          const diaper_features = siteSettings.custom_ap_profile_diaper_features;
          const diaper_sizes = siteSettings.custom_ap_profile_diaper_sizes;
          const diaper_sizes_adult = siteSettings.custom_ap_profile_diaper_sizes_adult;
          const diaper_sizes_child = siteSettings.custom_ap_profile_diaper_sizes_child;
          const diaper_sizes_teen = siteSettings.custom_ap_profile_diaper_sizes_teen;
          const diaper_types = siteSettings.custom_ap_profile_diaper_types;
          const sizes = ('' + diaper_sizes).split("|").map(size => {
            return {
              name: _I18n.default.t("custom_ap_profile.diaper_size_" + size).substr(0, 1) === "[" ? size : _I18n.default.t("custom_ap_profile.diaper_size_" + size),
              value: size
            };
          });
          const sizes_adult = ('' + diaper_sizes_adult).split("|").map(size => {
            return {
              name: _I18n.default.t("custom_ap_profile.diaper_size_" + size).substr(0, 1) === "[" ? size : _I18n.default.t("custom_ap_profile.diaper_size_" + size),
              value: size
            };
          });
          const sizes_child = ('' + diaper_sizes_child).split("|").map(size => {
            return {
              name: _I18n.default.t("custom_ap_profile.diaper_size_" + size).substr(0, 1) === "[" ? size : _I18n.default.t("custom_ap_profile.diaper_size_" + size),
              value: size
            };
          });
          const sizes_teen = ('' + diaper_sizes_teen).split("|").map(size => {
            return {
              name: _I18n.default.t("custom_ap_profile.diaper_size_" + size).substr(0, 1) === "[" ? size : _I18n.default.t("custom_ap_profile.diaper_size_" + size),
              value: size
            };
          });
          const protections = diaper_protections.map(at => {
            return {
              name: _I18n.default.t("custom_ap_profile.diaper_protection_" + at).substr(0, 1) === "[" ? at : _I18n.default.t("custom_ap_profile.diaper_protection_" + at),
              value: at
            };
          });
          const closure_types = ('' + diaper_closure_types).split("|").map(ct => {
            return {
              name: _I18n.default.t("custom_ap_profile.diaper_closure_type_" + ct).substr(0, 1) === "[" ? ct : _I18n.default.t("custom_ap_profile.diaper_closure_type_" + ct),
              value: ct
            };
          }).sort((a, b) => a.name > b.name ? 1 : a.name < b.name ? -1 : 0);
          const features = ('' + diaper_features).split("|").map(f => {
            return {
              name: _I18n.default.t("custom_ap_profile.diaper_features_" + f).substr(0, 1) === "[" ? f : _I18n.default.t("custom_ap_profile.diaper_features_" + f),
              value: f
            };
          }).sort((a, b) => a.name > b.name ? 1 : a.name < b.name ? -1 : 0);
          const diaper_target_users = 'adult|child|teen'.split("|").map(type => {
            return {
              name: _I18n.default.t("custom_ap_profile.diaper_target_user_" + type).substr(0, 1) === "[" ? type : _I18n.default.t("custom_ap_profile.diaper_target_user_" + type),
              value: type
            };
          }).sort((a, b) => a.name > b.name ? 1 : a.name < b.name ? -1 : 0);
          const diaper_types_list = ('' + diaper_types).split("|").map(type => {
            return {
              name: _I18n.default.t("custom_ap_profile.diaper_type_" + type).substr(0, 1) === "[" ? type : _I18n.default.t("custom_ap_profile.diaper_type_" + type),
              value: type
            };
          }).sort((a, b) => a.name > b.name ? 1 : a.name < b.name ? -1 : 0);
          const CAPPEnabledGroups = siteSettings.custom_ap_profile_enabled_groups;
          const diaper_library = siteSettings.custom_ap_profile_diaper_library;
          const story_characters_categories = siteSettings.story_characters_categories;

          // console.log(model.topic);
          // console.log(model.category_id);
          // const category_id = (typeof(model.topic !== 'undefined') ? model.category_id : model._categoryid;
          const category_id = model.categoryId;
          const isDiaperTopic = diaper_library == category_id;
          // const isStoryCategory = story_characters_categories.includes(category_id);

          const staff_min_title = siteSettings.custom_ap_profile_staff_min_post_length;
          const staff_min_post = siteSettings.custom_ap_profile_staff_min_title_length;
          // const stories_min_post = siteSettings.custom_ap_profile_stories_min_post_length;
          // const stories_min_title = siteSettings.custom_ap_profile_stories_min_title_length;

          const diapers_min_title = siteSettings.custom_ap_profile_diaper_library_min_post_length;
          const diapers_min_post = siteSettings.custom_ap_profile_diaper_library_min_title_length;
          const checkDiaperManufacturer = function () {
            if (model.diaper_manufacturer !== null) {
              // if (typeof library_manufacturers !== 'undefined' && library_manufacturers !== '') {
              //   // console.log('searching 2library_manufacturers');
              //   let filter = 'c/library_manufacturers/' + library_manufacturers;

              //   model.set('diaper_manufacturer_id', null);
              //   model.store.findFiltered('topicList', { filter, params: {}}).then(list => {
              //     list.topics.slice(1).forEach(function(item) {
              //       if (model.diaper_manufacturer === item.title.split(' :')[0]) {
              //         model.set('diaper_manufacturer_id', item.id);
              //       }
              //     });
              //   });
              // }

              if (typeof component.diaper_manufacturers !== 'undefined') {
                component.diaper_manufacturer_topics.forEach(function (item) {
                  if (model.diaper_manufacturer === item.title.split(' :')[0]) {
                    model.set('diaper_manufacturer_id', item.id);
                  }
                });
              }
            }
          };
          checkDiaperManufacturer();
          const checkDiaperType = function () {
            if (typeof model.diaper_type !== 'undefined') {
              switch (model.diaper_type) {
                case 'belt':
                  if (model.diaper_closure_type !== 'velcro-belt') {
                    model.set('diaper_closure_type', 'velcro-belt');
                  }
                  break;
                case 'pant':
                case 'pullup':
                  model.set('diaper_closure_type', 'elastic');
                  break;
              }
            }
          };
          const checkDiaperClosureType = function () {
            if (typeof model.diaper_closure_type !== 'undefined') {
              if (model.diaper_closure_type === 'velcro-belt' && model.diaper_type !== 'belt') {
                model.set('diaper_type', 'belt');
              }
            }
          };
          const checkDiaperTargetUsers = function () {
            if (typeof model.diaper_target_user !== 'undefined') {
              component.set('diaper_target_user', model.diaper_target_user);
              let isChildDiaper = false;
              let selectedSizes = [];
              if (model.diaper_target_user.includes('adult')) {
                selectedSizes = selectedSizes.concat(diaper_sizes_adult.split('|'));
              }
              if (model.diaper_target_user.includes('child')) {
                selectedSizes = selectedSizes.concat(diaper_sizes_child.split('|'));
                isChildDiaper = true;
              }
              if (model.diaper_target_user.includes('teen')) {
                selectedSizes = selectedSizes.concat(diaper_sizes_teen.split('|'));
                isChildDiaper = true;
              }
              component.set('isChildDiaper', isChildDiaper);
              const selected_sorted = diaper_sizes.split("|").filter(size => selectedSizes.includes(size));

              // const translatedSizes = selected_sorted.map((size) => {
              //     return { name: I18n.t("custom_ap_profile.diaper_size_" + size).substr(0,1) === "[" ? size : I18n.t("custom_ap_profile.diaper_size_" + size), value: size };
              //   });

              // const selected_sorted = selectedSizes.filter(size => sizes.map(size => size.value).includes(size));
              const translatedSizes = selected_sorted.map(size => ({
                name: _I18n.default.t("custom_ap_profile.diaper_size_" + size).substr(0, 1) === "[" ? size : _I18n.default.t("custom_ap_profile.diaper_size_" + size),
                value: size
              }));

              // If no options are present, use the master array of sizes
              component.set('sizes', translatedSizes.length > 0 ? translatedSizes : sizes);
            } else {
              // If diaper_target_user is not present or not valid, use the master array of sizes
              component.set('sizes', sizes);
            }
          };
          const setComposerTitle = function () {
            const title_input = document.querySelector(".title-input");
            let title_inputf = null;
            if (title_input !== null) {
              title_inputf = title_input.querySelector("input");
            }
            const action_title = document.querySelector('.composer-action-title').querySelector(".action-title");
            if (title_input !== null && title_inputf !== null && title_input.length > 0 && title_inputf.length > 0) {
              //perhaps model.titlePlaceholder is better than innerHTML
              if (component.get('isDiaperTopic')) {
                title_inputf.placeholder = _I18n.default.t("custom_ap_profile.instructions_diaper_name");
                if (model.creatingTopic) {
                  action_title.innerHTML = _I18n.default.t("custom_ap_profile.composer_action_title_create_diaper");
                } else {
                  action_title.innerHTML = _I18n.default.t("custom_ap_profile.composer_action_title_modify_diaper");
                }
              } else {
                title_inputf.placeholder = _I18n.default.t("composer.title_or_link_placeholder");
                action_title.innerHTML = _I18n.default.t("topic.create_long");
              }
            }
          };
          const checkFullscreen = function () {
            console.log('checkFullscreen');
            console.log(model);
            const detc = document.querySelector(".d-editor-textarea-column");
            detc.classList.remove('diaper_basics');
            detc.classList.remove('diaper_details');
            detc.classList.remove('diaper_packaging');
            detc.classList.remove('diaper_storage');
            detc.classList.remove('diaper_json');
            detc.classList.remove('diaper_description');
            if (component.datagroup && !model.viewFullscreen) {
              detc.classList.add('diaper_' + component.datagroup);
            }
          };
          const checkDataGroup = function () {
            const detc = document.querySelector(".d-editor-textarea-column");
            if (component.datagroup) {
              detc.classList.remove('diaper_basics');
              detc.classList.remove('diaper_details');
              detc.classList.remove('diaper_packaging');
              detc.classList.remove('diaper_storage');
              detc.classList.remove('diaper_json');
              detc.classList.remove('diaper_description');
              detc.classList.add('diaper_' + component.datagroup);
            }
            // if (typeof(model.topic) === 'undefined') { 
            //creatingTopic=true could also be used
            if (!model.creatingTopic && typeof model.topic !== 'undefined' && typeof model.diaperdata === 'undefined') {
              model.diaperdata = model.topic.diaperdata;
            }
            (0, _runloop.later)(function () {
              if (model.diaperdata) {
                const sizeGroups = document.querySelectorAll(".size-group");
                // console.log(typeof(sizeGroups));
                // console.log(sizeGroups);
                if (sizeGroups) {
                  sizeGroups.forEach(sizeGroup => {
                    const size = sizeGroup.querySelector("header").textContent;
                    const jsonData = JSON.parse(model.diaperdata);
                    const sizeData = jsonData.find(item => item.size === size);
                    if (sizeData) {
                      for (const prop in sizeData.data) {
                        const value = sizeData.data[prop];
                        const input = sizeGroup.querySelector("input." + prop);
                        if (input) {
                          input.value = value;
                        }
                      }
                    }
                    const inputs = sizeGroup.querySelectorAll("input");
                    inputs.forEach(input => {
                      input.disabled = false;
                    });
                  });
                }
              }
              //enable inputs
              const sizeGroups = document.querySelectorAll(".size-group");
              sizeGroups.forEach(sizeGroup => {
                const inputs = sizeGroup.querySelectorAll("input");
                inputs.forEach(input => {
                  input.disabled = false;
                });
              });
            }, 100);
          };
          // console.warn('model.minimumTitleLength');
          // console.log(model.minimumTitleLength);

          // Test deaktivering 240121
          // if (isStaff) {
          //   if (staff_min_title < model.minimumTitleLength)
          //     model.minimumTitleLength = staff_min_title;
          //   if (staff_min_post < model.minimumPostLength)
          //     model.minimumPostLength = staff_min_post;
          // }

          const checkCategory = function () {
            const diaper_library = siteSettings.custom_ap_profile_diaper_library;
            const isDiaperTopic = diaper_library === '' + model.categoryId;
            component.set('isDiaperTopic', isDiaperTopic);
            const detc = document.querySelector(".d-editor-textarea-column");
            if (!isDiaperTopic) {
              detc.classList.remove('diaper_basics');
              detc.classList.remove('diaper_details');
              detc.classList.remove('diaper_packaging');
              detc.classList.remove('diaper_storage');
              detc.classList.remove('diaper_json');
              detc.classList.remove('diaper_description');
            } else {
              detc.classList.add('diaper_basics');
              component.set('datagroup', 'basics');
            }
            setComposerTitle();
          };
          const checkDiaperSizes = function () {
            const diaper_sizes_sorted = diaper_sizes.split("|").filter(size => model.diaper_sizes.includes(size));
            model.set('diaper_sizes', diaper_sizes_sorted);
            // console.log(model);
            // Denne giver fejl ved nyt emne
            // model.set('topic.diaper_sizes', diaper_sizes_sorted);
            model.set('diaper_sizes', diaper_sizes_sorted);
            if (model.diaperdata) {
              const jsonData = JSON.parse(model.diaperdata);

              // Filter the data to keep only sizes present in model.diaper_sizes
              const filteredData = jsonData.filter(item => model.diaper_sizes.includes(item.size));

              // Update model.diaperdata with the filtered data
              const jsonString = JSON.stringify(filteredData, null, 2);
              // model.diaperdata = jsonString;
              model.set('diaperdata', jsonString);
              component.set('diaperdata', jsonString);
            }
          };
          const checkTitle = function () {
            const diaper_brandgroup = model.get('diaper_brandgroup');
            const diaper_manufacturer = model.get('diaper_manufacturer');
            if (model.title !== "" && typeof model.title !== 'undefined') {
              if (typeof diaper_brandgroup === 'undefined' || diaper_brandgroup === "" || diaper_brandgroup === null && model.get('diaper_brandgroup') !== '') {
                const diaper_brandgroup = diaper_brandgroups.filter(item => ('' + model.title).toLowerCase().indexOf(item.name.toLowerCase()) !== -1);
                // if (diaper_brandgroup.length === 1) {
                //   model.set('diaper_brandgroup', diaper_brandgroup[0].value);
                // }
                if (diaper_brandgroup.length > 0) {
                  // Initialize a variable to store the longest brand group
                  let diaper_brandgroup_found = diaper_brandgroup[0];

                  // Loop through the filtered diaper_brandgroup array
                  for (const brandGroup of diaper_brandgroup) {
                    if (brandGroup.name.length > diaper_brandgroup_found.name.length) {
                      // Update the diaper_brandgroup_found if a longer one is found
                      diaper_brandgroup_found = brandGroup;
                    }
                  }
                  model.set('diaper_brandgroup', diaper_brandgroup_found.value);
                }
              }
              if (typeof diaper_manufacturer === 'undefined' || diaper_manufacturer === "" || diaper_manufacturer === null) {
                // const diaper_manufacturer = diaper_manufacturers.filter((item) =>
                //   ('' + model.title).toLowerCase().indexOf(item.name.toLowerCase()) !== -1
                // );
                if (component.diaper_manufacturers) {
                  component.diaper_manufacturers.forEach(function (item) {
                    if (('' + model.title).toLowerCase().indexOf(item.name.toLowerCase()) !== -1) {
                      // console.log(item);
                      // model.set('diaper_manufacturer', diaper_manufacturer[0].value);
                      model.set('diaper_manufacturer', item.value);
                    }
                  });
                }
              }
            }
          };
          checkTitle();
          component.setProperties({
            isDiaperTopic,
            sizes,
            diaper_brandgroups,
            // diaper_manufacturers,
            diaper_target_users,
            diaper_types_list,
            protections,
            closure_types,
            features
          });
          setComposerTitle();
          if (component.get('isDiaperTopic')) {
            component.datagroup = 'basics';
            checkDataGroup();

            // Test deaktivering 240121
            // if (diapers_min_title < model.minimumTitleLength)
            //   model.minimumTitleLength = diapers_min_title;

            // if (diapers_min_post < model.minimumPostLength)
            //   model.minimumPostLength = diapers_min_post;

            // composer.showCategoryChooser = false;
            // model.disableTitleInput = !isStaff && !model.creatingTopic;
          }
          component.addObserver("model.title", checkTitle);
          component.addObserver("model.viewFullscreen", checkFullscreen);
          component.addObserver("model.diaper_type", checkDiaperType);
          component.addObserver("model.diaper_closure_type", checkDiaperClosureType);
          component.addObserver("model.diaper_target_user", checkDiaperTargetUsers);
          component.addObserver("model.diaper_manufacturer", checkDiaperManufacturer);
          component.addObserver("model.diaper_sizes", checkDiaperSizes);
          component.addObserver("datagroup", checkDataGroup);
          component.addObserver("model.categoryId", checkCategory);
          const topic_fields = {
            'diaper_protection': 'string',
            'diaper_brandgroup': 'string',
            'diaper_closure_type': 'string',
            'diaper_drops': 'string',
            'diaper_features': 'string_array',
            'diaper_manufacturer': 'string',
            'diaper_manufacturer_id': 'integer',
            'diaper_target_user': 'string_array',
            'diaper_type': 'string',
            'diaper_sizes': 'string_array',
            'diaperdata': 'json'
          };

          // console.log(model);
          for (const tf in topic_fields) {
            // console.log('cf1a');
            // If the first post is being edited we need to pass our value from
            // the topic model to the composer model.
            // console.log(tf);
            if (!(0, _discourse_custom_ap_profile.isDefined)(model[tf]) && model.topic && model.topic[tf]) {
              //if (!isDefined(model.get(tf)) && model.topic && model.topic[tf]) {
              // console.warn('setting model val from model.topic');
              // console.warn(model.topic[tf]);
              model.set(tf, model.topic[tf]);
            }

            // console.log('cf1b');
            let props = {
              //            fieldName: tf,
              //            fieldValue: model.get(tf)
              [tf]: model.get(tf)
            };
            // console.log(props);
            // console.log('cf1c');
            if (topic_fields.hasOwnProperty(tf)) {
              const fieldType = topic_fields[tf];
              // console.warn('setting fieldType for ' + tf + '=' + fieldType);
              component.setProperties(Object.assign(props, (0, _discourse_custom_ap_profile.fieldInputTypes)(fieldType)));
              // console.log(component);
            }
            // console.log('cf1d');
          }
          checkDiaperTargetUsers();
        }
      }
      // else
      // {
      // console.log('not active');
      // }
      // console.log('end of composer-fields');
      console.log('all good here');
    },
    asdAction(selected) {
      console.log(selected);
      const sizeGroups = document.querySelectorAll(".size-group");
      sizeGroups.forEach(sizeGroup => {
        const size = sizeGroup.querySelector("header").textContent; // Use "header" as required by your framework
        const inputFields = sizeGroup.querySelectorAll("input");
        const data = {};
        inputFields.forEach(input => {
          const key = input.classList[0];
          // console.log(input.classList[0]);
          const value = input.value;
          data[key] = value;
        });

        // Parse existing data
        let jsonData = [];
        if (this.model.diaperdata) {
          jsonData = JSON.parse(this.model.diaperdata);
        }

        // Find existing size
        const existingIndex = jsonData.findIndex(item => item.size === size);
        if (existingIndex !== -1) {
          // Merge or update the properties in existing data
          const existingData = jsonData[existingIndex].data;
          for (const prop in data) {
            if (data[prop] !== "") {
              // Update only if new value is not empty
              existingData[prop] = data[prop];
            }
          }
        } else {
          jsonData.push({
            size: size,
            data: data
          });
        }

        // Update the stored JSON string
        const jsonString = JSON.stringify(jsonData, null, 2);
        this.model.diaperdata = jsonString;
        //console.log('trying to update component from asdAction');
        //component.set('diaperdata', jsonString);
        console.log(jsonString);
        console.log(this.model.diaperdata);
        console.log('asd is done8');
      });
    }
  }, [["method", "asdAction", [_object.action]]]);
});