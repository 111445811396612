define("discourse/plugins/discourse-custom-ap-profile/discourse/connectors/user-card-post-names/user-card-ap-profile", ["exports", "discourse/models/category", "@ember/component", "discourse-common/utils/decorators", "I18n", "discourse/plugins/discourse-custom-ap-profile/discourse/lib/cakeday", "discourse/models/user", "discourse/plugins/discourse-custom-ap-profile/discourse/lib/discourse_custom_ap_profile"], function (_exports, _category, _component, _decorators, _I18n, _cakeday, _user, _discourse_custom_ap_profile) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    setupComponent(args, component) {
      component.set("isCAPPAvailable", (0, _discourse_custom_ap_profile.isCAPPAvailable)(this.currentUser));
      if (component.isCAPPAvailable) {
        const model = args.user;
        const show_debug_info = model.siteSettings.custom_ap_profile_show_debug_info || true;
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'setupComponent from user-card-post-names\\user-card-ap-profile.js');
        let definedDetails = 0;
        const isStaff = this.currentUser && this.currentUser.staff;
        const isAdmin = this.currentUser && this.currentUser.admin;
        const isSelf = args.user && this.currentUser && args.user.id === this.currentUser.id;
        const isSelfOrStaff = isSelf || isStaff;
        component.set("user.preferencesProfilePath", "/u/" + args.user.get("username_lower") + "/preferences/profile/");
        component.setProperties({
          isSelf,
          isStaff,
          isSelfOrStaff
        });

        // args.user.set("location", 'test');

        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'this');
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, this);
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'args');
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, args);

        // showLog(show_debug_info, 'model');
        // showLog(show_debug_info, model);

        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'component:');
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, component);
        component.set("canEditProfile", isSelfOrStaff);
        component.set("isCakeday", (0, _cakeday.cakeday)(args.user.cakedate));
        component.set("isBirthday", (0, _cakeday.birthday)(args.user.birthdate));
        component.set("isSecret", !(0, _cakeday.celebrate)(args.user));
        component.set("cakedayTitle", (0, _cakeday.cakedayTitle)(args.user, this.currentUser));
        component.set("birthdayTitle", (0, _cakeday.birthdayTitle)(args.user, this.currentUser));
        component.set("secretTitle", (0, _cakeday.secretTitle)(args.user, this.currentUser));
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'isBirthday:');
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, component.user.get("isBirthday"));
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, component.get("user.isBirthday"));
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'aassdd');
        component.set("hasBirthdate", args.user.get("birthdate") !== null);
        component.set("user.hasBirthdate", args.user.get("birthdate") !== null);
        component.set("user.hasplay_age", args.user.get("play_age") !== null);
        component.set("user.hascare_age", args.user.get("care_age") !== null);
        component.set("user.preferencesProfilePath", "/u/" + args.user.get("username_lower") + "/preferences/profile/");
        const ageplayRoles = args.user.get("ageplayRoles");
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, ageplayRoles);
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, typeof ageplayRoles);
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'z1a');
        const birthdate = args.user.get("birthdate");
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'z1a2');
        const care_age = args.user.get("care_age");
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'z1b');
        const country_District = args.user.get("country_District");
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'z1c');
        const diaper_change = args.user.get("diaper_change");
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'z1d');
        const diaper_usage = args.user.get("diaper_usage");
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'z1d2');
        const favorite_diapers = args.user.get("favorite_diapers");
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'z1e');
        const gender = args.user.get("gender");
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'z1fg');
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, gender);
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, typeof gender);
        const handicap = args.user.get("handicap");
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'z1g');
        const interests = args.user.get("interests");
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'z1h');
        const userLocation = args.user.get("location");
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'z1h2');
        const lookingFor = args.user.get("lookingFor");
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, lookingFor);
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'z1h3');
        const name = args.user.get("name");
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'z1i');
        const play_age = args.user.get("play_age");
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'z1j');
        const relationship = args.user.get("relationship");
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'z1k');
        const relationship_with = args.user.get("in_relationship_with");
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, relationship_with);
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'z1l');
        const sexualOrientation = args.user.get("sexualOrientation");
        console.log('sexualOrientation:');
        console.log(sexualOrientation);
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'z1m');
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'z2a');
        const hasAgeplayRole = typeof ageplayRoles !== 'undefined' && ageplayRoles !== null && ageplayRoles !== "" && ageplayRoles.length > 0 && ageplayRoles[0].length > 0;
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, ageplayRoles);
        // showLog(show_debug_info, ageplayRoles !== null);
        // showLog(show_debug_info, ageplayRoles !== "");
        // showLog(show_debug_info, ageplayRoles.length);
        // showLog(show_debug_info, ageplayRoles.length > 0);
        // showLog(show_debug_info, ageplayRoles[0].length > 0);

        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'z2b');
        const hasBirthdate = birthdate !== null;
        const hasCareAge = typeof care_age !== 'undefined' && care_age !== null && care_age !== "";
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'z2c');
        const hasCountry_District = typeof country_District !== 'undefined' && country_District !== null && country_District !== "";
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'z2d');
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, diaper_change);
        let hasDiaperChange = typeof diaper_change !== 'undefined' && diaper_change !== null && diaper_change !== "";
        if (hasDiaperChange) {
          if (diaper_change.length === 1) {
            if (diaper_change[0] === '' || isSelfOrStaff && diaper_change[0] === 'na') {
              hasDiaperChange = false;
            }
          }
        }
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'z2d2');
        let hasDiaperUsage = typeof diaper_usage !== 'undefined' && diaper_usage !== null && diaper_usage !== "";
        if (hasDiaperUsage) {
          if (diaper_usage.length === 1) {
            if (diaper_usage[0] === '' || isSelfOrStaff && diaper_usage[0] === 'na') {
              hasDiaperUsage = false;
            }
          }
        }
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'z2e');
        let hasFavoriteDiaper = typeof favorite_diapers !== 'undefined' && favorite_diapers !== null && favorite_diapers !== "";
        if (hasFavoriteDiaper) {
          if (favorite_diapers.length === 1) {
            if (favorite_diapers[0] === '' || isSelfOrStaff && favorite_diapers[0] === 'na') {
              hasFavoriteDiaper = false;
            }
          }
        }
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'z2f');
        const hasGender = typeof gender !== 'undefined' && gender !== null && gender !== "" && gender.length > 0 && gender[0].length > 0;
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'z2g');
        const hasHandicap = typeof handicap !== 'undefined' && handicap !== null;
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'z2h');
        let hasInterests = typeof interests !== 'undefined' && interests !== null && interests !== "";
        if (hasInterests && isSelf && interests[0] === 'na') {
          hasInterests = false;
        }
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'z2i');
        const hasLocation = typeof userLocation !== 'undefined' && userLocation !== null && userLocation !== "";
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'z2i2');
        const hasLookingFor = typeof lookingFor !== 'undefined' && lookingFor !== null; // && lookingFor !== "";
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, typeof lookingFor);
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, typeof lookingFor !== 'undefined');
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, lookingFor !== null);
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, lookingFor !== "");
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'z2i3');
        const hasName = typeof name !== 'undefined' && name !== null && name !== "";
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'z2j');
        const hasPlayAge = typeof play_age !== 'undefined' && play_age !== null && play_age !== "";
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'z2k');
        const hasRelationship = typeof relationship !== 'undefined' && relationship !== null && relationship !== '';
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'z2l');
        let hasRelationshipWith = typeof relationship_with !== 'undefined' && relationship_with !== null && relationship_with !== '';
        if (hasRelationshipWith) {
          if (relationship_with.length > 0) {
            if (relationship_with[0] === '' || isSelfOrStaff && relationship_with[0] === 'na') {
              hasRelationshipWith = false;
            }
          }
        }
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'z2m');
        let hasSexualOrientation = typeof sexualOrientation !== 'undefined' && sexualOrientation !== null && sexualOrientation !== "";
        if (hasSexualOrientation) {
          if (sexualOrientation.length === 1) {
            if (sexualOrientation[0] === '' || isSelfOrStaff && sexualOrientation[0] === 'na') {
              hasSexualOrientation = false;
            }
          }
        }
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'z2n');
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'asd');

        //console.clear();
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'this.currentUser');
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, this.currentUser);
        if (isAdmin || isStaff || isSelf) {
          component.set("userAgeTitle", (0, _cakeday.userAgeTitle)(args.user));
          component.set("userBirthdateTitle", (0, _cakeday.userBirthdateTitle)(args.user));
        }
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'args.user from user-card-post-names\\user-card-ap-profile.js:');
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, args.user);
        console.warn({
          gender,
          tog: typeof gender
        });
        // component.set("userGenderNa", typeof(gender) !== 'undefined' && gender === Array['na']);
        component.set("userGenderNa", typeof gender !== 'undefined' && gender !== null && gender.length > 0 && gender[0] === 'na');
        component.set("diaper_change", diaper_change);
        component.set("diaper_usage", diaper_usage);
        component.set("userGenderIcon", (0, _discourse_custom_ap_profile.genderToIcon)(args.user.get("gender")));
        //component.set("userGenderText", I18n.t('js.gender_' + args.user.get("gender")));
        //component.set("userGenderText", args.user.get("custom_fields.gender") !== null ? I18n.t('js.gender_' + args.user.get("custom_fields.gender")) : I18n.t('js.custom_ap_profile.not_defined'));
        component.set("userGenderText", _I18n.default.t('js.custom_ap_profile.gender_' + (args.user.get("gender") ? args.user.get("gender") : 'undefined')));
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'component.get("userGenderNa"):');
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, component.get("userGenderNa"));
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'component.get("user.hasBirthdate"):');
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, component.get("user.hasBirthdate"));
        component.set("userGenderAgeNa", component.get("userGenderNa") && component.get("user.hasBirthdate"));
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, typeof args.user.get("ageplayRoles"));
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, args.user.get("ageplayRoles"));
        let ageplayRolesArray = (args.user.get("ageplayRoles") + '').split('|');
        // args.user.set("ageplayRolesList", (args.user.get("ageplayRoles") + '').split('|'));

        // showLog(show_debug_info, 'args.user.get("ageplayRolesList"):');
        // showLog(show_debug_info, args.user.get("ageplayRolesList"));

        //der er en fejl vedr. join, som gør at rails skal genstartes
        // if (args.user.get("ageplayRoles") !== null && (typeof args.user.get("ageplayRoles") !== 'undefined')) {
        //hvis rails har været genstartet, eksempelvis fordi locales er blevet opdateret, så er ageplayRoles en string, hvorfor der nu anvendes ageplayRolesArray, inden der laves en join, som ellers vil fejle når det ikke er et array.
        if (hasAgeplayRole) {
          //component.set("ageplayRolesArray", ageplayRolesArray);
          component.set("ageplayRolesArray", args.user.get("ageplayRoles"));
          component.set("ageplayRolesList", ageplayRolesArray.join(', '));
        }
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'flubber5:');
        if (hasInterests) {
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'flyt1');
          var interestsList = new Array();
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'flyt2');
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'interests:');
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, interests);
          if (interests !== null) {
            interests.forEach(ui => {
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'flyt3');
              if (_I18n.default.t('js.custom_ap_profile.interests_' + ui).indexOf('.js.') === -1 || component.siteSettings.custom_ap_profile_show_missing_translations) {
                (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'flyt4');
                // interestsList.push(I18n.t('js.custom_ap_profile.interests_' + ui));
                interestsList.push({
                  name: _I18n.default.t('js.custom_ap_profile.interests_' + ui),
                  id: component.siteSettings['custom_ap_profile_interests_' + ui]
                });
              } else {
                (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'flyt5');
                interestsList.push(ui);
              }
            });
          }
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'flyt6');
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, interestsList.join(', '));
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'flyt7');
          component.set("interests", interests);
          // component.set("interestsList", interestsList.join(', '));
          component.set("interestsList", interestsList);
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'flyt8');
        }
        if (hasDiaperChange) {
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'dc1');
          var diaperChangeList = new Array();
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'dc2');
          if (diaper_change !== null) {
            (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'dc3');
            diaper_change.forEach(dc => {
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'dc4');
              if (_I18n.default.t('js.custom_ap_profile.diaper_change_' + dc).indexOf('.js.') === -1 || component.siteSettings.custom_ap_profile_show_missing_translations) {
                (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'dc5');
                diaperChangeList.push(_I18n.default.t('js.custom_ap_profile.diaper_change_' + dc));
              } else {
                (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'dc6');
                diaperChangeList.push(dc);
              }
            });
            (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'dc7');
          }
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'dc8');
          component.set("diaperChangeList", diaperChangeList.join(', '));
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'dc9');
        }
        if (hasDiaperUsage) {
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'du1');
          var diaperUsageList = new Array();
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'du2');
          if (diaper_usage !== null) {
            (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'du3');
            diaper_usage.forEach(du => {
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'du4');
              if (_I18n.default.t('js.custom_ap_profile.diaper_usage_' + du).indexOf('.js.') === -1 || component.siteSettings.custom_ap_profile_show_missing_translations) {
                (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'du5');
                diaperUsageList.push(_I18n.default.t('js.custom_ap_profile.diaper_usage_' + du));
              } else {
                (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'du6');
                diaperUsageList.push(du);
              }
            });
            (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'du7');
          }
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'du8');
          component.set("diaperUsageList", diaperUsageList.join(', '));
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'du9');
        }
        if (hasFavoriteDiaper) {
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'fd1');
          var favoriteDiaperList = new Array();
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'fd2');
          if (favorite_diapers !== null) {
            const library = component.siteSettings.custom_ap_profile_diaper_library;
            (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'library:');
            (0, _discourse_custom_ap_profile.showLog)(show_debug_info, library);
            if (typeof library !== 'undefined') {
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'fd3');
              let filter = 'c/library/' + library;
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'fd4');
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, args);
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, model);
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, model.store);
              model.store.findFiltered('topicList', {
                filter,
                params: {}
              }).then(list => {
                (0, _discourse_custom_ap_profile.showLog)(show_debug_info, list);
                list.topics.slice(1).forEach(function (item) {
                  (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'LI: ' + item.title);
                  (0, _discourse_custom_ap_profile.showLog)(show_debug_info, item);
                  // library_list[library_list.count] = { name: item.title, value: item.title };
                  //value skal måske være item.id, men så skal vi enten gemme 2 værdier, eller lave noget opslag..
                  // library_list bør måske være et globalt array, så der ikke skal laves genopslag for at gemme de 2 arrays værdier id + title
                  //favoriteDiaperList.push({ name: item.title, value: item.id });
                  if (favorite_diapers.includes(item.id)) {
                    favoriteDiaperList.push(item);
                  }
                });
                component.set('favoriteDiaperList', favoriteDiaperList);

                // showLog(show_debug_info, library_list);
              });
            }

            // showLog(show_debug_info, 'fd3');
            // favorite_diapers.forEach((fd) => {
            // showLog(show_debug_info, 'fd4');
            // if (I18n.t('js.custom_ap_profile.favorite_diapers_' + fd).indexOf('.js.') === -1 || component.siteSettings.custom_ap_profile_show_missing_translations) {
            // showLog(show_debug_info, 'fd5');
            // favoriteDiaperList.push(I18n.t('js.custom_ap_profile.favorite_diapers_' + fd));
            // }
            // else {
            // showLog(show_debug_info, 'fd6');
            // favoriteDiaperList.push(fd);
            // }
            // });
            // showLog(show_debug_info, 'fd7');
          }
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'fd8');
          // component.set("favoriteDiaperList", favoriteDiaperList.join(', '));
          //component.set("favoriteDiaperList", favorite_diapers.join(', '));
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'fd9');
        }
        if (hasRelationshipWith) {
          const relationship_withA = [];
          const relationship_withB = [];
          const relationship_withC = [];
          if (relationship_with.length === 2) {
            relationship_withB.push(relationship_with[0]);
            relationship_withC.push(relationship_with[1]);
          } else {
            relationship_withA.push(...relationship_with.slice(0, -2));
            relationship_withB.push(...relationship_with.slice(-2, -1));
            relationship_withC.push(...relationship_with.slice(-1));
          }
          component.set("relationship_withA", relationship_withA);
          component.set("relationship_withB", relationship_withB);
          component.set("relationship_withC", relationship_withC);
          component.set("hasRelationshipWith2", relationship_with.length > 1);
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'relationship_with A, B and C');
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, relationship_withA);
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, relationship_withB);
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, relationship_withC);
        }

        // if (args.user.get("lookingFor") !== null) {
        if (hasLookingFor) {
          var lookingForList = new Array();
          // args.user.get("lookingFor").forEach((ulf) => { showLog(show_debug_info, 'Looking for: ' + ulf + ', ' + I18n.t('js.looking_for_' + ulf)); lookingForList[ulf] = I18n.t('js.looking_for_' + ulf) });

          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'flubber6a:');
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'lookingFor:');
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, lookingFor);
          // if (typeof args.user.get("lookingFor") !== 'undefined') {
          // args.user.get("lookingFor").forEach((ulf) => {
          if (lookingFor) {
            lookingFor.forEach(ulf => {
              if (_I18n.default.t('js.custom_ap_profile.looking_for_' + ulf).indexOf('.js.') === -1 || this.siteSettings.custom_ap_profile_show_missing_translations) lookingForList.push(_I18n.default.t('js.custom_ap_profile.looking_for_' + ulf));else lookingForList.push(ulf);
            });
            // };
          }
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'flubber6b:');
          //component.set("lookingForList", args.user.get("lookingFor").join(', '));
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, lookingForList.join(', '));
          component.set("lookingForList", lookingForList.join(', '));
        }
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'flubber7:');
        if (args.user.get("sexualOrientation") !== null && typeof args.user.get("sexualOrientation") !== 'undefined') {
          component.set("sexualOrientation", _I18n.default.t('js.custom_ap_profile.sexual_orientation_' + args.user.get("sexualOrientation")));
        }
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'flubber7b');
        if (args.user.get("relationship") !== null && typeof args.user.get("relationship") !== 'undefined') {
          component.set("relationship", _I18n.default.t('js.custom_ap_profile.relationship_' + args.user.get("relationship")));
        }
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'flubber8:');
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'args.user.get("care_age"):');
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, args.user.get("care_age"));
        if (args.user.get("care_age") !== null && typeof args.user.get("care_age") !== 'undefined') {
          component.set("hascare_age", args.user.get("care_age").length > 0);
        }
        if (args.user.get("play_age") !== null && typeof args.user.get("play_age") !== 'undefined') {
          component.set("hasplay_age", args.user.get("play_age").length > 0);
        }
        component.set("care_age", args.user.get("care_age"));
        component.set("play_age", args.user.get("play_age"));
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'args.user:');
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, args.user);
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, args.user.get("gender"));

        // component.set("hasGender", args.user.get("gender") !== null && (typeof args.user.get("gender") !== 'undefined'));
        // component.set("hasGender", hasGender);
        // component.set("hasName", args.user.get("name") !== null && (typeof args.user.get("name") !== 'undefined'));
        // component.set("hasSexualOrientation", args.user.get("sexual_orientation") !== null && (typeof args.user.get("sexual_orientation") !== 'undefined'));
        // component.set("hasRelationship", args.user.get("relationship") !== null && (typeof args.user.get("relationship") !== 'undefined'));
        // component.set("hasAgeplayRoles", args.user.get("ageplayRoles") !== null && (typeof args.user.get("ageplayRoles") !== 'undefined'));
        // component.set("hasLookingFor", args.user.get("userLooking_for") !== null && (typeof args.user.get("lookingFor") !== 'undefined'));

        component.set("relationship_with", relationship_with);
        component.set("hasAgeplayRole", hasAgeplayRole);
        component.set("hasBirthdate", hasBirthdate);
        component.set("hasCareAge", hasCareAge);
        component.set("hasCountry_District", hasCountry_District);
        component.set("hasDiaperChange", hasDiaperChange);
        component.set("hasDiaperUsage", hasDiaperUsage);
        component.set("hasFavoriteDiaper", hasFavoriteDiaper);
        component.set("hasGender", hasGender);
        component.set("hasHandicap", hasHandicap);
        component.set("hasInterests", hasInterests);
        component.set("hasLocation", hasLocation);
        component.set("hasLookingFor", hasLookingFor);
        component.set("hasName", hasName);
        component.set("hasPlayAge", hasPlayAge);
        component.set("hasRelationship", hasRelationship);
        component.set("hasRelationshipWith", hasRelationshipWith);
        component.set("hasSexualOrientation", hasSexualOrientation);
        if (component.get("hasAgeplayRole")) definedDetails++;
        if (component.get("hasBirthdate")) definedDetails++;
        if (component.get("hasCareAge")) definedDetails++;
        if (component.get("hasCountry_District")) definedDetails++;
        if (component.get("hasDiaperChange")) definedDetails++;
        if (component.get("hasDiaperUsage")) definedDetails++;
        if (component.get("hasFavoriteDiaper")) definedDetails++;
        if (component.get("hasGender")) definedDetails++;
        if (component.get("hasHandicap")) definedDetails++;
        if (component.get("hasInterests")) definedDetails++;
        if (component.get("hasLocation")) definedDetails++;
        if (component.get("hasLookingFor")) definedDetails++;
        if (component.get("hasName")) definedDetails++;
        if (component.get("hasPlayAge")) definedDetails++;
        if (component.get("hasRelationship")) definedDetails++;
        if (component.get("hasRelationshipWith")) definedDetails++;
        if (component.get("hasSexualOrientation")) definedDetails++;
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'definedDetails:');
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, definedDetails);
        component.set("definedDetails", definedDetails);
        component.set("showHints", definedDetails < this.siteSettings.custom_ap_profile_show_tips_until_num_profile_data);
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'done setupComponent from user-card-post-names\\user-card-ap-profile.js');
      }
    }
  };
});