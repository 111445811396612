define("discourse/plugins/discourse-custom-ap-profile/discourse/templates/connectors/full-page-search-below-search-info/ap-profile-fpsbsi", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{!-- full-page-search-below-search-info --}}
  */
  {
    "id": "7Rpspvvj",
    "block": "[[],[],false,[]]",
    "moduleName": "discourse/plugins/discourse-custom-ap-profile/discourse/templates/connectors/full-page-search-below-search-info/ap-profile-fpsbsi.hbs",
    "isStrictMode": false
  });
});