define("discourse/plugins/discourse-custom-ap-profile/discourse/connectors/user-card-location-and-website/custom_ap_profile", ["exports", "@ember/component", "discourse-common/utils/decorators", "I18n", "discourse/models/user", "discourse/plugins/discourse-custom-ap-profile/discourse/lib/discourse_custom_ap_profile"], function (_exports, _component, _decorators, _I18n, _user, _discourse_custom_ap_profile) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // function initializeCustomApProfile(api, siteSettings) {
  // }
  var _default = _exports.default = {
    setupComponent(model, component) {
      // console.log(model);

      // const CAPPEnabledGroups = model.user.siteSettings.custom_ap_profile_enabled_groups;
      // const isCAPPAvailable = this.currentUser.groups.filter(g => CAPPEnabledGroups.includes(g.id)).length > 0;

      component.set("isCAPPAvailable", (0, _discourse_custom_ap_profile.isCAPPAvailable)(this.currentUser));
      if (component.isCAPPAvailable) {
        console.log('setupComponent from user-card-location-and-website\\custom_ap_profile.js.es6');

        // component.set("isCakeday", cakeday(model.user.get("created_at")));
        // component.set(
        // "isBirthday",
        // birthday(model.user.get("date_of_birth"))
        // );
        // component.set("cakedayTitle", cakedayTitle(model.user, this.currentUser));
        // component.set(
        // "birthdayTitle",
        // birthdayTitle(model.user, this.currentUser)
        // );
        console.log('flubber 1:');
        const isStaff = this.currentUser && this.currentUser.staff;
        const isAdmin = this.currentUser && this.currentUser.admin;
        console.log('flubber 2:');
        const isSelf = model.user && this.currentUser && model.user.id === this.currentUser.id;
        console.log('flubber 3:');

        // let enabledSocialNetworks = {};
        // const enabledSocialNetworks = 'asd|qwe'; //user.siteSettings.custom_ap_profile_social_networks.split('|');
        // console.log('component: (findme)');
        // console.log(component);
        // console.log('model: (findme)');
        // console.log(model);
        // console.log('component: (findme)');
        // console.log(component);
        // const enabledSocialNetworks = this.siteSettings.custom_ap_profile_social_networks.split('|');
        const enabledSocialNetworks = model.user.socialNetworks;

        // function getSocialUrl(a,b) {
        // console.log({'a': a, 'b': b});
        // switch(a)
        // {
        // case 'discord':
        // return 'https://discordapp.com/users/' + b + '/';
        // case 'discourse':
        // return 'https://meta.discourse.org/u/' + b + '/';
        // case 'facebook':
        // return 'https://www.facebook.com/' + b + '/';
        // case 'fetlife':
        // return 'https://fetlife.com/users/' + b + '/';
        // case 'flickr':
        // return 'https://www.flickr.com/people/' + b + '/';
        // case 'instagram':
        // return 'https://www.instagram.com/' + b + '/';
        // case 'snapchat':
        // return 'https://www.snapchat.com/add/' + b + '/';
        // case 'telegram':
        // return 'https://t.me//' + b + '/';
        // case 'tumblr':
        // return 'https://www.tumblr.com/register/follow/' + b + '/';
        // case 'twitch':
        // return 'https://www.twitch.tv/' + b + '/';
        // }

        // }

        function createIconObject(a, index, arr) {
          //arr[index] = { name: a, i18n: 'social.' + a, icon: 'fab-' + a, url: getSocialUrl(User.custom_fields[a]) };
          // arr[index] = { name: a, i18n: 'social.' + a, icon: 'fab-' + a, url: getSocialUrl(a) };
          arr[index] = {
            name: a,
            i18n: 'custom_ap_profile.social.' + a,
            icon: 'fab-' + a,
            url: (0, _discourse_custom_ap_profile.getSocialUrl)(a, model.user[a])
          };
        }
        ;
        if (enabledSocialNetworks) {
          enabledSocialNetworks.forEach(createIconObject);
          console.log('enabledSocialNetworks:');
          console.log(enabledSocialNetworks);
          console.log('User.custom_fields');
          console.log(_user.default.custom_fields);
        }
        // component.set("canEditProfile", isStaff || isSelf);

        // console.log('component:');
        // console.log(component);

        // component.set("user.hasBirthdate", model.user.get("date_of_birth") != null);
        // component.set("user.preferencesProfilePath", "/u/" + model.user.get("username_lower") + "/preferences/profile/#cakeday");

        // if (isAdmin || isStaff || isSelf) {
        // component.set("userAgeTitle", userAgeTitle(model.user));
        // component.set("userBirthdateTitle", userBirthdateTitle(model.user));
        // }
        console.log('this:');
        console.log(this);
        console.log('component:');
        console.log(component);
        console.log('model:');
        console.log(model);
        console.log('model.user:');
        console.log(model.user);
        //    component.set("enabledSocialNetworks", user.siteSettings.custom_ap_profile_social_networks.split('|'));
        // component.set("userGenderIcon", genderToIcon(model.user.get("gender")));
        // component.set("userGenderText", I18n.t('js.gender_' + model.user.get("gender")));
        // component.set("ageplayRolesList", model.user.get("ageplayRoles").join(', '));
        // component.set("sexualOrientation", I18n.t('js.sexual_orientation_' + model.user.get("sexualOrientation")));

        component.setProperties({
          enabledSocialNetworks
        });
      }
    }
  };
});