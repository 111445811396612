define("discourse/plugins/discourse-custom-ap-profile/discourse/templates/connectors/user-card-post-names/user-card-cakeday", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <span class="overridden">{{!-- Cakeday overridden to costomize template --}}</span>
  */
  {
    "id": "YHJwK9IL",
    "block": "[[[10,1],[14,0,\"overridden\"],[12],[13]],[],false,[]]",
    "moduleName": "discourse/plugins/discourse-custom-ap-profile/discourse/templates/connectors/user-card-post-names/user-card-cakeday.hbs",
    "isStrictMode": false
  });
});