define("discourse/plugins/discourse-custom-ap-profile/discourse/connectors/user-post-names/user-custom-ap-profile", ["exports", "@ember/component", "discourse-common/utils/decorators", "discourse/routes/discourse", "I18n", "discourse/plugins/discourse-custom-ap-profile/discourse/lib/cakeday", "discourse/models/user", "discourse/plugins/discourse-custom-ap-profile/discourse/lib/discourse_custom_ap_profile"], function (_exports, _component, _decorators, _discourse, _I18n, _cakeday, _user, _discourse_custom_ap_profile) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    setupComponent(_ref, component) {
      let {
        model
      } = _ref;
      const show_debug_info = model.siteSettings.custom_ap_profile_show_debug_info;
      let definedDetails = 0;

      //showLog(show_debug_info, this.renderer._owner.router.currentPath);

      // const updateCurrentPath = function (path) {
      // showLog(show_debug_info, 'Path is: ' + path);
      // component.set("isPreferences", path.indexOf("user.preferences") > 0);
      // component.set("isPreferencesProfile", path.indexOf("user.preferences.profile") > 0);
      // showLog(show_debug_info, component);

      // showWarn(show_debug_info, 'path has been updated');
      // }

      //    component.addObserver("this.renderer._owner.router.currentPath", updateCurrentPath(this.renderer._owner.router.currentPath));

      const isStaff = this.currentUser && this.currentUser.staff;
      const isAdmin = this.currentUser && this.currentUser.admin;
      const isSelf = model && this.currentUser && model.id === this.currentUser.id;
      const isSelfOrStaff = isSelf || isStaff;
      component.setProperties({
        isSelf,
        isStaff,
        isSelfOrStaff
      });

      // const CAPPEnabledGroups = model.siteSettings.custom_ap_profile_enabled_groups;
      // const isCAPPAvailable = this.currentUser.groups.filter(g => CAPPEnabledGroups.includes(g.id)).length > 0;

      component.set("isCAPPAvailable", (0, _discourse_custom_ap_profile.isCAPPAvailable)(this.currentUser));
      if (component.isCAPPAvailable) {
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'setup component from connectors/user-post-names/user-custom-ap-profile.js');
        if (typeof router !== 'undefined') (0, _discourse_custom_ap_profile.showLog)(show_debug_info, router);else (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'ruter not defined');
        (0, _discourse_custom_ap_profile.showWarn)(show_debug_info, 'this');
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, this);
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'model');
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, model);
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'component');
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, component);
        component.set("canEditProfile", isSelfOrStaff);
        component.set("isCakeday", (0, _cakeday.cakeday)(model.cakedate));
        component.set("isBirthday", (0, _cakeday.birthday)(model.birthdate));
        component.set("isSecret", !(0, _cakeday.celebrate)(model));
        component.set("cakedayTitle", (0, _cakeday.cakedayTitle)(show_debug_info, this.currentUser));
        component.set("birthdayTitle", (0, _cakeday.birthdayTitle)(show_debug_info, this.currentUser));
        component.set("secretTitle", (0, _cakeday.secretTitle)(show_debug_info, this.currentUser));
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'isBirthday:');
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, component.get("isBirthday"));

        //de maa komme fra cakeday... men skal maaske overskrives?
        // component.set("isCakeday", cakeday(model.get("created_at")));
        // component.set(
        // "isBirthday",
        // birthday(model.get("date_of_birth"))
        // );
        // component.set("cakedayTitle", cakedayTitle(show_debug_info, this.currentUser));
        // component.set(
        // "birthdayTitle",
        // birthdayTitle(show_debug_info, this.currentUser)
        // );

        const ageplayRoles = model.get("custom_fields.ageplayRoles");
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, ageplayRoles);
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, typeof ageplayRoles);
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'z1a');
        const birthdate = model.get("birthdate");
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'z1a2');
        const care_age = model.get("care_age");
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'z1b');
        const country_District = model.get("country_District");
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'z1c');
        const diaper_change = model.get("diaper_change");
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'z1d');
        const diaper_usage = model.get("diaper_usage");
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'z1d2');
        const favorite_diapers = model.get("favorite_diapers");
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'z1e');
        const gender = model.get("gender");
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'z1fp');
        const handicap = model.get("handicap");
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'z1g');
        const interests = model.get("interests");
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'z1h');
        const userLocation = model.get("location");
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'z1h2');
        const lookingFor = model.get("lookingFor");
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, lookingFor);
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'z1h3');
        const name = model.get("name");
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'z1i');
        const play_age = model.get("play_age");
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'z1j');
        const relationship = model.get("relationship");
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'z1k');
        const relationship_with = model.get("in_relationship_with");
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, relationship_with);
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'z1l');
        const sexualOrientation = model.get("sexualOrientation");
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'z1m');
        const hasAgeplayRole = typeof ageplayRoles !== 'undefined' && ageplayRoles !== null && ageplayRoles !== "" && ageplayRoles.length > 0 && ageplayRoles[0].length > 0;
        const hasBirthdate = birthdate !== null;
        const hasCareAge = typeof care_age !== 'undefined' && care_age !== null && care_age !== "";
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'z2c');
        const hasCountry_District = typeof country_District !== 'undefined' && country_District !== null && country_District !== "";
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'z2d');
        let hasDiaperChange = typeof diaper_change !== 'undefined' && diaper_change !== null && diaper_change !== "";
        if (hasDiaperChange) {
          if (diaper_change.length === 1) {
            if (diaper_change[0] === '' || isSelfOrStaff && diaper_change[0] === 'na') {
              hasDiaperChange = false;
            }
          }
        }
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'z2d2');
        let hasDiaperUsage = typeof diaper_usage !== 'undefined' && diaper_usage !== null && diaper_usage !== "";
        if (hasDiaperUsage) {
          if (diaper_usage.length === 1) {
            if (diaper_usage[0] === '' || isSelfOrStaff && diaper_usage[0] === 'na') {
              hasDiaperUsage = false;
            }
          }
        }
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'z2e');
        let hasFavoriteDiaper = typeof favorite_diapers !== 'undefined' && favorite_diapers !== null && favorite_diapers !== "";
        if (hasFavoriteDiaper) {
          if (favorite_diapers.length === 1) {
            if (favorite_diapers[0] === '' || isSelfOrStaff && favorite_diapers[0] === 'na') {
              hasDiaperUsage = false;
            }
          }
        }
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'z2f');
        const hasGender = typeof gender !== 'undefined' && gender !== null && gender !== "" && gender.length > 0 && gender[0].length > 0;
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'z2g');
        const hasHandicap = typeof handicap !== 'undefined' && handicap !== null;
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'z2h');
        const hasInterests = typeof interests !== 'undefined' && interests !== null && interests !== "";
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'z2i');
        const hasLocation = typeof userLocation !== 'undefined' && userLocation !== null && userLocation !== "";
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'z2i2');
        const hasLookingFor = typeof lookingFor !== 'undefined' && lookingFor !== null; // && lookingFor !== "";
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, typeof lookingFor);
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, typeof lookingFor !== 'undefined');
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, lookingFor !== null);
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, lookingFor !== "");
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'z2i3');
        const hasName = typeof name !== 'undefined' && name !== null && name !== "";
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'z2j');
        const hasPlayAge = typeof play_age !== 'undefined' && play_age !== null && play_age !== "";
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'z2k');
        const hasRelationship = typeof relationship !== 'undefined' && relationship !== null && relationship !== '';
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, relationship);
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'z2l');
        let hasRelationshipWith = typeof relationship_with !== 'undefined' && relationship_with !== null && relationship_with !== '';
        if (hasRelationshipWith) {
          if (relationship_with.length > 0) {
            if (relationship_with[0] === '') {
              hasRelationshipWith = false;
            }
          }
        }
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, relationship_with);
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'z2m');
        const hasSexualOrientation = typeof sexualOrientation !== 'undefined' && sexualOrientation !== null && sexualOrientation !== "";
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'z2n');
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'asd');
        component.set("relationship", relationship !== 'na' ? _I18n.default.t('custom_ap_profile.relationship_' + relationship) : null);
        component.set("hasBirthdate", model.get("birthdate") != null);
        component.set("model.hasBirthdate", model.get("birthdate") != null);
        component.set("model.hasplay_age", model.get("play_age") != null);
        component.set("model.hascare_age", model.get("care_age") != null);
        component.set("model.preferencesProfilePath", "/u/" + model.get("username_lower") + "/preferences/profile/");
        if (isAdmin || isStaff || isSelf) {
          component.set("userAgeTitle", (0, _cakeday.userAgeTitle)(model));
          component.set("userBirthdateTitle", (0, _cakeday.userBirthdateTitle)(model));
        }
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'z1');
        component.set("model.isPreferences", document.location.href.indexOf("/preferences") > 0);
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'z2');
        var isPreferences2 = document.location.href.indexOf("/preferences") > 0;
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'z3');
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, gender);
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, typeof gender);
        component.set("userGenderNa", typeof gender !== 'undefined' ? gender === ['na'] : null);
        // showLog(show_debug_info, "gender:")
        // showLog(show_debug_info, model.get("gender"));

        component.set("userGenderIcon", (0, _discourse_custom_ap_profile.genderToIcon)(gender));
        component.set("userGenderText", _I18n.default.t('custom_ap_profile.gender_' + (gender === null ? 'na' : gender)));
        component.set("userGenderAgeNa", component.get("userGenderNa") && component.get("model.hasBirthdate"));
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'z4');
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, model.get("custom_fields.ageplayRoles"));

        // let ageplayRoles = model.get("custom_fields.ageplayRoles");
        // // let ageplayRolesArray = (ageplayRoles + '').split('|');

        // let hasAgeplayRole = false;
        // hasAgeplayRole = typeof ageplayRoles !== 'undefined' && ageplayRoles !== null && ageplayRoles !== "" && ageplayRoles.length > 0 && ageplayRoles[0].length > 0;

        let ageplayRolesArray = (model.get("ageplayRoles") + '').split('|');
        if (hasAgeplayRole) {
          // && (typeof model.get("care_age") !== 'undefined') || isStaff) {
          var ageplayRolesList = Array();
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'z4a');
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, ageplayRoles);
          // showLog(show_debug_info, ageplayRolesArray);
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'z4b1');
          if (typeof ageplayRoles !== 'undefined') {
            ageplayRoles.forEach(apr => {
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'ageplayRole: ' + apr + ', ' + _I18n.default.t('custom_ap_profile.ageplay_role_' + apr));
              ageplayRolesList.push(_I18n.default.t('custom_ap_profile.ageplay_role_' + apr));
            });
          }
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'z4b');
          // component.set("ageplayRolesList", model.get("custom_fields.ageplayRoles").join(', '));
          component.set("ageplayRolesArray", model.get("custom_fields.ageplayRoles"));
          component.set("ageplayRolesList", ageplayRolesList.join(', '));
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'z4c');
        }
        if (hasInterests) {
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'flyt1');
          var interestsList = new Array();
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'flyt2');
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'interests:');
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, interests);
          if (interests !== null) {
            interests.forEach(ui => {
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'flyt3');
              if (_I18n.default.t('js.custom_ap_profile.interests_' + ui).indexOf('.js.') === -1 || component.siteSettings.custom_ap_profile_show_missing_translations) {
                (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'flyt4');
                interestsList.push({
                  name: _I18n.default.t('js.custom_ap_profile.interests_' + ui),
                  id: component.siteSettings['custom_ap_profile_interests_' + ui]
                });
              } else {
                (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'flyt5');
                interestsList.push(ui);
              }
            });
          }
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'flyt6');
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, interestsList.join(', '));
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'flyt7');
          component.set("interests", interests);
          // component.set("interestsList", interestsList.join(', '));
          component.set("interestsList", interestsList);
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'flyt8');
        }
        console.log('diaper_usage test');
        console.log(diaper_usage);
        console.log(model.get("custom_fields.diaper_change"));
        if (hasDiaperChange) {
          if (diaper_change !== null) {
            var diaperChangeList = Array();
            if (typeof diaper_change !== 'undefined') {
              diaper_change.forEach(dc => {
                diaperChangeList.push(_I18n.default.t('custom_ap_profile.diaper_change_' + dc));
              });
            }
            component.set("diaperChangeList", diaperChangeList.join(', '));
          }
        }
        if (hasDiaperUsage) {
          if (diaper_usage !== null) {
            var diaperUsageList = Array();
            if (typeof diaper_usage !== 'undefined') {
              diaper_usage.forEach(du => {
                if (_I18n.default.t('custom_ap_profile.diaper_usage_' + du).indexOf('.js.') === -1 || component.siteSettings.custom_ap_profile_show_missing_translations) {
                  (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'du5');
                  diaperUsageList.push(_I18n.default.t('custom_ap_profile.diaper_usage_' + du));
                } else {
                  (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'du6');
                  diaperUsageList.push(du);
                }
              });
            }
            component.set("diaperUsageList", diaperUsageList.join(', '));
          }
        }
        if (hasFavoriteDiaper) {
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'fd1');
          var favoriteDiaperList = new Array();
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'fd2');
          if (favorite_diapers !== null) {
            const library = component.siteSettings.custom_ap_profile_diaper_library;
            (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'library:');
            (0, _discourse_custom_ap_profile.showLog)(show_debug_info, library);
            if (typeof library !== 'undefined' && library !== '') {
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'fd3');
              let filter = 'c/library/' + library;
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'fd4');
              model.store.findFiltered('topicList', {
                filter,
                params: {}
              }).then(list => {
                (0, _discourse_custom_ap_profile.showLog)(show_debug_info, list);
                list.topics.slice(1).forEach(function (item) {
                  (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'LI: ' + item.title);
                  (0, _discourse_custom_ap_profile.showLog)(show_debug_info, item);
                  // library_list[library_list.count] = { name: item.title, value: item.title };
                  //value skal måske være item.id, men så skal vi enten gemme 2 værdier, eller lave noget opslag..
                  // library_list bør måske være et globalt array, så der ikke skal laves genopslag for at gemme de 2 arrays værdier id + title
                  //favoriteDiaperList.push({ name: item.title, value: item.id });
                  if (favorite_diapers.includes(item.id)) {
                    favoriteDiaperList.push(item);
                  }
                });
                component.set('favoriteDiaperList', favoriteDiaperList);

                // showLog(show_debug_info, library_list);
              });
            }

            // showLog(show_debug_info, 'fd3');
            // favorite_diapers.forEach((fd) => {
            // showLog(show_debug_info, 'fd4');
            // if (I18n.t('js.custom_ap_profile.favorite_diapers_' + fd).indexOf('.js.') === -1 || component.siteSettings.custom_ap_profile_show_missing_translations) {
            // showLog(show_debug_info, 'fd5');
            // favoriteDiaperList.push(I18n.t('js.custom_ap_profile.favorite_diapers_' + fd));
            // }
            // else {
            // showLog(show_debug_info, 'fd6');
            // favoriteDiaperList.push(fd);
            // }
            // });
            // showLog(show_debug_info, 'fd7');
          }
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'fd8');
          // component.set("favoriteDiaperList", favoriteDiaperList.join(', '));
          //component.set("favoriteDiaperList", favorite_diapers.join(', '));
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'fd9');
        }
        if (hasRelationshipWith) {
          const relationship_withA = [];
          const relationship_withB = [];
          const relationship_withC = [];
          if (relationship_with.length === 2) {
            relationship_withB.push(relationship_with[0]);
            relationship_withC.push(relationship_with[1]);
          } else {
            relationship_withA.push(...relationship_with.slice(0, -2));
            relationship_withB.push(...relationship_with.slice(-2, -1));
            relationship_withC.push(...relationship_with.slice(-1));
          }
          component.set("relationship_withA", relationship_withA);
          component.set("relationship_withB", relationship_withB);
          component.set("relationship_withC", relationship_withC);
          component.set("hasRelationshipWith2", relationship_with.length > 1);
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'relationship_with A, B and C');
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, relationship_withA);
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, relationship_withB);
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, relationship_withC);
        }
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, ':::: userLooking for: ');
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'model:');
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, model);
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, model.get("lookingFor"));
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'z5');
        if (model.get("lookingFor") !== null && model.get("lookingFor") !== undefined) {
          // component.set("lookingForList", model.get("lookingFor").join(', '));
          var lookingForList = new Array();
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'model.get(lookingFor):');
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, model.get("lookingFor"));
          //model.get("lookingFor").forEach((ulf) => { showLog(show_debug_info, 'Looking for: ' + ulf + ', ' + I18n.t('js.looking_for_' + ulf)); lookingForList.push(I18n.t('js.looking_for_' + ulf));});

          if (typeof model.get("lookingFor") !== 'undefined') {
            model.get("lookingFor").forEach(ulf => {
              if (_I18n.default.t('custom_ap_profile.looking_for_' + ulf).indexOf('.js.') === -1 || this.siteSettings.custom_ap_profile_show_missing_translations) lookingForList.push(_I18n.default.t('custom_ap_profile.looking_for_' + ulf));else lookingForList.push(ulf);
            });
          }
          ;
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'lookingForList:');
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, lookingForList.toString());
          // component.set("lookingForList", user.get("lookingFor").join(', '));
          //component.set("lookingForList", lookingForList.toString());
          component.set("lookingForList", lookingForList.join(', '));
        }
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'z6');
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, sexualOrientation);
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, model.get("sexualOrientation"));
        // if (model.get("sexualOrientation") !== null && (typeof model.get("sexualOrientation") !== 'undefined')) {
        // component.set("sexualOrientation", I18n.t('js.custom_ap_profile.sexual_orientation_' + model.get("custom_fields.sexualOrientation")));
        // }
        if (hasSexualOrientation) {
          component.set("sexualOrientation", _I18n.default.t('js.custom_ap_profile.sexual_orientation_' + sexualOrientation));
        }
        if (model.get("care_age") !== null && typeof model.get("care_age") !== 'undefined') {
          component.set("hascare_age", model.get("care_age").length > 0);
        }
        if (model.get("play_age") !== null && typeof model.get("play_age") !== 'undefined') {
          component.set("hasplay_age", model.get("play_age").length > 0);
        }
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'z7');
        component.set("care_age", model.get("care_age"));
        component.set("play_age", model.get("play_age"));
        const enabledSocialNetworks = model.socialNetworks;
        function createIconObject(a, index, arr) {
          arr[index] = {
            name: a,
            i18n: 'custom_ap_profile.social.' + a,
            icon: 'fab-' + a,
            url: (0, _discourse_custom_ap_profile.getSocialUrl)(a, model[a])
          };
        }
        ;
        if (enabledSocialNetworks) {
          enabledSocialNetworks.forEach(createIconObject);
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'enabledSocialNetworks:');
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, enabledSocialNetworks);
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'User.custom_fields');
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, _user.default.custom_fields);
        }
        component.setProperties({
          enabledSocialNetworks
        });
        component.set("hasAgeplayRole", hasAgeplayRole);
        component.set("hasBirthdate", hasBirthdate);
        component.set("hasCareAge", hasCareAge);
        component.set("hasCountry_District", hasCountry_District);
        component.set("hasDiaperChange", hasDiaperChange);
        component.set("hasDiaperUsage", hasDiaperUsage);
        component.set("hasFavoriteDiaper", hasFavoriteDiaper);
        component.set("hasGender", hasGender);
        component.set("hasHandicap", hasHandicap);
        component.set("hasInterests", hasInterests);
        component.set("hasLocation", hasLocation);
        component.set("hasLookingFor", hasLookingFor);
        component.set("hasName", hasName);
        component.set("hasPlayAge", hasPlayAge);
        component.set("hasRelationship", hasRelationship);
        component.set("hasRelationshipWith", hasRelationshipWith);
        component.set("hasSexualOrientation", hasSexualOrientation);
        if (component.get("hasAgeplayRole")) definedDetails++;
        if (component.get("hasBirthdate")) definedDetails++;
        if (component.get("hasCareAge")) definedDetails++;
        if (component.get("hasCountry_District")) definedDetails++;
        if (component.get("hasDiaperChange")) definedDetails++;
        if (component.get("hasDiaperUsage")) definedDetails++;
        if (component.get("hasFavoriteDiaper")) definedDetails++;
        if (component.get("hasGender")) definedDetails++;
        if (component.get("hasHandicap")) definedDetails++;
        if (component.get("hasInterests")) definedDetails++;
        if (component.get("hasLocation")) definedDetails++;
        if (component.get("hasLookingFor")) definedDetails++;
        if (component.get("hasName")) definedDetails++;
        if (component.get("hasPlayAge")) definedDetails++;
        if (component.get("hasRelationship")) definedDetails++;
        if (component.get("hasRelationshipWith")) definedDetails++;
        if (component.get("hasSexualOrientation")) definedDetails++;
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'definedDetails:');
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, definedDetails);
        component.set("showHints", definedDetails < this.siteSettings.custom_ap_profile_show_tips_until_num_profile_data);
      }

      //    component.set("isCakeday", cakeday(model.get("created_at")));
      //    component.set(
      //      "isBirthday",
      //      birthday(model.get("date_of_birth"))
      //    );
    }
  };
});