define("discourse/plugins/discourse-custom-ap-profile/discourse/templates/connectors/advanced-search-options-below/custom-ap-profile", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{!-- Synlig på emne/indlæg avanceret søgning
  asdtestasd
  {{email-group-user-chooser
    options=(hash
      includeGroups=true
      adminsOnly=true
    )
  }}
  --}}
  */
  {
    "id": "s6o2w/wz",
    "block": "[[],[],false,[]]",
    "moduleName": "discourse/plugins/discourse-custom-ap-profile/discourse/templates/connectors/advanced-search-options-below/custom-ap-profile.hbs",
    "isStrictMode": false
  });
});