define("discourse/plugins/discourse-custom-ap-profile/discourse/templates/connectors/above-user-preferences/user-ap-profile-link", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{!--
  <a href="/u/{{model.username_lower}}/preferences/profile/#cakeday">Cakeday</a> |
  <a href="/u/{{model.username_lower}}/preferences/profile/#ap-profile">AP-Profile</a>
  --}}
  */
  {
    "id": "uOIhe1Vv",
    "block": "[[],[],false,[]]",
    "moduleName": "discourse/plugins/discourse-custom-ap-profile/discourse/templates/connectors/above-user-preferences/user-ap-profile-link.hbs",
    "isStrictMode": false
  });
});