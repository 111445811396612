define("discourse/plugins/discourse-custom-ap-profile/discourse/connectors/user-card-metadata/custom_ap_profile", ["exports", "@ember/component", "I18n", "discourse/models/user", "discourse/plugins/discourse-custom-ap-profile/discourse/lib/discourse_custom_ap_profile"], function (_exports, _component, _I18n, _user, _discourse_custom_ap_profile) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.isValidValue = isValidValue;
  function isValidValue(val) {
    return typeof val !== 'undefined' && val !== null && val.length > 0;
  }
  var _default = _exports.default = {
    setupComponent(args, component) {
      component.set("isCAPPAvailable", (0, _discourse_custom_ap_profile.isCAPPAvailable)(this.currentUser));
      if (component.isCAPPAvailable) {
        console.log('setupComponent from user-card-metadata\\user-card-ap-profile.js');
        console.log('args:');
        console.log(args);
        const isSelf = args.user && this.currentUser && args.user.id === this.currentUser.id;
        let OwnProfileScore = -1;
        console.log('w1');
        if (this.currentUser.custom_fields) {
          if (isValidValue(this.currentUser.custom_fields.ageplayRoles)) OwnProfileScore++;
          if (isValidValue(this.currentUser.custom_fields.care_age)) OwnProfileScore++;
          if (isValidValue(this.currentUser.custom_fields.countryDistrict)) OwnProfileScore++;
          if (isValidValue(this.currentUser.custom_fields.diaper_usage)) OwnProfileScore++;
          if (isValidValue(this.currentUser.custom_fields.favorite_diapers)) OwnProfileScore++;
          if (isValidValue(this.currentUser.custom_fields.handicap)) OwnProfileScore++;
          if (isValidValue(this.currentUser.custom_fields.interests)) OwnProfileScore++;
          if (isValidValue(this.currentUser.custom_fields.gender)) OwnProfileScore++;
          if (isValidValue(this.currentUser.custom_fields.lookingFor)) OwnProfileScore++;
          if (isValidValue(this.currentUser.custom_fields.name)) OwnProfileScore++;
          if (isValidValue(this.currentUser.custom_fields.play_age)) OwnProfileScore++;
          if (isValidValue(this.currentUser.custom_fields.relationship)) OwnProfileScore++;
          if (isValidValue(this.currentUser.custom_fields.relationship_with)) OwnProfileScore++;
          if (isValidValue(this.currentUser.custom_fields.sexualOrientation)) OwnProfileScore++;
          if (isValidValue(this.currentUser.custom_fields.socialNetworks)) OwnProfileScore++;
        }
        console.log('w2');
        component.set("isSelf", isSelf);
        console.log('w3');
        component.set("isNotLooking", isValidValue(this.currentUser.custom_fields.lookingFor) && this.currentUser.custom_fields.lookingFor.includes('nothing'));
        console.log('w4');
        component.set("canImproveOwnProfile", OwnProfileScore < this.siteSettings.custom_ap_profile_show_tips_until_num_profile_data && !component.get("isNotLooking"));
        console.log('w5');
      }
    }
  };
});