define("discourse/plugins/discourse-custom-ap-profile/discourse/controllers/discourse-custom-ap-profile-index", ["exports", "@ember/controller", "discourse-common/utils/decorators", "discourse/models/user"], function (_exports, _controller, _decorators, _user) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend(dt7948.p({
    isLoggedIn() {
      return _user.default.current();
    },
    init() {
      console.log('init from controllers\\discourse-ap-profile-index.js.es6');
      this._super();
      this.set('genders', []);
      this.fetchGenders();
      console.log('init');
    },
    setupComponent(args, component) {
      console.log('setupComponent from controllers\\discourse-ap-profile-index.js.es6');
      const genders = args.model.siteSettings.custom_ap_profile_genders.split("|").map(gender => {
        return {
          name: I18n.t("custom_ap_profile.gender_" + gender).substr(0, 1) === "[" ? gender : I18n.t("custom_ap_profile.gender_" + gender),
          value: gender
        };
      });
      let ageplay_roles = args.model.siteSettings.custom_ap_profile_ageplay_roles.split("|").map(ageplay_role => {
        return {
          name: I18n.t("custom_ap_profile.ageplay_role_" + ageplay_role).substr(0, 1) === "[" ? ageplay_role : I18n.t("custom_ap_profile.ageplay_role_" + ageplay_role),
          value: ageplay_role
        };
      });
      component.setProperties({
        genders,
        ageplay_roles
        //      sexual_orientations,
        //      looking_for_list,
        //      ageplayRoles,
      });
    },
    fetchGenders() {
      console.log('gendertest');
      console.log(this);
      //    this.store.findAll('note')
      //      .then(result => {
      //        for (const note of result.content) {
      //          this.notes.pushObject(note);
      //        }
      //      })
      //      .catch(console.error);
      this.genders = this.siteSettings.ap_profile_genders.split("|").map(gender => {
        return {
          name: I18n.t("custom_ap_profile.gender_" + gender).substr(0, 1) === "[" ? gender : I18n.t("custom_ap_profile.gender_" + gender),
          value: gender
        };
      });
    }
  }, [["method", "isLoggedIn", [(0, _decorators.default)()]]]));
});