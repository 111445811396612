define("discourse/plugins/discourse-custom-ap-profile/discourse/initializers/custom-ap-profile", ["exports", "I18n", "discourse/plugins/discourse-custom-ap-profile/discourse/lib/cakeday", "discourse/lib/ajax", "discourse-common/utils/decorators", "discourse/lib/plugin-api", "discourse-common/lib/helpers", "discourse-common/lib/icon-library", "virtual-dom", "discourse/models/post", "discourse/models/topic", "discourse/widgets/raw-html", "@ember/utils", "discourse/plugins/discourse-custom-ap-profile/discourse/lib/discourse_custom_ap_profile"], function (_exports, _I18n, _cakeday, _ajax, _decorators, _pluginApi, _helpers, _iconLibrary, _virtualDom, _post, _topic, _rawHtml, _utils, _discourse_custom_ap_profile) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.SEARCH_TYPE_USERS = void 0;
  //post og topic er ikke i brug endnu...

  const SEARCH_TYPE_USERS = _exports.SEARCH_TYPE_USERS = "users";
  let cmpnt = null;

  // async fetchDiapers() {

  //   if (typeof library !== 'undefined' && library !== '') {
  //     console.log('searching library');
  //     let filter = 'c/library/' + library;
  //     console.log('filter is');
  //     console.log(filter);

  //     let diapers2 = [];
  //     let promises = [];
  //     promises.push(postModel.store.findFiltered('topicList', { filter, params: {}}).then(list => {
  //       console.log('list');
  //       console.logf(list);
  //       list.topics.slice(1).forEach(function(item) {
  //         console.log('LI2: ' + item.title.split(' :')[0]);
  //         diapers2.push(item.title);
  //       });
  //     }));

  //     // Use Promise.all to wait for all promises to resolve
  //     Promise.all(promises).then(() => {
  //       console.log('diaper checking');
  //       return diapers2;
  //     });
  //   }
  //   else return;
  // }

  function initializeApProfile(api, router) {
    const currentUser = api.getCurrentUser();
    if (!currentUser) {
      return;
    }
    if ((0, _discourse_custom_ap_profile.isCAPPAvailable)(currentUser)) {
      const siteSettings = api.container.lookup("site-settings:main");
      const show_debug_info = siteSettings.custom_ap_profile_show_debug_info;
      (0, _discourse_custom_ap_profile.showLog)(show_debug_info, "### initializeApProfile from initializers/custom-ap-profile.js");
      const SEARCH_TYPE_USERS = "users";
      api.includePostAttributes("diaper_type");
      api.includePostAttributes("diaper_manufacturer_id");
      api.decorateWidget("poster-name:after", helper => {
        const post = helper.getModel();
        if (typeof post.user_handicap !== 'undefined' && post.user_handicap !== null && post.user_handicap !== 'na' && post.user_handicap.length > 0) {
          var hcList = "";
          for (var hc of post.user_handicap) {
            hcList += (hcList !== "" ? ", " : "") + _I18n.default.t('custom_ap_profile.handicap_' + hc);
          }
          return helper.h(`span.handicap`, {
            title: hcList
          }, (0, _iconLibrary.iconNode)("wheelchair"));
        }
        ;
      });
      api.decorateWidget("post-contents:after-cooked", dec => {
        // console .log('post-contents:after-cooked start');

        if (dec.attrs.post_number === 1) {
          const postModel = dec.getModel();
          if (postModel) {
            const topic = postModel.topic;
            console.log(topic);
            const library = siteSettings.custom_ap_profile_diaper_library;
            const library_manufacturers = siteSettings.custom_ap_profile_diaper_library_manufacturers;
            const isLibrary = library == topic.category_id;
            const isManufacturer = library_manufacturers == topic.category_id;

            //if ([library, library_manufacturers].includes(topic.category_id)) {
            if (isLibrary || isManufacturer) {
              let target_users = '';
              if (topic.diaper_target_user !== null) {
                [].concat(topic.diaper_target_user).forEach(x => {
                  // console.log(x);
                  target_users += (target_users !== '' ? ', ' : '') + _I18n.default.t("custom_ap_profile.diaper_target_user_" + x);
                });
              }
              let table = '';
              let datatable = '';
              let diapers = [];
              let diaperline;
              let hasMySize = false;
              let hasWaist = false;
              let hasWaistComplete = true;
              if (topic.diaperdata !== null) {
                let ddd2 = JSON.parse(topic.diaperdata);
                ddd2.forEach(x => {
                  const waist = parseInt(currentUser.custom_fields.waist);
                  console.log(x.data);
                  console.log(x.data.waist);
                  const waistdata = typeof x.data.waist !== 'undefined' ? x.data.waist.split('-') : [null, null];
                  if (waistdata) {
                    hasWaist = true;
                  } else {
                    hasWaistComplete = false;
                  }
                  const waistmin = parseInt(waistdata[0]);
                  const waistmax = parseInt(waistdata[1]);
                  // const isWithin = waist >= waistmin && (waist <= waistmax || isNaN(waistmax));
                  const isWithin = waist >= waistmin && waist <= waistmax;
                  const tdclass = isWithin ? 'mysize' : '';
                  console.log([waist, waistdata, waistmin, waistmax, isWithin, tdclass]);
                  diaperline = '';
                  diaperline += '<td class="' + tdclass + ' diaper_size">' + _I18n.default.t("custom_ap_profile.diaper_size_short_" + x.size) + '</td>';
                  if (topic.diaper_target_user && topic.diaper_target_user.includes('child')) {
                    diaperline += '<td class="' + tdclass + ' diaper_weight">' + (x.data.weight_child ? x.data.weight_child + ' kg.' : '-') + '</td>';
                  }
                  diaperline += '<td class="' + tdclass + ' diaper_waist">' + (x.data.waist ? x.data.waist + ' cm.' : '-') + '</td>';
                  diaperline += '<td class="' + tdclass + ' diaper_capacity">' + (x.data.capacity ? x.data.capacity + (x.data.realcap ? ' (' + x.data.realcap + ')' : '') + ' ml.' : '-') + '</td>';
                  diaperline += '<td class="' + tdclass + ' diaper_count">' + (typeof x.data.bagcount !== 'undefined' ? x.data.bagcount : '?') + (x.data.bagcount ? '/' : '') + (typeof x.data.boxcount !== 'undefined' ? x.data.boxcount : '?') + '</td>';
                  if (isWithin) {
                    hasMySize = true;
                    // diaperline += '<td class="' + tdclass + ' diaper_isWithinSize">' + h('span', iconHTML("point_left")) + '</td>';
                    // diaperline += '<td class="' + tdclass + ' diaper_isWithinSize">' + h('span', iconNode("point_left")) + '</td>';
                    // console.log(h('span', iconNode("point_left")));
                    diaperline += '<td class="' + tdclass + ' diaper_isWithinSize"><span class="mysize" title="' + _I18n.default.t("custom_ap_profile.this_is_your_diaper_size") + '"><svg class="fa d-icon d-icon-hand-point-left svg-icon svg-string" xmlns="http://www.w3.org/2000/svg"><use href="#hand-point-left"></use></svg></span></td>';
                  } else if (hasMySize) diaperline += '<td class="' + tdclass + '"></td>';else {
                    diaperline += '[mysizeiconcol]';
                  }
                  diaperline += '<td class="' + tdclass + ' diaper_cart">';
                  diaperline += '<span title="' + _I18n.default.t("custom_ap_profile.sale_sell_this_diaper") + '"><a href="' + window.location.origin.replace('/', '//') + '/new-topic?title=Sælges%3A%20' + topic.title.split(' :')[0] + ' - ' + _I18n.default.t("custom_ap_profile.diaper_size_" + x.size) + (x.data.weight_child ? ' (' + x.data.weight_child + ' kg.)' : '') + '&body=Hvorfor%20sælger%20du%3F%20%0DHvor%20mange%20har%20du%3F%20%0DHvorfra%20kan%20de%20hentes%3F%20%0DKan%20de%20sendes%3F%20%0D%0DSe%20flere%20detailer%3A%20' + encodeURIComponent(window.location.origin) + topic.url + '&category=kob-salg/privat-kob-og-salg"><svg class="fa d-icon d-icon-bullhorn svg-icon svg-string" xmlns="http://www.w3.org/2000/svg"><use href="#bullhorn"></use></svg></a></span>';
                  diaperline += ' ';
                  diaperline += '<span title="' + _I18n.default.t("custom_ap_profile.sale_give_this_diaper") + '"><a href="' + window.location.origin.replace('/', '//') + '/new-topic?title=Bortgives%3A%20' + topic.title.split(' :')[0] + ' - ' + _I18n.default.t("custom_ap_profile.diaper_size_" + x.size) + '&body=Hvorfor%20bortgiver%20du%3F%20%0DHvor%20mange%20har%20du%3F%20%0DHvorfra%20kan%20de%20hentes%3F%20%0DKan%20de%20sendes%3F%20%0D%0DSe%20flere%20detailer%3A%20' + encodeURIComponent(window.location.origin) + topic.url + '&category=kob-salg/privat-kob-og-salg"><svg class="fa d-icon d-icon-gift svg-icon svg-string" xmlns="http://www.w3.org/2000/svg"><use href="#gift"></use></svg></a></span>';
                  diaperline += '</td>';

                  //diapers.push(x.size, diaperline);
                  diapers.push(x.size);
                  diapers[x.size] = diaperline;
                });
                // console.log(topic);
                const diaper_sizes = siteSettings.custom_ap_profile_diaper_sizes;
                // console.log(diaper_sizes);
                const diaper_sizes_sorted = diaper_sizes.split("|").filter(size => topic.diaper_sizes.includes(size));
                diaper_sizes_sorted.forEach(x => {
                  // console.log(x);
                  table += '<tr>';
                  table += ('' + diapers[x]).replace(/\[mysizeiconcol\]/g, hasMySize ? '<td class=""></td>' : '');
                  table += '</tr>';
                });
              }
              if (table !== '') {
                datatable = '<table><tr>';
                datatable += '<th>' + _I18n.default.t("custom_ap_profile.size") + '</th>';
                if (topic.diaper_target_user && topic.diaper_target_user.includes('child')) {
                  datatable += '<th>' + _I18n.default.t("custom_ap_profile.weight") + '</th>';
                }
                datatable += '<th>' + _I18n.default.t("custom_ap_profile.hip_or_waist") + '</th>';
                datatable += '<th>' + _I18n.default.t("custom_ap_profile.capacity") + '</th>';
                datatable += '<th>' + 'Count' + '</th>';
                datatable += '</tr>';
                datatable += table + '</table>';
              }
              const setasd = function (v) {
                diaper_manufacturer = v + '<br />';
              };
              let diaper_manufacturer = !isManufacturer && topic.diaper_manufacturer !== null ? '<span class="header">' + _I18n.default.t("custom_ap_profile.diaper_manufacturer") + '</span>: ' + (topic.diaper_manufacturer_id !== null && topic.diaper_manufacturer_id > 0 ? '<a href="/t/' + topic.diaper_manufacturer + '/' + topic.diaper_manufacturer_id + '">' + topic.diaper_manufacturer + '</a>' : topic.diaper_manufacturer) + '<br />' : '';
              const fetchTopics = function (url) {
                return (0, _ajax.ajax)(url).then(content => {
                  // Filter out hidden topics
                  const visibleTopics = content.topic_list.topics.filter(topic => topic.visible);
                  const topics = visibleTopics.map(topic => ({
                    title: topic.title,
                    slug: topic.slug,
                    id: topic.id,
                    diaper_drops: topic.diaper_drops,
                    diaper_manufacturer: topic.diaper_manufacturer,
                    diaper_manufacturer_id: topic.diaper_manufacturer_id,
                    diaper_brandgroup: topic.diaper_brandgroup
                  }));
                  if (content.topic_list.more_topics_url) {
                    // If more topics are available, recursively fetch them after a timeout
                    return new Promise(resolve => {
                      setTimeout(() => {
                        fetchTopics(content.topic_list.more_topics_url).then(moreTopics => {
                          // Concatenate the current visible topics with the fetched additional visible topics
                          resolve({
                            topics: topics.concat(moreTopics.topics)
                          });
                        });
                      }, 200); // 200 milliseconds timeout
                    });
                  } else {
                    // No more topics, just return the current visible topics
                    return {
                      topics
                    };
                  }
                }).catch(error => {
                  console.error('Error fetching topics:', error);
                });
              };
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'library:');
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, library);

              // document.addEventListener('DOMContentLoaded', function() {

              // Check if library is defined before constructing the URL
              if (typeof library !== 'undefined') {
                // Use the /none.json URL to prevent topics from subcategories
                const url = '/c/library/' + library + '/none.json';

                // Start fetching topics with the constructed URL
                fetchTopics(url).then(result => {
                  const visibleDiapers = result.topics.map(item => {
                    const diaperDrops = parseFloat(item.diaper_drops) || 0;
                    const index = diaperDrops * 10;

                    // Build the URL using the slug and id properties
                    const diaperUrl = '/t/' + item.slug + '/' + item.id;

                    // Apply additional filter
                    const topicIsManufacturer = item.diaper_manufacturer_id === topic.id;
                    const topicIsSameBrandgroup = item.diaper_brandgroup !== null && item.diaper_brandgroup === topic.diaper_brandgroup && item.id !== topic.id;
                    const topicIsSameManufacturer = topic.diaper_brandgroup === null && (item.diaper_manufacturer === topic.diaper_manufacturer && topic.diaper_manufacturer !== null || item.diaper_manufacturer_id === topic.diaper_manufacturer_id && topic.diaper_manufacturer_id > 0) && item.id !== topic.id;

                    // console.warn({'isManufacturer': topicIsManufacturer, 'isSameBrandgroup': topicIsSameBrandgroup, 'isSameManufacturer': topicIsSameManufacturer})
                    // console.log({'tid': topic.id, 'tm': topic.diaper_manufacturer, 'tmid': topic.diaper_manufacturer_id});
                    // console.log({'iid': item.id, 'im': item.diaper_manufacturer, 'imid': item.diaper_manufacturer_id, 'ititle': item.title});

                    // if (item.diaper_manufacturer_id === topic.id || (item.diaper_brandgroup !== null && item.diaper_brandgroup === topic.diaper_brandgroup && item.id !== topic.id)) {
                    if (topicIsManufacturer || topicIsSameBrandgroup || topicIsSameManufacturer) {
                      return {
                        html: '<li><a href="' + diaperUrl + '">' + item.title + '</a></li>',
                        title: item.title,
                        drops: diaperDrops
                      };
                    } else {
                      return null; // Filter out items that don't meet the criteria
                    }
                  }).filter(item => item !== null); // Remove null entries

                  // Sort visibleDiapers by drops then title
                  visibleDiapers.sort((a, b) => {
                    // Sort by drops in ascending order
                    if (isLibrary) {
                      if (a.drops !== b.drops) {
                        return a.drops - b.drops;
                      }
                    }
                    // If drops are equal or isLibrary, sort by title in ascending order (case-insensitive)
                    const nameA = a.title.toLowerCase();
                    const nameB = b.title.toLowerCase();
                    if (nameA < nameB) {
                      return -1;
                    }
                    if (nameA > nameB) {
                      return 1;
                    }
                    return 0; // names are equal
                  });

                  // Output a list of '<li>' items to the DOM
                  // diaperlist.innerHTML += (isManufacturer ? '' : '<br />') + (isManufacturer ? I18n.t("custom_ap_profile.diapers") : I18n.t("custom_ap_profile.related_diapers")) + ':<ul>' + visibleDiapers.map(result => result.html).join('\n') + '</ul>';

                  const diaperlist = document.getElementById('diaperlist');
                  if (diaperlist && visibleDiapers.length > 0) {
                    diaperlist.innerHTML = (isManufacturer ? '' : '<br />') + (isManufacturer ? _I18n.default.t("custom_ap_profile.diapers") : _I18n.default.t("custom_ap_profile.related_diapers")) + ':<ul>' + visibleDiapers.map(result => result.html).join('\n') + '</ul>';
                  }
                });
              }
              let diaper_brandgroup = topic.diaper_brandgroup !== null ? '<span class="header">' + _I18n.default.t("custom_ap_profile.diaper_brandgroup") + '</span>: ' + topic.diaper_brandgroup + '<br />' : '';
              let diaper_closure_type = topic.diaper_closure_type !== null ? '<span class="header">' + _I18n.default.t("custom_ap_profile.diaper_closure_type") + '</span>: ' + _I18n.default.t("custom_ap_profile.diaper_closure_type_" + topic.diaper_closure_type) + '<br />' : '';
              let diaper_features = null;
              if (topic.diaper_features !== null) {
                let featuresArray = Array.isArray(topic.diaper_features) ? topic.diaper_features : [topic.diaper_features];
                let joinedFeatures = featuresArray.map(feature => {
                  return {
                    key: feature,
                    value: _I18n.default.t("custom_ap_profile.diaper_features_" + feature).substr(0, 1) === "[" ? feature : _I18n.default.t("custom_ap_profile.diaper_features_" + feature)
                  };
                }).map(featureObj => featureObj.value).join(', ');
                diaper_features = '<span class="header">' + _I18n.default.t("custom_ap_profile.diaper_features") + '</span>: ' + joinedFeatures + '<br />';
              } else {
                diaper_features = ''; // Håndter situationen, hvor topic.diaper_features er null
              }
              let diaper_protection = typeof topic.diaper_protection !== 'undefined' && topic.diaper_protection !== null ? '<span class="header">' + _I18n.default.t("custom_ap_profile.diaper_protection") + '</span>: ' + _I18n.default.t("custom_ap_profile.diaper_protection_" + topic.diaper_protection) + '<br />' : '';
              let diaper_sizes = topic.diaper_sizes !== null ? '<span class="header">' + _I18n.default.t("custom_ap_profile.diaper_sizes") + '</span>: ' + topic.diaper_sizes + '<br />' : '';
              let diaper_target_user = typeof topic.target_user !== 'undefined' && topic.target_user !== null ? '<span class="header">' + _I18n.default.t("custom_ap_profile.diaper_target_user") + '</span>: ' + target_users + '<br />' : '';
              let diaper_type = topic.diaper_type !== null ? '<span class="header">' + _I18n.default.t("custom_ap_profile.diaper_type") + '</span>: ' + _I18n.default.t("custom_ap_profile.diaper_type_" + topic.diaper_type) + '<br />' : '';
              let diaper_fitment = table !== '' && hasWaist ? '<div>' + (hasMySize ? 'Du har en eller flere blestørrelser du burde kunne passe ud fra din indtastning på din <a href="/my/preferences/profile#waist">Profil</a>' : 'Hvis din størrelse ikke er markeret ovenfor, kan det skyldes at du ikke har indtastet din hoftestørrelse på din <a href="/my/preferences/profile#waist">Profil</a> eller at data mangler at blive indtastet, enkelte bleer har huller i deres opgivne data fra producenten.') + (topic.diaper_target_user && topic.diaper_target_user.includes('child') && !hasWaistComplete ? (table !== '' && hasWaist ? '<br /><br />' : '') + 'Hvis du kan passe denne ble, skriv venligst dit hofte/taljemål, hvordan den passer og evt. hvordan du bruger den.' : '') + '</div>' : '';

              // if (topic.diaper_target_user.includes('child')) {
              //   diaper_fitment += '';
              // }

              return [dec.h("hr"), dec.h("div", new _rawHtml.default({
                html: `<div class='cooked'>
                    ${diaper_manufacturer}
                    ${diaper_brandgroup}
                    ${diaper_closure_type}
                    ${diaper_features}
                    ${diaper_protection}
                    ${diaper_sizes}
                    ${diaper_target_user}
                    ${diaper_type}
                    ${datatable}
                    ${diaper_fitment}<div id="diaperlist"></div></div>`
              }))];
            }
          } else {
            return;
          }
          ;
        } else {
          return;
        }
        ;
      });
      api.onPageChange((url, title) => {
        // showLog(show_debug_info, 'the page changed to: ' + url + ' and title ' + title);
        // showLog(show_debug_info, api);

        if (url.indexOf('/preferences/') !== -1 && url.indexOf('#') === -1 && url.indexOf('?') === -1) {
          window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
        }
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, currentUser.preferencesProfilePath);
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, url);
        //showLog(show_debug_info, document.getElementById('userProfileControlEditProfile'));
        let btn = document.getElementById('userProfileControlEditProfile');
        //if (typeof btn !== 'undefined')
        if (btn !== null) btn.style.display = url + '/' === currentUser.preferencesProfilePath ? 'none' : 'flex';
      });
      api.modifyClass('component:user-card-contents', {
        pluginId: "discourse-custom-ap-profile",
        can_send_private_message_to_user: false
      });
      api.modifyClass("controller:preferences/profile", {
        pluginId: "discourse-custom-ap-profile",
        actions: {
          save() {
            (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'saving');
            if (this.model.name !== '' || !(currentUser.admin || currentUser.moderator)) this.saveAttrNames.push("name");
            this.saveAttrNames.push("muted_category_ids");
            (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'save1');
            let ageplayRoles = this.model.custom_fields.ageplayRoles;
            (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'save2');
            let hasAgeplayRole = typeof ageplayRoles !== 'undefined' && ageplayRoles !== null && ageplayRoles !== "" && ageplayRoles.length > 0 && ageplayRoles[0].length > 0;
            (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'save3');
            let isABCT = false;
            let isABTB = false;
            (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'save4');
            if (hasAgeplayRole) {
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'save5');
              isABCT = hasAgeplayRole && ageplayRoles.filter(val => val !== '' && this.siteSettings.custom_ap_profile_abct_roles.split('|').includes(val)).length > 0;
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'save6');
              isABTB = hasAgeplayRole && ageplayRoles.filter(val => val !== '' && this.siteSettings.custom_ap_profile_abtb_roles.split('|').includes(val)).length > 0;
            }
            (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'save7');
            if (!isABCT) {
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'save8');
              this.model.set("custom_fields.care_age", null);
            }
            (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'save9');
            if (!isABTB) {
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'save10');
              this.model.set("custom_fields.play_age", null);
            }
            (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'done custom saving');
            this._super(...arguments);
          },
          scrolltome() {
            alert('found');
          }
        }
      });

      // PreferencesController.reopen({
      // @observes("gender")
      // _setUserGenderPref() {
      // const user = this.get("model");

      // user.set("gender", this.get("gender"));
      // },
      // });

      api.addUserSearchOption("adminsOnly");
      api.modifyClass("controller:full-page-search", dt7948.p({
        pluginId: "discourse-custom-ap-profile",
        init() {
          this._super(...arguments);

          //this.set("searchedTerms.withCategoryExpertResponse", null);
          // this.setProperties({
          // usingUsersSearchType,
          // });
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'init this:');
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, this);
          //      this.set("usingUsersSearchType", true);
        },
        usingUsersSearchType(searchType) {
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, "Evaluating usingUsersSearchType");
          return searchType === SEARCH_TYPE_USERS;
        }
      }, [["method", "usingUsersSearchType", [(0, _decorators.default)("search_type")]]]));
      api.modifyClass("controller:preferences", dt7948.p({
        pluginId: "discourse-custom-ap-profile",
        isPreferencesProfile() {
          //console.clear();
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'router:');
          if (typeof router !== 'undefined') (0, _discourse_custom_ap_profile.showLog)(show_debug_info, router);else (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'ruter not defined');
          return router.currentPath === "user.preferences.profile";
          //return document.location.href.indexOf("preferences/profile") > 0
        },
        preferencesRoute(currentRouteName, currentURL) {
          return currentRouteName.indexOf("preferences") > -1;
        },
        _setIsPreferencesProfile() {
          const isPreferencesProfile = this.get("isPreferencesProfile");
          const user = this.get("model");
          user.set("isPreferencesProfile", isPreferencesProfile);
        },
        _setUserGenderPref() {
          const user = this.get("model");
          user.set("gender", this.get("gender"));
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'user');
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, user);
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'this');
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, this);
        },
        _setrelationship() {
          const user = this.get("model");
          user.set("relationship", this.get("relationship"));
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'user');
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, user);
        },
        _setsexualOrientation() {
          const user = this.get("model");
          user.set("sexualOrientation", this.get("sexualOrientation"));
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'user');
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, user);
        }

        // let lookingFor = args.model.get("lookingFor");

        // @discourseComputed
        // genders() {
        // return this.siteSettings.custom_ap_profile_genders
        // .split("|")
        // //.filter(Boolean);
        // },

        // @discourseComputed
        // ageplay_roles() {
        // // return this.siteSettings.custom_ap_profile_ageplay_roles
        // // .split("|")
        // // .filter(Boolean);
        // },
      }, [["method", "isPreferencesProfile", [(0, _decorators.default)("model.isPreferencesProfile")]], ["method", "preferencesRoute", [(0, _decorators.default)("router.currentRouteName", "router.currentURL")]], ["method", "_setIsPreferencesProfile", [(0, _decorators.observes)("model.isPreferencesProfile")]], ["method", "_setUserGenderPref", [(0, _decorators.observes)("model.custom_fields.gender")]], ["method", "_setrelationship", [(0, _decorators.observes)("model.custom_fields.relationship")]], ["method", "_setsexualOrientation", [(0, _decorators.observes)("model.custom_fields.sexualOrientation")]]]));

      //this.set("model.custom_fields.gender.required", true);

      api.includePostAttributes("user_handicap");
      const genders2 = siteSettings.custom_ap_profile_genders.split("|").map(gender => {
        return {
          name: _I18n.default.t("gender_" + gender).substr(0, 1) === "[" ? gender : _I18n.default.t("gender_" + gender),
          value: gender
        };
      });
      (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'genders2');
      (0, _discourse_custom_ap_profile.showLog)(show_debug_info, genders2);
      let ageplay_roles = siteSettings.custom_ap_profile_ageplay_roles.split("|").map(ageplay_role => {
        return {
          name: _I18n.default.t("ageplay_role_" + ageplay_role).substr(0, 1) === "[" ? ageplay_role : _I18n.default.t("ageplay_role_" + ageplay_role),
          value: ageplay_role
        };
      });

      //checkAgeplayRoles();

      // function userGenderIcon(user) {
      // // showLog(show_debug_info, 'userGenderIcon');
      // return 'male';
      // }

      (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'currentUser:' + currentUser);
      (0, _discourse_custom_ap_profile.showLog)(show_debug_info, currentUser);

      //const diaper_data = 'diaperdata';
      // const topic_string_fields = ['diaper_brandgroup',
      // 'diaper_manufacturer',
      // 'diaper_target_users',
      // 'diaper_type'];
      // const topic_array_fields = ['diaper_sizes'];
      // const topic_json_fields = ['diaperdata'];
      // const topic_fields = [...topic_string_fields,
      // ...topic_array_fields,
      // ...topic_json_fields];

      const topic_fields = {
        'diaper_protection': 'string',
        'diaper_brandgroup': 'string',
        'diaper_closure_type': 'string',
        'diaper_drops': 'string',
        'diaper_features': 'string_array',
        'diaper_manufacturer': 'string',
        'diaper_manufacturer_id': 'integer',
        'diaper_target_user': 'string_array',
        'diaper_type': 'string',
        'diaper_sizes': 'string_array',
        'diaperdata': 'json'
      };

      // giver ikke mening, når der samtidigt er oprettet en js fil der indlæses først/sidst
      // showLog(show_debug_info, 'cf1');
      // // api.registerConnectorClass('d-editor', 'd-editor-plugin-outlet-plugin-connector', {
      // api.registerConnectorClass('composer-fields', 'composer-topic-custom-field-container', {
      // setupComponent(attrs, component) {
      // alert('api setupComponent - not firing');
      // const model = attrs.model;

      // for (const tf in topic_fields) {
      // showLog(show_debug_info, 'cf1a');
      // // If the first post is being edited we need to pass our value from
      // // the topic model to the composer model.
      // if (!isDefined(model[tf]) && model.topic && model.topic[tf]) {
      // model.set(tf, model.topic[tf]);
      // }

      // showLog(show_debug_info, 'cf1b');
      // let props = {
      // fieldName: tf,
      // fieldValue: model.get(tf)
      // }
      // showLog(show_debug_info, 'cf1c');
      // if (topic_fields.hasOwnProperty(tf)) {
      // const fieldType = topic_fields[tf];
      // component.setProperties(Object.assign(props, fieldInputTypes(fieldType)));
      // }
      // showLog(show_debug_info, 'cf1d');
      // }
      // },

      // actions: {
      // onChangeDiaperBrandgroup(fieldValue) {
      // this.set(`model.diaper_brandgroup`, fieldValue);
      // },
      // onChangeDiaperManufacturer(fieldValue) {
      // this.set(`model.diaper_manufacturer`, fieldValue);
      // },
      // onChangeDiaperTargetUsers(fieldValue) {
      // this.set(`model.diaper_target_users`, fieldValue);
      // },
      // onChangeDiaperType(fieldValue) {
      // this.set(`model.diaper_type`, fieldValue);
      // },
      // onChangeDiaperSizes(fieldValue) {
      // this.set(`model.diaper_sizes`, fieldValue);
      // },
      // onChangeDiaperData(fieldValue) {
      // this.set(`model.diaperdata`, fieldValue);
      // }
      // }
      // });

      // /*
      // * type:        step
      // * number:      6.1
      // * title:       Setup the edit topic connector class
      // * description: Set the actions and properties you'll need in the edit
      // *              topic connector template.
      // * references:  app/assets/javascripts/discourse/app/components/plugin-outlet.js.es6
      // */
      // showLog(show_debug_info, 'cf2');
      // api.registerConnectorClass('edit-topic', 'edit-topic-custom-field-container', {
      // setupComponent(attrs, component) {
      // const model = attrs.model;

      // for (const tf in topic_fields) {
      // let props = {
      // fieldName: tf,
      // fieldValue: model.get(tf)
      // }
      // if (topic_fields.hasOwnProperty(tf)) {
      // const fieldType = topic_fields[tf];
      // component.setProperties(Object.assign(props, fieldInputTypes(fieldType)));
      // }
      // }
      // },

      // actions: {

      // onChangeDiaperBrandgroup(fieldValue) {
      // this.set(`buffered.diaper_brandgroup`, fieldValue);
      // },
      // onChangeDiaperManufacturer(fieldValue) {
      // this.set(`buffered.diaper_manufacturer`, fieldValue);
      // },
      // onChangeDiaperTargetUsers(fieldValue) {
      // this.set(`buffered.diaper_target_users`, fieldValue);
      // },
      // onChangeDiaperType(fieldValue) {
      // this.set(`buffered.diaper_type`, fieldValue);
      // },
      // onChangeDiaperSizes(fieldValue) {
      // this.set(`buffered.diaper_sizes`, fieldValue);
      // },
      // onChangeDiaperData(fieldValue) {
      // this.set(`buffered.diaperdata`, fieldValue);
      // }
      // }
      // });

      // /*
      // * type:        step
      // * number:      7
      // * title:       Serialize your field to the server
      // * description: Send your field along with the post and topic data saved
      // *              by the user when creating a new topic, saving a draft, or
      // *              editing the first post of an existing topic.
      // * references:  app/assets/javascripts/discourse/app/lib/plugin-api.js.es6,
      // *              app/assets/javascripts/discourse/app/models/composer.js.es6
      // */
      // showLog(show_debug_info, 'cf3');
      // // showLog(show_debug_info, diaper_data);
      // // if (typeof diaper_data === 'undefined') {
      // // diaper_data = {};
      // // }
      // // showLog(show_debug_info, diaper_data);

      //disse er vigtige for at der gemmes data
      for (const tf in topic_fields) {
        api.serializeOnCreate(tf);
        api.serializeToDraft(tf);
        api.serializeToTopic(tf, `topic.${tf}`);
      }
      console.log('all is good, we are ready to save');

      // /*
      // * type:        step
      // * number:      8.1
      // * title:       Setup the topic title connector component
      // * description: Set the actions and properties you'll need in the topic
      // *              title
      // *              connector template.
      // * references:  app/assets/javascripts/discourse/app/components/plugin-outlet.js.es6
      // */
      // showLog(show_debug_info, 'cf4');
      // api.registerConnectorClass('topic-title', 'topic-title-custom-field-container', {
      // setupComponent(attrs, component) {
      // const model = attrs.model;
      // const controller = container.lookup('controller:topic');

      // component.setProperties({
      // diaper_data: diaper_data,
      // fieldValue: model.get(diaper_data),
      // showField: !controller.get('editingTopic') && isDefined(model.get(diaper_data))
      // });

      // controller.addObserver('editingTopic', () => {
      // if (this._state === 'destroying') return;
      // component.set('showField', !controller.get('editingTopic') && isDefined(model.get(diaper_data)));
      // });

      // model.addObserver(diaper_data, () => {
      // if (this._state === 'destroying') return;
      // component.set('fieldValue', model.get(diaper_data));
      // });
      // }
      // });
    }
  }

  // export function chooseGender(canFilterGender) {
  // if (canFilterGender) {
  // return "custom_ap_profile.choose_gender";
  // } else {
  // return "custom_ap_profile.missing_your_gender";
  // }
  // }

  // export function userGenderIcon(user) {
  // //showLog(show_debug_info, 'userGenderIcon');
  // return 'male';
  // }
  var _default = _exports.default = {
    name: "discourse-custom-ap-profile-initializer",
    initialize(container) {
      const router = container.lookup("router:main");
      (0, _pluginApi.withPluginApi)("0.11.2", api => initializeApProfile(api, router));
    }
    /*,
     setupComponent(args, component) {
      cmpnt = component;
      
      //is this really firing?
      showLog(show_debug_info, "setupComponent from initializers/custom-ap-profile.js")
      showLog(show_debug_info, args);
      showLog(show_debug_info, component);
          
      const genders = { options: args.model.siteSettings.custom_ap_profile_genders
        .split("|").map((gender) => {
        return { name: I18n.t("gender_" + gender).substr(0,1) === "[" ? gender: I18n.t("gender_" + gender), value: gender };
      }), required: true };
       let ageplay_roles = args.model.siteSettings.custom_ap_profile_ageplay_roles
            .split("|").map((ageplay_role) => {
        return { name: I18n.t("ageplay_role_" + ageplay_role).substr(0,1) === "[" ? ageplay_role: I18n.t("ageplay_role_" + ageplay_role), value: ageplay_role };
      });
       component.setProperties({
        ageplay_roles,
        genders,
        userGenderIcon,
        // userGenderIcon2,
    //      chooseGender,
    //      sexual_orientations,
    //      looking_for_list,
    //      ageplayRoles,
      });
       //component.set("chooseGender", chooseGender(false));
      component.set("userGenderIcon", 'baby');
      //component.set("userGenderIcon", userGenderIcon(args.user));
      // component.set("userGenderIcon2", userGenderIcon(args.user));
     },
    */
  };
});