define("discourse/plugins/discourse-custom-ap-profile/discourse/lib/cakeday", ["exports", "@ember/utils", "I18n"], function (_exports, _utils, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.birthday = birthday;
  _exports.birthdayTitle = birthdayTitle;
  _exports.cakeday = cakeday;
  _exports.cakedayTitle = cakedayTitle;
  _exports.celebrate = celebrate;
  _exports.secretTitle = secretTitle;
  _exports.userAge = userAge;
  _exports.userAgeTitle = userAgeTitle;
  _exports.userBirthdateText = userBirthdateText;
  _exports.userBirthdateTitle = userBirthdateTitle;
  function cakeday(date) {
    return !(0, _utils.isEmpty)(date) && isSameDay(date, {
      anniversary: true
    });
  }
  function birthday(date) {
    return !(0, _utils.isEmpty)(date) && isSameDay(date);
  }
  function celebrate(u) {
    return u.get('custom_fields.show_birthday_to_be_celebrated') === true;
  }
  function userAge(dateOfBirth) {
    return dateOfBirth ? moment(dateOfBirth, "YYYY-MM-DD").year() !== 1904 ? moment().diff(dateOfBirth, 'years') : null : null;
  }
  function userAgeTitle(user) {
    return user.birthdate && moment(user.birthdate, "YYYY-MM-DD").year() !== 1904 ? userAge(user.birthdate) + ' ' + _I18n.default.t("relative_time_picker.years", {
      count: userAge(user.birthdate)
    }) : null;
  }
  function userBirthdateTitle(user) {
    return user.birthdate && moment(user.birthdate, "YYYY-MM-DD").year() !== 1904 ? moment(user.birthdate).format(_I18n.default.t("dates.long_with_year_no_time")) : null;
  }
  function userBirthdateText(user) {
    let show_year = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
    return user.birthdate ? moment(user.birthdate, "yyyy-mm-dd").year() !== 1904 && show_year ? moment(user.birthdate).format(_I18n.default.t("dates.long_with_year_no_time")) : moment(user.birthdate).format(_I18n.default.t("dates.long_no_year_no_time")) : null;
  }
  function cakedayTitle(user, currentUser) {
    if (user.id === currentUser?.id) {
      return "user.anniversary.user_title";
    } else {
      return "user.anniversary.title";
    }
  }
  function birthdayTitle(user, currentUser) {
    if (user.id === currentUser?.id) {
      return "user.date_of_birth.user_title";
    } else {
      return "user.date_of_birth.title";
    }
  }
  function secretTitle(user, currentUser) {
    if (user.id === currentUser?.id) {
      return "user.date_of_birth.user_secret_title";
    } else {
      return "user.date_of_birth.secret_title";
    }
  }
  function isSameDay(dateString, opts) {
    const now = moment();
    const date = moment(dateString);
    if (opts?.anniversary) {
      if (now.format("YYYY") <= date.format("YYYY")) {
        return false;
      }
    }
    return now.format("MMDD") === date.format("MMDD");
  }
});