define("discourse/plugins/discourse-custom-ap-profile/discourse/lib/discourse_custom_ap_profile", ["exports", "discourse/models/user"], function (_exports, _user) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.fieldInputTypes = fieldInputTypes;
  _exports.genderToIcon = genderToIcon;
  _exports.getSocialUrl = getSocialUrl;
  _exports.isCAPPAvailable = isCAPPAvailable;
  _exports.isDefined = isDefined;
  _exports.showLog = showLog;
  _exports.showWarn = showWarn;
  function getSocialUrl(a, b) {
    // console.log({'a': a, 'b': b});

    if (b) {
      switch (a) {
        case 'discord':
          return 'https://discordapp.com/users/' + b + '/';
        case 'discourse':
          return 'https://meta.discourse.org/u/' + b + '/';
        case 'facebook':
          return 'https://www.facebook.com/' + b + '/';
        case 'fetlife':
          return 'https://fetlife.com/users/' + b + '/';
        case 'flickr':
          return 'https://www.flickr.com/people/' + b + '/';
        case 'instagram':
          return 'https://www.instagram.com/' + b + '/';
        case 'snapchat':
          return 'https://www.snapchat.com/add/' + b + '/';
        case 'skype':
          return 'skype:' + b + '?userinfo';
        case 'telegram':
          return 'https://t.me/' + b + '/';
        case 'tumblr':
          return 'https://www.tumblr.com/register/follow/' + b + '/';
        case 'twitch':
          return 'https://www.twitch.tv/' + b + '/';
        case 'youtube':
          return 'https://www.youtube.com/@' + b + '/';
      }
    } else {
      switch (a) {
        case 'discord':
          return 'https://discordapp.com/';
        case 'discourse':
          return 'https://meta.discourse.org/';
        case 'facebook':
          return 'https://www.facebook.com/';
        case 'fetlife':
          return 'https://fetlife.com/';
        case 'flickr':
          return 'https://www.flickr.com/';
        case 'instagram':
          return 'https://www.instagram.com/';
        case 'snapchat':
          return 'https://www.snapchat.com/';
        case 'skype':
          return 'https://www.skype.com/';
        case 'telegram':
          return 'https://telegram.org/';
        case 'tumblr':
          return 'https://www.tumblr.com/';
        case 'twitch':
          return 'https://www.twitch.tv/';
        case 'youtube':
          return 'https://www.youtube.com/';
      }
    }
  }

  // export function createIconObject(a, index, arr) {
  //   //arr[index] = { name: a, i18n: 'social.' + a, icon: 'fab-' + a, url: getSocialUrl(User.custom_fields[a]) };
  //   // arr[index] = { name: a, i18n: 'social.' + a, icon: 'fab-' + a, url: getSocialUrl(a) };
  //   arr[index] = { name: a, i18n: 'custom_ap_profile.social.' + a, icon: 'fab-' + a, url: getSocialUrl(a, args.user ? args.user[a] : args.model[a]) };
  // };

  function genderToIcon(gender) {
    if (typeof gender !== 'undefined') {
      // console.log('processing gender: ' + gender);
      // console.log('User');
      // console.log(User);
      switch (gender) {
        case 'f':
          return 'female';
        case 'm':
          return 'male';
        case 'nb':
          return 'genderless';
        case 'tm':
        case 'tf':
          return 'transgender';
        default:
          return 'user';
      }
    } else return 'genderless';
  }
  function isCAPPAvailable(user) {
    if (user !== null) {
      const CAPPEnabledGroups = user.siteSettings.custom_ap_profile_enabled_groups;
      console.log(user);
      if (typeof user.group_users !== 'undefined') {
        return user.group_users.filter(g => CAPPEnabledGroups.includes(g.group_id)).length > 0;
      } else {
        return user.groups.filter(g => CAPPEnabledGroups.includes(g.id)).length > 0;
      }
    } else {
      return false;
    }
  }

  // export function showLog(model, log) {
  // if (model.siteSettings.custom_ap_profile_show_debug_info) {
  // console.log(log);
  // }
  // }

  // export function showWarn(model, warn) {
  // if (model.siteSettings.custom_ap_profile_show_debug_info) {
  // console.warn(warn);
  // }
  // }

  function showLog(state, log) {
    if (state) {
      console.log(log);
    }
  }
  function showWarn(state, warn) {
    if (state) {
      console.warn(warn);
    }
  }
  function isDefined(value) {
    return value !== null && value !== undefined;
  }
  function fieldInputTypes(fieldType) {
    return {
      isBoolean: fieldType === 'boolean',
      isString: fieldType === 'string',
      isStringArray: fieldType === 'string_array',
      isInteger: fieldType === 'integer',
      isJson: fieldType === 'json'
    };
  }
});