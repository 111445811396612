define("discourse/plugins/discourse-custom-ap-profile/discourse/connectors/user-profile-controls/discourse-ap-custom-profile", ["exports", "@ember/component", "discourse-common/utils/decorators", "I18n", "discourse/models/user", "discourse/plugins/discourse-custom-ap-profile/discourse/lib/discourse_custom_ap_profile"], function (_exports, _component, _decorators, _I18n, _user, _discourse_custom_ap_profile) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    setupComponent(args, component) {
      component.set("isCAPPAvailable", (0, _discourse_custom_ap_profile.isCAPPAvailable)(this.currentUser));
      if (component.isCAPPAvailable) {
        const model = args.model;
        const show_debug_info = model.siteSettings.custom_ap_profile_show_debug_info;
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'setupComponent from user-profile-controls\\discourse-ap-custom-profile.js.es6');
        const isStaff = this.currentUser && this.currentUser.staff;
        const isAdmin = this.currentUser && this.currentUser.admin;
        const isSelf = args.model && this.currentUser && args.model.id === this.currentUser.id;
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, args);
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, _component.default);
        component.set("canEditProfile", isStaff || isSelf);
        component.set("isSelfOrStaff", isSelf || isStaff);
      }
    }
  };
});