define("discourse/plugins/discourse-custom-ap-profile/discourse/discourse-custom-ap-profile-route-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default() {
    this.route("discourse-custom-ap-profile", {
      path: "/profiles"
    },
    //    { path: "/profiles", resetNamespace: true },
    function () {
      //      this.route("birthdays", { path: "/birthdays" }, function () {
      //        this.route("today", { path: "/today" });
      //        this.route("tomorrow", { path: "/tomorrow" });
      //        this.route("upcoming", { path: "/upcoming" });
      //        this.route("all", { path: "/all" });
      //      });

      //      this.route("anniversaries", { path: "/anniversaries" }, function () {
      //        this.route("today", { path: "/today" });
      //        this.route("tomorrow", { path: "/tomorrow" });
      //        this.route("upcoming", { path: "/upcoming" });
      //        this.route("all", { path: "/all" });
      //      });
    });
  }
});