define("discourse/plugins/discourse-custom-ap-profile/discourse/components/user-age-title-ageplay", ["exports", "@ember/component", "discourse-common/utils/decorators", "I18n"], function (_exports, _component, _decorators, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import User from "discourse/models/user";
  // import {
  //  showLog,
  //  showWarn,
  // } from "discourse/plugins/discourse-custom-ap-profile/discourse/lib/discourse_custom_ap_profile";
  var _default = _exports.default = _component.default.extend(dt7948.p({
    classNames: ["user-age-title"],
    titleText(title) {
      // const show_debug_info = this.siteSettings.custom_ap_profile_show_debug_info;
      // showLog(show_debug_info, 'this user-age-title-ageplay');
      // showLog(show_debug_info, this);
      return _I18n.default.t('js.user.date_of_birth.label') + ': ' + title;
    }

    // setupComponent(args, component) {
    //   //this is not being used/fired... probably because its a component
    //   const show_debug_info = this.siteSettings.custom_ap_profile_show_debug_info;

    //   console.warn('looki looki here');
    //   showLog(show_debug_info, 'setupComponent from controllers\\user-age-title.js.es6')

    //   showLog(show_debug_info, 'component from user-age-title.js.es6');
    //   showLog(show_debug_info, component);

    //   component.model.set('isProfile', component.connector.outletName === "user-location-and-website");
    // },
  }, [["method", "titleText", [(0, _decorators.default)("title")]]]));
});