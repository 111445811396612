define("discourse/plugins/discourse-custom-ap-profile/discourse/connectors/user-location-and-website/custom_ap_profile", ["exports", "@ember/component", "discourse-common/utils/decorators", "I18n", "discourse/models/user", "discourse/plugins/discourse-custom-ap-profile/discourse/lib/discourse_custom_ap_profile"], function (_exports, _component, _decorators, _I18n, _user, _discourse_custom_ap_profile) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    setupComponent(_ref, component) {
      let {
        model
      } = _ref;
      function createIconObjectModel(a, index, arr) {
        arr[index] = {
          name: a,
          i18n: 'custom_ap_profile.social.' + a,
          icon: 'fab-' + a,
          url: (0, _discourse_custom_ap_profile.getSocialUrl)(a, model[a])
        };
      }
      ;
      component.set("isCAPPAvailable", (0, _discourse_custom_ap_profile.isCAPPAvailable)(this.currentUser));
      if (_component.default.isCAPPAvailable) {
        console.log('setupComponent from user-location-and-website\\custom_ap_profile.js.es6');
        const isStaff = this.currentUser && this.currentUser.staff;
        const isAdmin = this.currentUser && this.currentUser.admin;
        const isSelf = model && this.currentUser && model.id === this.currentUser.id;

        // let enabledSocialNetworks = {};
        // const enabledSocialNetworks = 'asd|qwe'; //user.siteSettings.custom_ap_profile_social_networks.split('|');
        // console.log('component: (findme)');
        // console.log(component);
        // console.log('model: (findme)');
        // console.log(model);
        // console.log('component: (findme)');
        // console.log(component);
        // const enabledSocialNetworks = this.siteSettings.custom_ap_profile_social_networks.split('|');
        const enabledSocialNetworks = model.socialNetworks;

        // if (enabledSocialNetworks) {
        // console.log('enabledSocialNetworks (2a):');
        // console.log(enabledSocialNetworks);

        // enabledSocialNetworks.forEach(createIconObjectModel);
        // console.log('enabledSocialNetworks (2):');
        // console.log(enabledSocialNetworks);

        // console.log('User.custom_fields');
        // console.log(User.custom_fields);
        // }
        // component.set("canEditProfile", isStaff || isSelf);

        // console.log('component:');
        // console.log(component);

        // component.set("user.hasBirthdate", model.get("date_of_birth") != null);
        // component.set("user.preferencesProfilePath", "/u/" + model.get("username_lower") + "/preferences/profile/#cakeday");

        // if (isAdmin || isStaff || isSelf) {
        // component.set("userAgeTitle", userAgeTitle(model));
        // component.set("userBirthdateTitle", userBirthdateTitle(model));
        // }
        console.log('this:');
        console.log(this);
        console.log('component:');
        console.log(component);
        console.log('model:');
        console.log(model);
        //    component.set("enabledSocialNetworks", user.siteSettings.custom_ap_profile_social_networks.split('|'));
        // component.set("userGenderIcon", genderToIcon(model.get("gender")));
        // component.set("userGenderText", I18n.t('js.gender_' + model.get("gender")));
        // component.set("ageplayRolesList", model.get("ageplayRoles").join(', '));
        // component.set("sexualOrientation", I18n.t('js.sexual_orientation_' + model.get("sexualOrientation")));

        component.setProperties({
          enabledSocialNetworks
        });
        console.log('eof');
      }
    }
  };
});