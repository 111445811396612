define("discourse/plugins/discourse-custom-ap-profile/discourse/controllers/discourse-ap-profile", ["exports", "@ember/controller", "discourse-common/utils/decorators", "discourse/models/user"], function (_exports, _controller, _decorators, _user) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend(dt7948.p({
    isLoggedIn() {
      return _user.default.current();
    },
    init() {
      this._super();
      this.set('genders', []);
      this.fetchGenders();
      console.log('init');
    },
    setupComponent(args, component) {
      const genders = args.model.siteSettings.custom_ap_profile_genders.split("|").map(gender => {
        return {
          name: I18n.t("gender_" + gender).substr(0, 1) === "[" ? gender : I18n.t("gender_" + gender),
          value: gender
        };
      });
      let abdl_prefs = args.model.siteSettings.custom_ap_profile_abdl_prefs.split("|").map(abdl_pref => {
        return {
          name: I18n.t("abdl_pref_" + abdl_pref).substr(0, 1) === "[" ? abdl_pref : I18n.t("abdl_pref_" + abdl_pref),
          value: abdl_pref
        };
      });
      component.setProperties({
        genders,
        abdl_prefs
        //      sexual_orientations,
        //      looking_for_list,
        //      userABDLPrefs,
      });
    },
    fetchGenders() {
      console.log('gendertest');
      console.log(this);
      //    this.store.findAll('note')
      //      .then(result => {
      //        for (const note of result.content) {
      //          this.notes.pushObject(note);
      //        }
      //      })
      //      .catch(console.error);
      this.genders = this.siteSettings.ap_profile_genders.split("|").map(gender => {
        return {
          name: I18n.t("gender_" + gender).substr(0, 1) === "[" ? gender : I18n.t("gender_" + gender),
          value: gender
        };
      });
    }
  }, [["method", "isLoggedIn", [(0, _decorators.default)()]]]));
});