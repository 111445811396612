define("discourse/plugins/discourse-custom-ap-profile/discourse/connectors/user-custom-preferences/user-ap-profile-input", ["exports", "I18n", "discourse/models/category", "discourse/plugins/discourse-custom-ap-profile/discourse/lib/cakeday", "discourse/plugins/discourse-custom-ap-profile/discourse/lib/discourse_custom_ap_profile", "discourse/lib/ajax", "discourse-common/lib/get-owner", "@ember/template", "@ember/runloop", "@ember/object"], function (_exports, _I18n, _category, _cakeday, _discourse_custom_ap_profile, _ajax, _getOwner, _template, _runloop, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = dt7948.p({
    updateDiscord() {
      this.value = event.target.value;
    },
    updateFetlifeId() {
      this.value = event.target.value;
    },
    setupComponent(args, component) {
      component.set("isCAPPAvailable", (0, _discourse_custom_ap_profile.isCAPPAvailable)(this.currentUser));
      if (component.isCAPPAvailable) {
        const model = args.model;
        const show_debug_info = model.siteSettings.custom_ap_profile_show_debug_info;
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, "setupComponent from connectors/user-custom-preference/user-ap-custom-input.js");
        const fetchTopics = function (url) {
          return (0, _ajax.ajax)(url).then(content => {
            // Filter out hidden topics
            const visibleTopics = content.topic_list.topics.filter(topic => topic.visible);
            const topics = visibleTopics.map(topic => ({
              name: topic.title,
              value: topic.id
            }));
            if (content.topic_list.more_topics_url) {
              // If more topics are available, recursively fetch them after a timeout
              return new Promise(resolve => {
                setTimeout(() => {
                  fetchTopics(content.topic_list.more_topics_url).then(moreTopics => {
                    // Concatenate the current visible topics with the fetched additional visible topics
                    resolve({
                      topics: topics.concat(moreTopics.topics)
                    });
                  });
                }, 250); // 250 milliseconds timeout
              });
            } else {
              // No more topics, just return the current visible topics
              return {
                topics
              };
            }
          }).catch(error => {
            console.error('Error fetching topics:', error);
          });
        };
        const library = component.siteSettings.custom_ap_profile_diaper_library;
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'library:');
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, library);

        // Check if library is defined before constructing the URL
        if (typeof library !== 'undefined') {
          // Use the /none.json URL to prevent topics from subcategories
          const url = '/c/library/' + library + '/none.json';

          // Start fetching topics with the constructed URL
          fetchTopics(url).then(result => {
            const library_list = result.topics.map(item => ({
              name: item.name,
              value: item.value
            }));

            // Sort library_list by name (case-insensitive)
            library_list.sort(function (a, b) {
              var nameA = a.name.toLowerCase();
              var nameB = b.name.toLowerCase();
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
              return 0; // names are equal
            });

            // Set favorite_diapers_list using the sorted library_list
            component.set('favorite_diapers_list', library_list);
          });
        }
        const birthdate = model.birthdate;
        const isAdmin = this.currentUser.admin;
        const isModerator = this.currentUser.moderator;
        const isSelf = args.model && this.currentUser && args.model.id === this.currentUser.id;
        const isStaff = this.currentUser.staff;
        component.set('isStaff', isStaff);
        const alertMsg = function (msg) {
          const dialog = (0, _getOwner.getOwner)(this).lookup("service:dialog");
          dialog.alert({
            message: (0, _template.htmlSafe)(msg)
          });
        };
        const relationships_none = component.siteSettings.custom_ap_profile_relationships_none;
        const relationships_mono = component.siteSettings.custom_ap_profile_relationships_mono;
        const relationships_vary = component.siteSettings.custom_ap_profile_relationships_vary;
        const relationships_triad = component.siteSettings.custom_ap_profile_relationships_triad;
        const relationships_quad = component.siteSettings.custom_ap_profile_relationships_quad;
        const relationships_with = component.siteSettings.custom_ap_profile_relationships_with;
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, "args:");
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, args);
        var element = document.getElementById("edit-location");
        var parent = element.parentNode.parentNode;
        var link = document.createElement('a');
        link.name = 'edit-location-data';
        var parent2 = parent.parentNode;
        parent2.insertBefore(link, parent);
        if (isSelf) component.set("preferencesPath", "/my/preferences/");else component.set("preferencesPath", "/u/" + args.model.get("username_lower") + "/preferences/");
        if (isSelf) component.set("preferencesProfilePath", "/my/preferences/profile/");else component.set("preferencesProfilePath", "/u/" + args.model.get("username_lower") + "/preferences/profile/");

        //model.data = this.getProperties("path", "version", "branch");

        //let isPreferencesProfile = function () {
        //  return document.location.href.indexOf("preferences/profile") > 0
        //};

        //preferencesRoute

        // showLog(show_debug_info, document.location.href);
        // showLog(show_debug_info, document.location.href.indexOf("preferences/profile"));
        //model.set("isPreferencesProfile", isPreferencesProfile);    

        // const genders1 = moment.months().map((gender, index) => {
        // return { name: gender, value: index + 1 };
        // });

        // showLog(show_debug_info, this.siteSettings);
        // showLog(show_debug_info, model.siteSettings);
        // showLog(show_debug_info, model.siteSettings.approfile_genders);

        // const genders2 = model.siteSettings.custom_ap_profile_genders
        // .split("|");

        // showLog(show_debug_info, "f:")
        // showLog(show_debug_info, "f: " + I18n.isValidNode("gender_f"))
        // showLog(show_debug_info, I18n.isValidNode("gender_f"));
        // showLog(show_debug_info, I18n.isValidNode("gender_nb"));
        // showLog(show_debug_info, I18n.translations.en.js.gender_m);
        // showLog(show_debug_info, I18n.translations.en.js.gender_nb);
        // showLog(show_debug_info, I18n.translations.en.js.gender_f);

        const CAPPEnabledGroups = model.siteSettings.custom_ap_profile_enabled_groups;
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, this.currentUser.groups);
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'a1');
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, model.siteSettings.custom_ap_profile_enabled_groups.split('|'));
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'a2');
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, (model.siteSettings.custom_ap_profile_enabled_groups + '').split('|').filter(val => this.currentUser.groups.includes(val)));
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'a3');
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, this.currentUser.groups.filter(g => CAPPEnabledGroups.includes(g.id)));
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, this.currentUser.groups.filter(g => CAPPEnabledGroups.includes(g.id)).length);

        // const isCAPPAvailable = this.currentUser.groups.filter(g => CAPPEnabledGroups.includes(g.id)).length > 0;
        component.set("isCAPPAvailable", (0, _discourse_custom_ap_profile.isCAPPAvailable)(this.currentUser));
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'a4');
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'args.model.siteSettings:');
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, args.model.siteSettings);
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'model.siteSettings.custom_ap_profile_social_networks');
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, model.siteSettings.custom_ap_profile_social_networks);
        const socialEnabled = model.siteSettings.custom_ap_profile_social_networks !== '';
        const ageControlVisibility = model.siteSettings.cakeday_min_age_controlvisibility;
        let canControlVisibility = ageControlVisibility && (0, _cakeday.userAge)(birthdate) >= ageControlVisibility || isStaff;
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'birthdate from ap-profile');
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, birthdate);
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, (0, _cakeday.userAge)(birthdate));
        const country_districts = args.model.siteSettings.custom_ap_profile_country_districts.split("|").map(district => {
          return {
            name: district,
            value: district
          };
        });
        let genders = args.model.siteSettings.custom_ap_profile_genders.split("|").map(gender => {
          return {
            name: _I18n.default.t("custom_ap_profile.gender_" + gender).substr(0, 1) === "[" ? gender : _I18n.default.t("custom_ap_profile.gender_" + gender),
            value: gender
          };
        });
        genders = genders.sort((a, b) => a.name > b.name ? 1 : a.name < b.name ? -1 : 0);
        let handicaps = args.model.siteSettings.custom_ap_profile_handicaps.split("|").map(hc => {
          return {
            name: _I18n.default.t("custom_ap_profile.handicap_" + hc),
            value: hc
          };
        });
        handicaps = handicaps.sort((a, b) => a.name > b.name ? 1 : a.name < b.name ? -1 : 0);
        handicaps.push({
          name: _I18n.default.t("custom_ap_profile.handicap_other"),
          value: 'other'
        });
        const choose_handicap = "custom_ap_profile.choose_your_handicap";

        // let isGenderFemale = args.model.get('custom_fields.gender') === 'f';
        // let isGenderMale = args.model.get('custom_fields.gender') === 'm';
        // let isGenderOther = (isGenderFemale === false && isGenderMale === false);

        let ageplay_roles_array = args.model.siteSettings.custom_ap_profile_ageplay_roles.split("|");
        let ageplay_roles_map = args.model.siteSettings.custom_ap_profile_ageplay_roles.split("|").map(ageplay_role => {
          return {
            name: _I18n.default.t("custom_ap_profile.ageplay_role_" + ageplay_role).substr(0, 1) === "[" ? _I18n.default.t("custom_ap_profile.ageplay_role_" + ageplay_role + '2').substr(0, 1) === "[" ? ageplay_role : _I18n.default.t("custom_ap_profile.ageplay_role_" + ageplay_role + '2') : _I18n.default.t("custom_ap_profile.ageplay_role_" + ageplay_role),
            value: ageplay_role
          };
        });
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, '*** ageplay_roles ***');
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, _I18n.default.t("js.custom_ap_profile.ageplay_role_MF"));
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, _I18n.default.t("custom_ap_profile.ageplay_role_MF"));
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, _I18n.default.t("custom_ap_profile.ageplay_role_MF2"));
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, _I18n.default.t("custom_ap_profile.ageplay_role_MG"));
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, ageplay_roles_map);
        ageplay_roles_map = ageplay_roles_map.sort((a, b) => a.name > b.name ? 1 : a.name < b.name ? -1 : 0);
        (0, _discourse_custom_ap_profile.showWarn)(show_debug_info, ageplay_roles_map);
        let ageplay_f_roles = component.siteSettings.custom_ap_profile_ageplay_f_roles;
        let ageplay_m_roles = component.siteSettings.custom_ap_profile_ageplay_m_roles;
        let aRoles_f = ageplay_f_roles.split('|');
        let aRoles_m = ageplay_m_roles.split('|');

        // let ageplay_f_roles_array = component.siteSettings.custom_ap_profile_ageplay_f_roles.split("|");
        let ageplay_f_roles_array = ageplay_roles_array.filter(val => !aRoles_m.includes(val));
        let ageplay_m_roles_array = ageplay_roles_array.filter(val => !aRoles_f.includes(val));
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'ageplay_f_roles_array:');
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, ageplay_f_roles_array);

        // let ageplay_f_roles1 = ageplay_roles_array.filter(val => !ageplay_f_roles_array.includes(val)).map((ageplay_role) => {
        // return { name: I18n.t("custom_ap_profile.ageplay_role_" + ageplay_role).substr(0,1) === "[" ? (I18n.t("custom_ap_profile.ageplay_role_" + ageplay_role + '2').substr(0,1) === "[" ? ageplay_role : I18n.t("custom_ap_profile.ageplay_role_" + ageplay_role + '2')) : I18n.t("custom_ap_profile.ageplay_role_" + ageplay_role), value: ageplay_role };
        // });

        // showLog(show_debug_info, 'ageplay_f_roles1:');
        // showLog(show_debug_info, ageplay_f_roles1);

        // let ageplay_f_roles = args.model.siteSettings.custom_ap_profile_ageplay_f_roles

        // let ageplay_f_roles = ageplay_roles.filter(val => component.siteSettings.custom_ap_profile_ageplay_f_roles.includes(val))
        // let ageplay_f_roles = args.model.siteSettings.custom_ap_profile_ageplay_f_roles
        // .split("|").map((ageplay_role) => {
        // return { name: I18n.t("custom_ap_profile.ageplay_role_" + ageplay_role).substr(0,1) === "[" ? ageplay_role: I18n.t("custom_ap_profile.ageplay_role_" + ageplay_role), value: ageplay_role };
        // });

        // let ageplay_f_roles_map = args.model.siteSettings.custom_ap_profile_ageplay_f_roles
        let ageplay_f_roles_map = ageplay_f_roles_array.map(ageplay_role => {
          return {
            name: _I18n.default.t("custom_ap_profile.ageplay_role_" + ageplay_role).substr(0, 1) === "[" ? _I18n.default.t("custom_ap_profile.ageplay_role_" + ageplay_role + '2').substr(0, 1) === "[" ? ageplay_role : _I18n.default.t("custom_ap_profile.ageplay_role_" + ageplay_role + '2') : _I18n.default.t("custom_ap_profile.ageplay_role_" + ageplay_role),
            value: ageplay_role
          };
        });

        // let ageplay_m_roles_map = args.model.siteSettings.custom_ap_profile_ageplay_m_roles
        let ageplay_m_roles_map = ageplay_m_roles_array.map(ageplay_role => {
          return {
            name: _I18n.default.t("custom_ap_profile.ageplay_role_" + ageplay_role).substr(0, 1) === "[" ? _I18n.default.t("custom_ap_profile.ageplay_role_" + ageplay_role + '2').substr(0, 1) === "[" ? ageplay_role : _I18n.default.t("custom_ap_profile.ageplay_role_" + ageplay_role + '2') : _I18n.default.t("custom_ap_profile.ageplay_role_" + ageplay_role),
            value: ageplay_role
          };
        });
        const abct_roles = args.model.siteSettings.custom_ap_profile_abct_roles.split("|").map(abct_pref => {
          return {
            name: _I18n.default.t("custom_ap_profile.ageplay_role_" + abct_pref).substr(0, 1) === "[" ? ageplay_role : _I18n.default.t("custom_ap_profile.ageplay_role_" + abct_pref),
            value: abct_pref
          };
        });
        const abtb_roles = args.model.siteSettings.custom_ap_profile_abtb_roles.split("|");
        const blmb_roles = args.model.siteSettings.custom_ap_profile_blmb_roles.split("|");

        //  ageplay_roles.map.delete("DD");
        //    ageplay_roles.filter(val => model.siteSettings.custom_ap_profile_ageplay_f_roles.includes(val));

        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, ageplay_roles_map);
        let diaper_change_list = args.model.siteSettings.custom_ap_profile_diaper_change.split("|").map(diaper_change => {
          return {
            name: _I18n.default.t("custom_ap_profile.diaper_change_" + diaper_change).substr(0, 1) === "[" ? diaper_change : _I18n.default.t("custom_ap_profile.diaper_change_" + diaper_change),
            value: diaper_change
          };
        });
        let diaper_usage_list = args.model.siteSettings.custom_ap_profile_diaper_usage.split("|").map(diaper_usage => {
          return {
            name: _I18n.default.t("custom_ap_profile.diaper_usage_" + diaper_usage).substr(0, 1) === "[" ? diaper_usage : _I18n.default.t("custom_ap_profile.diaper_usage_" + diaper_usage),
            value: diaper_usage
          };
        });
        diaper_change_list = diaper_change_list.sort((a, b) => a.name > b.name ? 1 : a.name < b.name ? -1 : 0);
        diaper_usage_list = diaper_usage_list.sort((a, b) => a.name > b.name ? 1 : a.name < b.name ? -1 : 0);
        let interests_list_array = args.model.siteSettings.custom_ap_profile_interests.split("|");
        let interests_list_arrayrev = [];
        for (const interest in interests_list_array) {
          interests_list_arrayrev[interest] = _I18n.default.t("custom_ap_profile.interests_" + interests_list_array[interest]).substr(0, 1) === "[" ? interests_list_array[interest] : _I18n.default.t("custom_ap_profile.interests_" + interests_list_array[interest]);
        }
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'interests_list_arrayrev:');
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, interests_list_arrayrev);
        const interests_map = ['a', 'b'];
        let interests_list = args.model.siteSettings.custom_ap_profile_interests.split("|").map(interest => {
          return {
            name: _I18n.default.t("custom_ap_profile.interests_" + interest).substr(0, 1) === "[" ? interest : _I18n.default.t("custom_ap_profile.interests_" + interest),
            value: interest
          };
        });
        interests_list = interests_list.sort((a, b) => a.name > b.name ? 1 : a.name < b.name ? -1 : 0);
        let looking_for_list = args.model.siteSettings.custom_ap_profile_looking_for.split("|").map(looking_for => {
          return {
            name: _I18n.default.t("custom_ap_profile.looking_for_" + looking_for).substr(0, 1) === "[" ? looking_for : _I18n.default.t("custom_ap_profile.looking_for_" + looking_for),
            value: looking_for
          };
        });
        looking_for_list = looking_for_list.sort((a, b) => a.name > b.name ? 1 : a.name < b.name ? -1 : 0);
        let relationships = args.model.siteSettings.custom_ap_profile_relationships.split("|").map(relationship => {
          return {
            name: _I18n.default.t("custom_ap_profile.relationship_" + relationship).substr(0, 1) === "[" ? relationship : _I18n.default.t("custom_ap_profile.relationship_" + relationship),
            value: relationship
          };
        });
        relationships = relationships.sort((a, b) => a.name > b.name ? 1 : a.name < b.name ? -1 : 0);
        let sexual_orientations = args.model.siteSettings.custom_ap_profile_sexual_orientation.split("|").map(sexual_orientation => {
          return {
            name: _I18n.default.t("custom_ap_profile.sexual_orientation_" + sexual_orientation).substr(0, 1) === "[" ? sexual_orientation : _I18n.default.t("custom_ap_profile.sexual_orientation_" + sexual_orientation),
            value: sexual_orientation
          };
        });
        sexual_orientations = sexual_orientations.sort((a, b) => a.name > b.name ? 1 : a.name < b.name ? -1 : 0);

        // const ageControlVisibility = args.model.siteSettings.cakeday_min_age_controlvisibility;
        // let canControlBirthdayVisibility = ageControlVisibility && userAge(dateOfBirth) >= ageControlVisibility;

        component.set("userGenderIcon", (0, _discourse_custom_ap_profile.genderToIcon)(args.model.get("custom_fields.gender")));
        component.set("userGenderText", _I18n.default.t('custom_ap_profile.gender_' + args.model.get("custom_fields.gender")));
        //component.set("userGenderText", model.get("custom_fields.gender") !== null ? I18n.t('js.gender_' + model.get("custom_fields.gender")) : I18n.t('js.custom_ap_profile.not_defined'));

        //    let gender = model.get("custom_fields.gender");
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'args.model:');
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, args.model);
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'gender:');
        //    showLog(show_debug_info, gender);
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, args.model.get("custom_fields.gender"));

        // this.category.custom_fields.category_expert_group_ids
        // ? this.category.custom_fields.category_expert_group_ids.split("|")
        // : []

        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'flubber:');
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'args.model.custom_fields.ageplayRoles:');
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, args.model.custom_fields.ageplayRoles);
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'component.siteSettings.custom_ap_profile_abtb_roles:');
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, component.siteSettings.custom_ap_profile_abtb_roles);

        // showLog(show_debug_info, args.model.custom_fields.ageplayRoles.split('|').filter(val => component.siteSettings.custom_ap_profile_abtb_roles.includes(val)).length);

        const isABTB = args.model.custom_fields.ageplayRoles ? (args.model.custom_fields.ageplayRoles + '').split('|').filter(val => val !== "" && component.siteSettings.custom_ap_profile_abtb_roles.includes(val)).length > 0 : false;
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'flubber1:');

        //const isABCT = component.ABCTPrefs.filter(val => component.siteSettings.custom_ap_profile_abct_roles.includes(val)).length > 0;
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info);
        const isABCT = args.model.custom_fields.ageplayRoles ? (args.model.custom_fields.ageplayRoles + '').split('|').filter(val => val !== "" && component.siteSettings.custom_ap_profile_abct_roles.includes(val)).length > 0 : false;
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'flubber2:');
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'isABCT: ' + isABCT);
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'isABTB: ' + isABTB);

        // args.model.set("isABTB", isABTB);
        // args.model.set("isABCT", isABCT);
        component.set("isABTB", isABTB);
        component.set("isABCT", isABCT);
        //args.model.set("ageplayRoles", userABDLPref

        let ABCTPrefs = args.model.get("abct_roles");
        let play_age = args.model.get("play_age");
        let care_age = args.model.get("care_age");
        let waist = args.model.get("waist");
        let ageplayRoles = args.model.get("custom_fields.ageplayRoles"); // ? args.model.get("ageplayRoles").split("|") : [];
        let countryDistrict = args.model.get("custom_fields.countryDistrict");
        let handicap = args.model.get("custom_fields.handicap");
        let relationship = args.model.get("custom_fields.relationship");
        let sexualOrientation = args.model.get("custom_fields.sexualOrientation");
        let lookingFor = args.model.get("custom_fields.lookingFor");
        let name = args.model.get("name");
        let socialNetworks = args.model.get("custom_fields.socialNetworks");
        let hasABCTPrefs = ABCTPrefs !== null;
        let hasCareAge = care_age !== "";
        let hascare_age = care_age !== null;
        let hasWaist = waist !== null;
        //let hasplay_age = play_age !== null;
        //let hasPlayAge = play_age !== "";
        //let hasHandicap = args.model.custom_fields.handicap !== undefined && args.model.custom_fields.handicap !== null;
        //let hasGender = typeof gender !== 'undefined' && gender !== "" && gender.length > 0 && gender[0].length > 0;
        let hasHandicap = typeof handicap !== 'undefined' && handicap !== "" && handicap.length > 0;
        let hasHandicapDyslexia = hasHandicap ? args.model.custom_fields.handicap.indexOf("dyslexia") != -1 : false;
        let hasLookingFor = typeof lookingFor !== 'undefined' && lookingFor !== null && lookingFor.length > 0;
        let hasName = name !== null;
        let hasRelationship = relationship !== "";
        let hasSexualOrientation = typeof sexualOrientation !== 'undefined' && sexualOrientation !== null && sexualOrientation !== "";
        let hasSocialNetworks = socialNetworks !== null;
        (0, _discourse_custom_ap_profile.showWarn)(show_debug_info, 'data array:');
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, handicap);
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, typeof handicap !== 'undefined');
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, handicap !== "");
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'before handicap.length');
        if (typeof handicap !== 'undefined') {
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, handicap.length > 0);
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, handicap[0].length > 0);
        }
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, {
          'name': name,
          'ABCTPrefs': typeof ABCTPrefs !== 'undefined' ? ABCTPrefs : null,
          'care_age': care_age ? care_age : null,
          'userABDLPref': typeof userABDLPref !== 'undefined' ? userABDLPref : null,
          'play_age': play_age ? play_age : null,
          'lookingFor': lookingFor ? lookingFor : null,
          'relationship': relationship ? relationship : null,
          'sexualOrientation': sexualOrientation ? sexualOrientation : null,
          'socialNetworks': socialNetworks ? socialNetworks : null,
          'handicap': handicap ? handicap : null,
          'hasABCTPrefs': hasABCTPrefs,
          'hasHandicap': hasHandicap,
          'hasHandicapDyslexia': hasHandicapDyslexia,
          'hasLookingFor': hasLookingFor,
          'hasName': hasName,
          'hasRelationship': hasRelationship,
          'hasSexualOrientation': hasSexualOrientation,
          'hasSocialNetworks': hasSocialNetworks
        });
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'sexualOrientation:');
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, sexualOrientation);
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'hasSexualOrientation:');
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, hasSexualOrientation);
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'flubber3:');
        let custom_fields = args.model.get("custom_fields");
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'flubber3b:');
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, args.model.custom_fields.handicap);

        // const isABTB = args.model.custom_fields.ageplayRoles ? args.model.custom_fields.ageplayRoles.filter(val => component.siteSettings.custom_ap_profile_abtb_roles.includes(val)).length > 0 : false;

        // args.model.set("canEditName", true);

        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'flubber3c:');
        args.model.set("hasHandicap", hasHandicap);
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'flubber3d:');
        args.model.set("hasHandicapDyslexia", hasHandicapDyslexia);
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'flubber4:');
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'args.model.siteSettings.custom_ap_profile_social_networks:');
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, args.model.siteSettings.custom_ap_profile_social_networks);

        //    var social_networks = []; //args.model.siteSettings.custom_ap_profile_social_networks.split('|');

        // args.model.set("hasPlayAge", hasPlayAge);
        // args.model.set("hasCareAge", hasCareAge);

        args.model.set("findThis", 'please');
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'ageplayRoles:');
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, ageplayRoles);

        // this.siteSettings.custom_ap_profile_social_networks.split('|').forEach(function(social) {
        // showLog(show_debug_info, 'Social option: ' + social);
        // social_networks[social] = { name: I18n.t("social." + social), value: social };

        // // args.model.set("custom_fields." + social, args.model.custom_fields[social]);
        // // // args.model.set(social, args.model.custom_fields.split('|').includes(social) ? args.model.custom_fields[social] : null);
        // });

        // args.model.set("social_networks", social_networks );

        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'args.model.siteSettings.custom_ap_profile_social_networks:');
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, args.model.siteSettings.custom_ap_profile_social_networks);
        //need to add a check for no social networks enabled...
        let social_networks_list = args.model.siteSettings.custom_ap_profile_social_networks.split("|").map(social => {
          return {
            name: _I18n.default.t("custom_ap_profile.social." + social),
            value: social
          };
        });
        social_networks_list.forEach(social => {
          component.set("hasSocial" + social.value.charAt(0).toUpperCase() + social.value.slice(1) + "Name", args.model.get("custom_fields." + social.value) !== undefined);
          // showLog(show_debug_info, 'toast');
          // showLog(show_debug_info, args.model.get("custom_fields." + social.value) !== undefined);
        });
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'social_networks_list array:');
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, args.model.get("social_networks_list"));
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'social_networks_list array:');
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, social_networks_list);

        //args.model.set("user_ageplay_roles", ageplayRoles);

        //    const isAdmin = this.currentUser.admin;
        //    const isModerator = this.currentUser.moderator;
        //    const isStaff = this.currentUser.staff;
        //    const showYear = args.model.siteSettings.cakeday_birthday_show_year;
        //
        //    let hasBirthdate = false;
        //    if (showYear)
        //      hasBirthdate = dateOfBirth !== null && userBirthdayYear !== null;
        //    else
        //      hasBirthdate = dateOfBirth !== null;
        //    args.model.set("hasBirthdate", hasBirthdate);
        //    
        //    let hasAge = userBirthdayYear !== null;
        //    args.model.set("hasAge", hasAge);

        //
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'flubber5:');
        const scrollToMe = function () {
          alert('found it');
        };
        const isPreferencesProfile3 = true;
        component.setProperties({
          // ageplay_roles,
          // ageplay_f_roles,
          // ageplay_m_roles,
          canControlVisibility,
          country_districts,
          diaper_change_list,
          diaper_usage_list,
          genders,
          handicaps,
          interests_map,
          interests_list,
          interests_list_array,
          interests_list_arrayrev,
          // isGenderFemale,
          // isGenderMale,
          // isGenderOther,
          isPreferencesProfile3,
          looking_for_list,
          relationships,
          sexual_orientations,
          socialEnabled,
          countryDistrict,
          // gender,
          choose_handicap,
          ageplayRoles,
          ABCTPrefs,
          // hasPlayAge,
          // hasCareAge,
          hasABCTPrefs,
          // hasplay_age,
          // hascare_age,
          hasHandicap,
          hasLookingFor,
          hasName,
          hasRelationship,
          hasSexualOrientation,
          hasSocialNetworks,
          // play_age,
          // care_age,
          relationship,
          sexualOrientation,
          lookingFor,
          socialNetworks,
          social_networks_list,
          custom_fields,
          //      allowUserChangeBirthdate,
          //      hasBirthdate,
          //      saved,
          scrollToMe
        });
        //
        //
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'flubber6:');
        //if (typeof userLocation === 'undefined') {
        //  args.model.set("location", '');
        //}
        if (typeof country_District !== 'undefined') {
          args.model.set("custom_fields.countryDistrict", '');
        }

        //const category = this.get('category');

        // let filt = `c/${Category.slugFor('12')}/l/`;

        // showLog(show_debug_info, 'filt: ' + filt);

        // const library = component.siteSettings.custom_ap_profile_diaper_library;
        // showLog(show_debug_info, 'library:');
        // showLog(show_debug_info, library);
        // if (typeof library !== 'undefined') {

        //   showLog(show_debug_info, 'qa6');
        //   let filter = 'c/library/' + library;

        //   showLog(show_debug_info, 'qa7');
        //   model.store.findFiltered('topicList', { filter, params: {}}).then(list => {
        //   // this.setProperties({
        //     // topics: list.topics,
        //     // currentMonth: month,
        //     // currentYear: year,
        //     // loading: false
        //   // });
        //     showLog(show_debug_info, list);

        //     let library_list = [];

        //     list.topics.slice(1).forEach(function(item) {
        //       showLog(show_debug_info, 'LI: ' + item.title);
        //       showLog(show_debug_info, item);
        //       // library_list[library_list.count] = { name: item.title, value: item.title };
        //       //value skal måske være item.id, men så skal vi enten gemme 2 værdier, eller lave noget opslag..
        //       // library_list bør måske være et globalt array, så der ikke skal laves genopslag for at gemme de 2 arrays værdier id + title
        //       library_list.push({ name: item.title, value: item.id });
        //     });
        //     library_list.sort(function (a, b) {
        //       var nameA = a.name.toLowerCase(); // Convert names to lowercase for case-insensitive sorting
        //       var nameB = b.name.toLowerCase();
        //       if (nameA < nameB) {
        //         return -1;
        //       }
        //       if (nameA > nameB) {
        //         return 1;
        //       }
        //       return 0; // names are equal
        //     });
        //     component.set('favorite_diapers_list', library_list);

        //     showLog(show_debug_info, library_list);

        //   });
        // }

        const updateLocation = function () {
          (0, _discourse_custom_ap_profile.showWarn)(show_debug_info, 'updateLocation');
          let userLocation = args.model.get("location");
          //let userLocation = component.get("location");
          let country_District = args.model.get("custom_fields.countryDistrict"); //component.custom_fields.countryDistrict;
          // let country_District = component.custom_fields.countryDistrict;
          let hasLocation = typeof userLocation !== 'undefined' && userLocation !== null && userLocation !== "";
          let hasCountry_District = typeof country_District !== 'undefined' && country_District !== null && country_District !== "";

          //if (typeof userLocation === 'undefined') {
          //  args.model.set("location", '');
          //}
          // if (typeof country_District !== 'undefined') {
          // args.model.set("custom_fields.countryDistrict", '');
          // }

          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, userLocation);
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, countryDistrict);
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'hasLocation: ' + hasLocation);
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'hasCountry_District: ' + hasCountry_District);
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'qwe1');
          if (!hasCountry_District)
            // && hasLocation)
            {
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'qwe1a');
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, country_districts);
              hasLocation = country_districts.some(x => x.value === userLocation);
              // country_districts.every(function(cd) {
              // hasLocation = country_districts.includes(userLocation);
              // showLog(show_debug_info, 'each hasLocation: ' + hasLocation);
              // return !hasLocation;
              // });

              if (hasLocation) {
                // showLog(show_debug_info, 'should clear');
                (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'userLocation');
                (0, _discourse_custom_ap_profile.showLog)(show_debug_info, userLocation);
                // args.model.set("location", '');  //denne
                userLocation = '';
                hasLocation = false;
              } else {
                args.model.set("custom_fields.countryDistrict", userLocation);
                hasCountry_District = true;
              }
            }
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'qwe2');
          // showLog(show_debug_info, 'component:')
          // showLog(show_debug_info, component);
          // showLog(show_debug_info, 'component.args:')
          // showLog(show_debug_info, component.args);
          // showLog(show_debug_info, 'component.args.model:')
          // showLog(show_debug_info, component.args.model);
          // showLog(show_debug_info, 'component.args.model.custom_fields.countryDistrict:');
          // showLog(show_debug_info, component.args.model.custom_fields.countryDistrict);
          // showLog(show_debug_info, 'args.model.custom_fields.countryDistrict:');
          // showLog(show_debug_info, args.model.custom_fields.countryDistrict);
          // showLog(show_debug_info, 'countryDistrict:');
          // showLog(show_debug_info, countryDistrict);
          // showLog(show_debug_info, 'get-location:');
          // showLog(show_debug_info, args.model);
          // showLog(show_debug_info, args.model.get("location"));

          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'qwe3');
          //if (!component.args.location || component.args.location != countryDistrict)
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, hasLocation);
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, hasCountry_District);
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, userLocation);
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, countryDistrict);
          if (!hasLocation || userLocation !== countryDistrict && typeof countryDistrict !== 'undefined') {
            //showLog(show_debug_info, "something is true");
            (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 't1');
            component.args.location = countryDistrict;
            (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 't2');
            args.model.set("custom_fields.countryDistrict", args.model.custom_fields.countryDistrict);
            (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 't3');
            args.model.set("location", args.model.custom_fields.countryDistrict);
            (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 't4');
          }
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'qwe4');
          component.set("hasCountry_District", hasCountry_District);
          component.set("hasLocation", hasLocation);
          //if location is empty or the same as defined previously
          //set location to the same new value
          if (country_District === 'Andet') alertMsg(_I18n.default.t('location_other_use_clarify'));
          if (hasCountry_District) {
            let ecd = document.getElementsByClassName("edit-country_district");
            (0, _discourse_custom_ap_profile.showLog)(show_debug_info, ecd);
            if (typeof ecd !== 'undefined' && ecd.length > 0) {
              ecd[0].childNodes[5].childNodes[1].childNodes[1].childNodes[0].childNodes[4].title = _I18n.default.t("custom_ap_profile.instructions_country_district");
            }
            // showLog(show_debug_info, ecd[0].childNodes[5].childNodes[1].childNodes[1].childNodes[0]);
            // showLog(show_debug_info, ecd[0].childNodes[5].childNodes[1].childNodes[1].childNodes[0].childNodes[0]);
            // showLog(show_debug_info, ecd[0].childNodes[5].childNodes[1].childNodes[1].childNodes[0].childNodes[1]);
            // showLog(show_debug_info, ecd[0].childNodes[5].childNodes[1].childNodes[1].childNodes[0].childNodes[2]);
            // showLog(show_debug_info, ecd[0].childNodes[5].childNodes[1].childNodes[1].childNodes[0].childNodes[3]);
            // showLog(show_debug_info, ecd[0].childNodes[5].childNodes[1].childNodes[1].childNodes[0].childNodes[4]);
            // showLog(show_debug_info, ecd[0].childNodes[5].childNodes[1].childNodes[1].childNodes[0].childNodes[1].title);
          }
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'updateLocation complete');
        };

        /*
        
        hvis location bliver slettet, skal country_District også slettes
        hvis location matcher én af værdierne i country_District listen, skal country_District sættes til samme værdi som location, ellers skal country_District sættes til "Andet"
        
        hvis countryDistrict slettes, skal location også slettes
        når en værdi vælges i country district skal værdien med undtagelse af Andet sættes i location også...
        
        hvis discourse-calendar plugin er installeret, skal countryDistrict ikke vises
        
        alle componenter skal påtrykkes en class for bedre dom manipulering..
        
        */
        const checkCountryDistrict = function () {
          if (!component.get('locationUpdating')) {
            component.set('locationUpdating', true);
            (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'checkCountryDistrict start');
            let userLocation = args.model.get("location");
            let countryDistrict = args.model.get("custom_fields.countryDistrict"); //component.custom_fields.countryDistrict;
            let hasLocation = typeof userLocation !== 'undefined' && userLocation !== null && userLocation !== "";
            let hasCountryDistrict = typeof countryDistrict !== 'undefined' && countryDistrict !== null && countryDistrict !== "";
            if (hasCountryDistrict) {
              if (userLocation !== countryDistrict) {
                args.model.set("location", countryDistrict);
              }
              if (countryDistrict !== 'Andet') {
                model.set("custom_fields.holidays-region", 'dk');
              }
              let cds = document.getElementsByClassName("country_district-select");
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, cds);
              if (typeof cds !== 'undefined' && cds.length > 0) {
                cds[0].childNodes[1].childNodes[0].childNodes[4].title = _I18n.default.t("custom_ap_profile.instructions_country_district");
              }
            } else {
              args.model.set("location", '');
              //no control added in hbs template
              //model.set('custom_fields.showOnUserCardCountryDistrict', true);
            }
            component.set("hasCountry_District", hasCountryDistrict);
            component.set("hasLocation", hasLocation);
            (0, _runloop.later)(function () {
              component.set('locationUpdating', false);
            }, 100);
            (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'checkCountryDistrict complete');
          }
        };
        const checkLocation = function () {
          if (!component.get('locationUpdating')) {
            component.set('locationUpdating', true);
            (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'checkLocation start');
            let userLocation = args.model.get("location");
            let countryDistrict = args.model.get("custom_fields.countryDistrict"); //component.custom_fields.countryDistrict;
            let hasLocation = typeof userLocation !== 'undefined' && userLocation !== null && userLocation !== "";
            let hasCountryDistrict = typeof countryDistrict !== 'undefined' && countryDistrict !== null && countryDistrict !== "";
            if (hasLocation) {
              hasCountryDistrict = country_districts.some(x => x.value === userLocation);
              if (hasCountryDistrict) {
                args.model.set("custom_fields.countryDistrict", userLocation);
                args.model.set('custom_fields.holidays-region', 'dk');
              } else if (component.get('isDKRegion')) {
                args.model.set("custom_fields.countryDistrict", 'Andet');
              }
            } else {
              args.model.set("custom_fields.countryDistrict", '');
              hasCountryDistrict = false;
            }
            component.set("hasCountry_District", hasCountryDistrict);
            component.set("hasLocation", hasLocation);
            (0, _runloop.later)(function () {
              component.set('locationUpdating', false);
            }, 100);
            (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'checkLocation complete');
          }
        };
        const checkHandicap = function () {
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'checkHandicap_component:');
          //showLog(show_debug_info, component);

          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, args.model.custom_fields.handicap);
          if (args.model.get('custom_fields.handicap').length === 0) args.model.set('custom_fields.handicap', null);

          //let hasHandicapDyslexia = args.model.custom_fields.handicap ? args.model.custom_fields.handicap.filter('dyslexia').length > 0 : false;

          const hasHandicap = args.model.custom_fields.handicap !== null;
          const hasHandicapDyslexia = hasHandicap ? args.model.custom_fields.handicap.indexOf("dyslexia") != -1 : false;

          //if (hasHandicapDyslexia)
          args.model.set("hasHandicap", hasHandicap);
          args.model.set("hasHandicapDyslexia", hasHandicapDyslexia);
          component.set("hasHandicap", hasHandicap);
          component.set("hasHandicapDyslexia", hasHandicapDyslexia);
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, hasHandicapDyslexia);
          if (hasHandicap) {
            let eh = document.getElementsByClassName("edit-handicap");
            if (typeof eh !== 'undefined' && eh.length > 0) {
              eh[0].childNodes[5].childNodes[1].childNodes[1].childNodes[0].title = _I18n.default.t("custom_ap_profile.instructions_handicap");
            }
          } else {
            model.set('custom_fields.showOnUserCardHandicap', true);
          }
        };
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'flubber7:');
        const checkGenderPrefs = function () {
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'checkGenderPrefs_component:');
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, component);
          let gender = args.model.custom_fields.gender;
          let gender_old = component.custom_fields.gender;
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'gender: ' + gender);
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'gender_old: ' + gender_old);
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, component.get('ageplayRoles'));
          let hasGender = false;
          if (gender !== gender_old || component.get('ageplayRoles') !== []) {
            hasGender = typeof gender !== 'undefined' && gender !== null && gender !== "" && gender.length > 0 && gender[0].length > 0;
            (0, _discourse_custom_ap_profile.showLog)(show_debug_info, hasGender);
            let ageplayRoles = args.model.custom_fields.ageplayRoles;
            (0, _discourse_custom_ap_profile.showLog)(show_debug_info, ageplayRoles);
            const isSissy = typeof ageplayRoles !== 'undefined' && ageplayRoles !== null && ageplayRoles.includes('sissy');
            (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'isSissy: ' + isSissy);
            const isGenderFemale = hasGender && gender === 'f';
            const isGenderMale = hasGender && gender === 'm';
            const isGenderOther = isGenderFemale === false && isGenderMale === false;
            component.setProperties({
              hasGender,
              isSissy,
              isGenderFemale,
              isGenderMale,
              isGenderOther
            });
            /*
            //showLog(show_debug_info, component.ageplayRoles);
            //showLog(show_debug_info, ageplayRoles);
            
            // showLog(show_debug_info, 'component.siteSettings.custom_ap_profile_ageplay_roles:');
            // showLog(show_debug_info, component.siteSettings.custom_ap_profile_ageplay_roles);
             // component.ageplay_roles = component.ageplay_roles.filter(val => component.siteSettings.custom_ap_profile_ageplay_f_roles.includes(val))
            */
            (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'setting new ageplay roles');
            if (isSissy || isGenderOther) {
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'ageplay_roles_map:');
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, ageplay_roles_map);
              component.set('ageplay_roles', ageplay_roles_map);
            } else if (isGenderFemale) {
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'ageplay_f_roles_map:');
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, ageplay_f_roles_map);
              component.set('ageplay_roles', ageplay_f_roles_map);
            } else if (isGenderMale) {
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'ageplay_m_roles_map:');
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, ageplay_m_roles_map);
              component.set('ageplay_roles', ageplay_m_roles_map);
            }
            /*
            // component.ageplay_roles = component.siteSettings.custom_ap_profile_ageplay_roles.split("|").map((abct_pref) => {
            // return { name: I18n.t("custom_ap_profile.ageplay_role_" + abct_pref).substr(0,1) === "[" ? ageplay_role: I18n.t("custom_ap_profile.ageplay_role_" + abct_pref), value: abct_pref }});
             // showLog(show_debug_info, 'component.ageplay_roles:');
            // showLog(show_debug_info, component.ageplay_roles);
            
            // component.ageplay_roles = component.siteSettings.custom_ap_profile_ageplay_roles.split("|").map((abct_pref) => {
            // return { name: I18n.t("custom_ap_profile.ageplay_role_" + abct_pref).substr(0,1) === "[" ? ageplay_role: I18n.t("custom_ap_profile.ageplay_role_" + abct_pref), value: abct_pref }}).filter(val => component.siteSettings.custom_ap_profile_ageplay_f_roles.includes(val))
             // component.ageplay_roles = component.siteSettings.custom_ap_profile_ageplay_m_roles.split("|").map((abct_pref) => {
            // return { name: I18n.t("custom_ap_profile.ageplay_role_" + abct_pref).substr(0,1) === "[" ? ageplay_role: I18n.t("custom_ap_profile.ageplay_role_" + abct_pref), value: abct_pref }});
              // component.setProperties({
               // ageplay_roles
             // });
            
            // showLog(show_debug_info, 'component.siteSettings.custom_ap_profile_ageplay_roles:');
            // showLog(show_debug_info, component.siteSettings.custom_ap_profile_ageplay_roles);
             // showLog(show_debug_info, 'component.siteSettings.custom_ap_profile_ageplay_f_roles:');
            // showLog(show_debug_info, component.siteSettings.custom_ap_profile_ageplay_f_roles);
             // showLog(show_debug_info, 'component.ageplay_roles:');
            // showLog(show_debug_info, component.ageplay_roles);
             
            // component.siteSettings.custom_ap_profile_ageplay_f_roles
            
            //showLog(show_debug_info, component.ageplay_roles.filter(val => component.siteSettings.custom_ap_profile_ageplay_f_roles.includes(val) || showLog(show_debug_info, val)))
             // model.set("gender", gender);
            // showLog(show_debug_info, 'args.model:');
            // showLog(show_debug_info, args.model);
            //showLog(show_debug_info, 'checkGender complete');
            */
            (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'new roles set by checkGenderPrefs');
            component.set('custom_fields.gender', gender);
          }
          if (hasGender) {
            (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'qaqq');
            let eg = document.getElementsByClassName("edit-gender");
            (0, _discourse_custom_ap_profile.showLog)(show_debug_info, eg);
            if (typeof eg !== 'undefined' && eg.length > 0) {
              eg[0].childNodes[5].childNodes[1].childNodes[1].childNodes[0].childNodes[4].title = _I18n.default.t("custom_ap_profile.instructions_gender");
            }
          } else {
            model.set('custom_fields.showOnUserCardGender', true);
          }
        };
        const checkAgeplayRoles = function () {
          //showLog(show_debug_info, component);
          //showLog(show_debug_info, component.ageplayRoles);
          //showLog(show_debug_info, ageplayRoles);

          (0, _discourse_custom_ap_profile.showWarn)(show_debug_info, 'checkAgeplayRoles');
          let ageplayRoles = model.custom_fields.ageplayRoles;
          let ageplayRoles_old = component.get('ageplayRoles');
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'ageplayRoles:');
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, ageplayRoles);
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'ageplayRoles_old:');
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, ageplayRoles_old);
          let gender = args.model.custom_fields.gender;
          let bRolesAdded = false;
          let bRolesChanged = false;
          let bRolesRemoved = false;
          let hasAgeplayRole = false;
          if (typeof ageplayRoles !== 'undefined' && ageplayRoles !== null) {
            if (ageplayRoles.length === 0) {
              args.model.set("custom_fields.ageplayRoles", null);
              args.model.get("custom_fields.play_age", '');
              args.model.get("custom_fields.care_age", '');
              bRolesRemoved = true;
              component.set("isABCT", false);
              component.set("isABTB", false);
            } else {
              if (typeof ageplayRoles_old !== 'undefined' && ageplayRoles_old !== null && ageplayRoles_old.length > 0 && ageplayRoles_old[0] !== '') {
                bRolesAdded = ageplayRoles.length > ageplayRoles_old.length;
                bRolesRemoved = ageplayRoles.length < ageplayRoles_old.length;

                // if (!bRolesAdded && !bRolesRemoved)
                // {
                (0, _discourse_custom_ap_profile.showLog)(show_debug_info, ageplayRoles.filter(val => !ageplayRoles_old.includes(val)));
                bRolesChanged = ageplayRoles.filter(val => !ageplayRoles_old.includes(val)).length > 0;
                // }
                // else
                // {
                // bRolesChanged = true;
                // }
              } else {
                bRolesAdded = true;
                bRolesChanged = bRolesAdded;
                // hasAgeplayRole = bRolesAdded;
              }

              // hasAgeplayRole = typeof ageplayRoles !== 'undefined' && ageplayRoles !== null && ageplayRoles !== "" && ageplayRoles.length > 0 && ageplayRoles[0].length > 0;
              hasAgeplayRole = typeof ageplayRoles !== "" && ageplayRoles.length > 0 && ageplayRoles[0].length > 0;
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, hasAgeplayRole);
              component.set('hasAgeplayRole', hasAgeplayRole);
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'asd');
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'kebab1');
              // showLog(show_debug_info, ageplayRoles);
              // let nAgeplayRoles = ageplayRoles.length
              // showLog(show_debug_info, 'nAgeplayRoles: ' + nAgeplayRoles);
              // showLog(show_debug_info, component.siteSettings.custom_ap_profile_abct_roles);
              // showLog(show_debug_info, ageplayRoles.filter(val => !component.siteSettings.custom_ap_profile_abct_roles.includes(val)));

              const isABCT = hasAgeplayRole && ageplayRoles.filter(val => component.siteSettings.custom_ap_profile_abct_roles.includes(val)).length > 0;
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'isABCT: ' + isABCT);
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'kebab2');
              const isABTB = hasAgeplayRole && ageplayRoles.filter(val => val !== '' && component.siteSettings.custom_ap_profile_abtb_roles.includes(val)).length > 0;
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'isABTB: ' + isABTB);
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'kebab3');

              //args.model.set("isABCT", isABCT);
              //args.model.set("isABTB", isABTB);
              component.set("isABCT", isABCT);
              component.set("isABTB", isABTB);
            }
          }
          const isFurry = hasAgeplayRole && ageplayRoles.filter(val => component.siteSettings.custom_ap_profile_furry_roles.includes(val)).length > 0;
          const isSwitch = hasAgeplayRole && ageplayRoles.includes('switch');
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'bRolesAdded: ' + bRolesAdded);
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'bRolesChanged: ' + bRolesChanged);
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'bRolesRemoved: ' + bRolesRemoved);
          if (bRolesChanged) {
            //why keep theese local?
            const ageplay_roles = component.siteSettings.custom_ap_profile_ageplay_roles;
            const ageplay_f_roles = component.siteSettings.custom_ap_profile_ageplay_f_roles;
            const ageplay_m_roles = component.siteSettings.custom_ap_profile_ageplay_m_roles;
            const furry_roles = component.siteSettings.custom_ap_profile_furry_roles;
            let aRoles_f = ageplay_f_roles.split('|');
            let aRoles_m = ageplay_m_roles.split('|');
            let aRoles_furry = furry_roles.split('|');
            const genders_f = component.siteSettings.custom_ap_profile_genders_f;
            const genders_m = component.siteSettings.custom_ap_profile_genders_m;
            const aGenders_f = genders_f.split('|');
            const aGenders_m = genders_m.split('|');

            /*
            //flyttet til save funktion
            // if (!isABCT) {
              // args.model.set("custom_fields.care_age", null);
            // }
            // if (!isABTB) {
              // args.model.set("custom_fields.play_age", null);
            // }
            
            //const isABTB = ageplayRoles.filter(val => component.siteSettings.custom_ap_profile_abtb_roles.includes(val)).length > 0;
            */
            (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'asd2');
            let curABDLPrefs = null;
            let nABDLPrefs = 0;
            (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'asd2b');
            if (hasAgeplayRole) {
              curABDLPrefs = ageplayRoles; // .map((x) => x);
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'curABDLPrefs');
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, curABDLPrefs);
              let lastValue = curABDLPrefs.pop();
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'curABDLPrefs popped');
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, curABDLPrefs);
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'lastValue: ' + lastValue);
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'asd3');
              nABDLPrefs = ageplayRoles.length;
              const aPrefs = ageplay_roles.split('|');
              //makes no sense
              (0, _discourse_custom_ap_profile.showWarn)(show_debug_info, 'makes no sense');
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, ageplay_roles);
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, ageplay_f_roles);
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, ageplay_m_roles);
              // const aFPrefs = ageplay_f_roles.split('|').filter(x => !ageplay_m_roles.split('|').includes(x));
              // const aMPrefs = ageplay_m_roles.split('|').filter(x => !ageplay_f_roles.split('|').includes(x));
              let aFPrefs = aPrefs;
              // aFPrefs = aFPrefs.filter(x => !ageplay_m_roles.split('|').includes(x));
              aFPrefs = aPrefs.filter(x => !aRoles_m.includes(x));
              let aMPrefs = aPrefs;
              // aMPrefs = aMPrefs.filter(x => !ageplay_f_roles.split('|').includes(x));
              aMPrefs = aPrefs.filter(x => !aRoles_f.includes(x));
              // aMPrefs = ageplay_f_roles.split('|');

              let aFurPrefs = aPrefs.filter(x => !aRoles_f.includes(x) && !aRoles_m.includes(x));

              // aRoles_f = aPrefs.filter(x => !aFPrefs.includes(x));
              // aRoles_m = aPrefs.filter(x => !aMPrefs.includes(x));

              if (true) {
                (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'robin test');
                (0, _discourse_custom_ap_profile.showLog)(show_debug_info, aPrefs);
                (0, _discourse_custom_ap_profile.showLog)(show_debug_info, aFPrefs);
                (0, _discourse_custom_ap_profile.showLog)(show_debug_info, aMPrefs);
                (0, _discourse_custom_ap_profile.showLog)(show_debug_info, aRoles_f);
                (0, _discourse_custom_ap_profile.showLog)(show_debug_info, aRoles_m);
                (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'asd4');
                (0, _discourse_custom_ap_profile.showLog)(show_debug_info, args.model.get("custom_fields.gender"));
                (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'aFPrefs include lastValue: ' + aFPrefs.includes(lastValue));
                (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'lastValue === "sissy": ' + lastValue === 'sissy');
              }
              const isSissy = typeof ageplayRoles !== 'undefined' && ageplayRoles !== null && ageplayRoles.includes('sissy') || lastValue === 'sissy';
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'isSissy: ' + isSissy);
              const hasGenderRoleFemale = aRoles_f.includes(lastValue); // && !aGenders_f.includes(args.model.get("custom_fields.gender"));
              // showLog(show_debug_info, 'hasGenderRoleFemale: ' + hasGenderRoleFemale);
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'hasGenderRoleFemale:');
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, hasGenderRoleFemale);
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'asd4a');
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'lastValue: ' + lastValue);
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'aGenders_m:');
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, aGenders_m);
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, args.model.get("custom_fields.gender"));
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, aRoles_m.includes(lastValue));
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, !aGenders_m.includes(args.model.get("custom_fields.gender")));
              const hasGenderRoleMale = aRoles_m.includes(lastValue); // && !aGenders_m.includes(args.model.get("custom_fields.gender"));
              // showLog(show_debug_info, 'hasGenderRoleMale: ' + hasGenderRoleMale);
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'hasGenderRoleMale:');
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, hasGenderRoleMale);
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'asd4b');
              const isGenderOther = hasGenderRoleFemale === false && hasGenderRoleMale === false && isSelf === false;
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'asd4c');
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'isGenderOther: ' + isGenderOther);
              if (hasGenderRoleFemale && bRolesAdded) {
                (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'hasGenderRoleFemale && bRolesAdded is true');
                (0, _discourse_custom_ap_profile.showWarn)(show_debug_info, 'asd444');
                (0, _discourse_custom_ap_profile.showLog)(show_debug_info, gender);
                //if (args.model.get("custom_fields.gender") !== 'f')
                if (!isSissy && !aGenders_f.includes(gender)) {
                  if (gender !== null && gender !== '') alertMsg(_I18n.default.t("custom_ap_profile.gender_specific_option_selected").replace('%{gender}', _I18n.default.t("custom_ap_profile.gender_f")));
                  args.model.set("custom_fields.gender", 'f');
                }
                (0, _discourse_custom_ap_profile.showLog)(show_debug_info, ageplayRoles);
                //fjerner valgte kvindelige roller med mindre sissy er valgt
                if (!isSissy) ageplayRoles = ageplayRoles.filter(val => ageplay_f_roles.includes(val));
                (0, _discourse_custom_ap_profile.showLog)(show_debug_info, ageplayRoles);
                //showModal('gender1', { title: 'qwe' });
                //this.flash(extractError(e), "error"));
              }
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'asd5');
              if (hasGenderRoleMale && bRolesAdded) {
                (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'hasGenderRoleMale');
                //if (args.model.get("custom_fields.gender") !== 'm')
                if (!aGenders_m.includes(gender)) if (gender !== null && gender !== '') alertMsg(_I18n.default.t("custom_ap_profile.gender_specific_option_selected").replace('%{gender}', _I18n.default.t("custom_ap_profile.gender_m")));
                args.model.set("custom_fields.gender", 'm');
                (0, _discourse_custom_ap_profile.showLog)(show_debug_info, ageplayRoles);
                //fjerner valgte kvindelige roller med mindre sissy er valgt
                if (!isSissy) ageplayRoles = ageplayRoles.filter(val => ageplay_m_roles.includes(val));
                (0, _discourse_custom_ap_profile.showLog)(show_debug_info, ageplayRoles);
              }
              if (bRolesRemoved && !isSissy) ageplayRoles = ageplayRoles.filter(val => ageplay_f_roles.includes(val));
              let newRoles_array;
              if (isFurry) newRoles_array = aFurPrefs;else if (hasGenderRoleFemale) newRoles_array = aFPrefs;else if (hasGenderRoleMale) newRoles_array = aMPrefs;else
                //if (isSissy || isGenderOther)
                newRoles_array = aPrefs;
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, newRoles_array);
              let newRoles_map = newRoles_array.map(ageplay_role => {
                return {
                  name: _I18n.default.t("custom_ap_profile.ageplay_role_" + ageplay_role).substr(0, 1) === "[" ? _I18n.default.t("custom_ap_profile.ageplay_role_" + ageplay_role + '2').substr(0, 1) === "[" ? ageplay_role : _I18n.default.t("custom_ap_profile.ageplay_role_" + ageplay_role + '2') : _I18n.default.t("custom_ap_profile.ageplay_role_" + ageplay_role),
                  value: ageplay_role
                };
              });
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'newRoles_map:');
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, newRoles_map);
              component.set('ageplay_roles', newRoles_map);
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'asd6');
              //custom_ap_profile_abtb_roles
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'asd7');
              if (abtb_roles.includes(lastValue) && !isSwitch) {
                (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'curABDLPrefs(1):');
                (0, _discourse_custom_ap_profile.showLog)(show_debug_info, curABDLPrefs);
                curABDLPrefs = curABDLPrefs.filter(val => !abtb_roles.includes(val));
                // curABDLPrefs.push(lastValue);
                // args.model.set("custom_fields.ageplayRoles", curABDLPrefs);
                (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'curABDLPrefs(2):');
                (0, _discourse_custom_ap_profile.showLog)(show_debug_info, curABDLPrefs);
                (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'abtb_roles');
                (0, _discourse_custom_ap_profile.showLog)(show_debug_info, abtb_roles);
              }
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'asd8');
              //filter baby,litle,middle,big roles, so there is only one left (lastValue)
              if (blmb_roles.includes(lastValue) && !isSwitch) {
                if (true) {
                  (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'orig');
                  (0, _discourse_custom_ap_profile.showLog)(show_debug_info, args.model.get("custom_fields.ageplayRoles"));
                  (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'curABDLPrefs(3):');
                  (0, _discourse_custom_ap_profile.showLog)(show_debug_info, curABDLPrefs);
                  (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'blmb_roles');
                  (0, _discourse_custom_ap_profile.showLog)(show_debug_info, blmb_roles);
                }
                curABDLPrefs = curABDLPrefs.filter(val => !blmb_roles.includes(val));
                if (true) {
                  (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'curABDLPrefs(4):');
                  (0, _discourse_custom_ap_profile.showLog)(show_debug_info, curABDLPrefs);
                  // curABDLPrefs.push(lastValue);
                  // showLog(show_debug_info, 'pushed');
                  // showLog(show_debug_info, curABDLPrefs);
                  // args.model.set("custom_fields.ageplayRoles", curABDLPrefs);
                  (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'orig 2');
                  (0, _discourse_custom_ap_profile.showLog)(show_debug_info, args.model.get("custom_fields.ageplayRoles"));
                }
              }
              if (aRoles_furry.includes(lastValue) && !isSwitch) {
                curABDLPrefs = curABDLPrefs.filter(val => !aRoles_furry.includes(val));
              }
              curABDLPrefs.push(lastValue);
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'pushed');
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, curABDLPrefs);
              component.set('ageplayRoles_old', curABDLPrefs);
              args.model.set("custom_fields.ageplayRoles", curABDLPrefs);

              //ageplayRoles.filter(val => ageplay_m_roles.includes(val));

              //args.model.set("ageplayRoles", ageplayRoles);

              //args.model.set("custom_fields.ageplayRoles", ageplayRoles);
            } else {
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'saving state to component');
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'curABDLPrefs:');
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, curABDLPrefs);
              component.set('ageplayRoles', curABDLPrefs);
              args.model.set("custom_fields.ageplayRoles", null);
            }
          } else (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'no changes made');
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'component.get(\'ageplay_roles\'):');
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, component.get('ageplay_roles'));
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'component.get(\'ageplayRoles\'):');
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, component.get('ageplayRoles'));
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'args.model.get(\'custom_fields.ageplayRoles\'):');
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, args.model.get('custom_fields.ageplayRoles'));
          if (hasAgeplayRole) {
            let ear = document.getElementsByClassName("edit-ageplay_roles");
            if (typeof ear !== 'undefined' && ear.length > 0) {
              ear[0].childNodes[5].childNodes[1].childNodes[1].childNodes[0].title = _I18n.default.t("custom_ap_profile.instructions_ageplay_roles");
            }
          } else {
            model.set('custom_fields.showOnUserCardAgeplayRoles', true);
          }
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'checkAgeplayRoles complete');
        };
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'flubber8:');
        const checkPlayAge = function () {
          const play_age = model.custom_fields.play_age;
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'play_age:');
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, play_age);
          const hasPlayAge = typeof play_age !== 'undefined' && play_age !== null && play_age !== "";
          component.set("hasPlayAge", hasPlayAge);
          if (!hasPlayAge) {
            model.set('custom_fields.showOnUserCardPlayAge', true);
          }
        };
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'flubber8b:');
        const checkCareAge = function () {
          const care_age = model.custom_fields.care_age;
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'care_age:');
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, care_age);
          const hasCareAge = typeof care_age !== 'undefined' && care_age !== null && care_age !== "";
          component.set("hasCareAge", hasCareAge);
          if (!hasCareAge) {
            model.set('custom_fields.showOnUserCardCareAge', true);
          }
        };
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'flubber8c:');
        const checkName = function () {
          const hasName = args.model.get("name") !== "";
          args.model.set("hasName", hasName);
          component.set("hasName", hasName);
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, args.model.name);
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'checkName - hasName: ' + hasName);
        };
        const checkSortInterests = function () {
          const interests_rev = component.get("interests_rev");
          const hasInterestsRev = typeof interests_rev !== 'undefined' && interests_rev !== "";
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, hasInterestsRev);
          if (hasInterestsRev) {
            const interests_sorted = [];
            for (const revName of interests_rev) {
              const interestItem = interests_list.find(item => item.name === revName);
              if (interestItem) {
                interests_sorted.push(interestItem.value);
              }
            }
            args.model.set("custom_fields.interests", interests_sorted);
          }
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, component);
        };
        const processInterests = async function (interests, muted_category_ids, muted_interests, allInterests, component, diaper_library) {
          console.log('has interests');
          // showLog(show_debug_info, interests)
          // showLog(show_debug_info, 'i3')
          // showLog(show_debug_info, 'muted_category_ids:');
          // showLog(show_debug_info, muted_category_ids);
          // showLog(show_debug_info, 'component.siteSettings.custom_ap_profile_interests_stories:');
          // showLog(show_debug_info, component.siteSettings.custom_ap_profile_interests_stories);
          allInterests.forEach(function (item) {
            // showLog(show_debug_info, item);
            let cid = null;
            let bAction = false;
            switch (item) {
              case 'cars':
                cid = component.siteSettings.custom_ap_profile_interests_cars;
                break;
              case 'cartoons':
                cid = component.siteSettings.custom_ap_profile_interests_cartoons;
                break;
              case 'cooking':
                cid = component.siteSettings.custom_ap_profile_interests_cooking;
                break;
              case 'creativity':
                cid = component.siteSettings.custom_ap_profile_interests_creativity;
                break;
              case 'diapers':
                cid = component.siteSettings.custom_ap_profile_interests_diapers;
                break;
              case 'geekstuff':
                cid = component.siteSettings.custom_ap_profile_interests_geekstuff;
                break;
              case 'makerspace':
                cid = component.siteSettings.custom_ap_profile_interests_makerspace;
                break;
              case 'politics':
                cid = component.siteSettings.custom_ap_profile_interests_politics;
                break;
              case 'sports':
                cid = component.siteSettings.custom_ap_profile_interests_sports;
                break;
              case 'stories':
                cid = component.siteSettings.custom_ap_profile_interests_stories;
                break;
              case 'trains':
                cid = component.siteSettings.custom_ap_profile_interests_trains;
                break;
            }
            if (typeof cid !== 'undefined' && cid !== null && cid !== '') {
              // showLog(show_debug_info, cid);
              cid = parseInt(cid, 10);
              // showLog(show_debug_info, cid);
              if (!isNaN(cid)) {
                (0, _discourse_custom_ap_profile.showLog)(show_debug_info, item + ' is not undefined');
                // showLog(show_debug_info, interests);
                (0, _discourse_custom_ap_profile.showLog)(show_debug_info, interests.includes(item));
                if (interests.includes(item)) {
                  (0, _discourse_custom_ap_profile.showLog)(show_debug_info, muted_category_ids);
                  // showLog(show_debug_info, cid);
                  if (muted_category_ids.includes(cid)) {
                    (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'cid (' + cid + ') is included in muted_category_ids');
                    // muted_category_ids = muted_category_ids.filter(val => (val !== cid));

                    const index = muted_category_ids.indexOf(cid);
                    if (index > -1) {
                      // only splice array when item is found
                      muted_category_ids.splice(index, 1); // 2nd parameter means remove one item only
                    }
                    (0, _discourse_custom_ap_profile.showLog)(show_debug_info, muted_category_ids);
                  }
                  // let tmp_categories = muted_category_ids.filter(val => (val !== cid));
                  // muted_category_ids = tmp_categories;

                  // showLog(show_debug_info, 'filteres');
                  // showLog(show_debug_info, muted_category_ids.filter(val => val !== cid));
                  // showLog(show_debug_info, 'asd');
                } else {
                  //add
                  // showLog(show_debug_info, 'adding ' + item + ' (' + cid + ')');
                  if (!muted_category_ids.includes(cid)) {
                    muted_category_ids.push(cid);
                  }
                  muted_interests.push({
                    name: item,
                    cid: cid
                  });
                }
              }
            }
            if (item === 'diapers' && typeof diaper_library !== 'undefined' && diaper_library !== null) {
              if (interests.includes(item)) {
                console.log('interests include ' + item);
                // alert('should be removed');
                const index = muted_category_ids.indexOf(diaper_library);
                console.log('index');
                console.log(index);
                if (index > -1) {
                  // only splice array when item is found
                  console.log('yup remove');
                  console.log('muted_category_ids');
                  console.log(muted_category_ids);
                  muted_category_ids.splice(index, 1); // 2nd parameter means remove one item only
                  console.log(muted_category_ids);
                }
              } else {
                console.log('interests does not include ' + item);
                // alert('should we add?');
                console.log([muted_category_ids, diaper_library], !muted_category_ids.includes(diaper_library));
                if (!muted_category_ids.includes(diaper_library)) {
                  console.log('yup add');
                  muted_category_ids.push(diaper_library);
                } else {
                  console.log('already added');
                }
              }
              console.log('muted_category_ids 2');
              console.log(muted_category_ids);
            }

            //library_list.push({ name: item.title, value: item.title });
          });
          console.log('muted_category_ids 3');
          console.log(muted_category_ids);
          // showLog(show_debug_info, muted_category_ids);

          // showLog(show_debug_info, component.siteSettings.custom_ap_profile_interests_cars);
          // showLog(show_debug_info, component.siteSettings.custom_ap_profile_interests_cartoons);
          // showLog(show_debug_info, component.siteSettings.custom_ap_profile_interests_cooking);
          // showLog(show_debug_info, component.siteSettings.custom_ap_profile_interests_creativity);
          // showLog(show_debug_info, component.siteSettings.custom_ap_profile_interests_diapers);
          // showLog(show_debug_info, component.siteSettings.custom_ap_profile_interests_geekstuff);
          // showLog(show_debug_info, component.siteSettings.custom_ap_profile_interests_makerspace);
          // showLog(show_debug_info, component.siteSettings.custom_ap_profile_interests_politics);
          // showLog(show_debug_info, component.siteSettings.custom_ap_profile_interests_sports);
          // showLog(show_debug_info, component.siteSettings.custom_ap_profile_interests_stories);
          // showLog(show_debug_info, component.siteSettings.custom_ap_profile_interests_trains);
          return {
            'muted_category_ids': muted_category_ids,
            'muted_interests': muted_interests
          };
        };
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'flubber8d:');
        const checkInterests = function () {
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'checkInterests');
          const interests = args.model.get("custom_fields.interests");
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, interests);
          const hasInterests = typeof interests !== 'undefined' && interests !== "" && interests.length > 0;
          component.set("hasInterests", hasInterests);
          let interests_rev = [];
          for (const interest in interests) {
            interests_rev[interest] = _I18n.default.t("custom_ap_profile.interests_" + interests[interest]).substr(0, 1) === "[" ? interests[interest] : _I18n.default.t("custom_ap_profile.interests_" + interests[interest]);
          }
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'interests_rev:');
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, interests_rev);
          component.set("interests_rev", interests_rev);
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, component);
          let muted_category_ids = model.get('muted_category_ids');
          let muted_interests = [];
          const allInterests = component.siteSettings.custom_ap_profile_interests.split('|');
          const diaper_library = parseInt(component.siteSettings.custom_ap_profile_diaper_library);

          // let interests_map = args.model.get("custom_fields.interests").map(interest => {
          // return { name: I18n.t("custom_ap_profile.interest_" + interest).substr(0,1) === "[" ? (I18n.t("custom_ap_profile.interest_" + interest + '2').substr(0,1) === "[" ? interest : I18n.t("custom_ap_profile.interest_" + interest + '2')) : I18n.t("custom_ap_profile.interest_" + interest), value: interest };
          // });

          // component.set("interests_map", interests_map);

          //interesser der er valgt, skal fjernes fra muted_category_ids
          //interesser der ikke er valgt skal tilføjes muted_category_ids hvis der er angivet en siteSettings category

          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, allInterests);
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'hasInterests: ' + hasInterests);
          if (hasInterests) {
            (async () => {
              const modifiedArrays = await processInterests(interests, muted_category_ids, muted_interests, allInterests, component, diaper_library);
              console.log(modifiedArrays); // Log the modified array after the loop has finished
              model.set('muted_category_ids', modifiedArrays.muted_category_ids);
              component.set('muted_interests', modifiedArrays.muted_interests.sort((a, b) => a.name > b.name ? 1 : a.name < b.name ? -1 : 0));
            })();

            // muted_interests = muted_interests.sort((a,b) => ((a.name > b.name) ? 1 : (a.name < b.name) ? -1 : 0));
            // model.set('muted_category_ids', muted_category_ids);
            console.log('muted_category_ids set in model');
            console.log(muted_category_ids);
            console.log(model.get('muted_category_ids'));
          }
          // component.set('muted_interests', muted_interests);

          //model.get('muted_category_ids');

          if (hasInterests && false) {
            let ei = document.getElementsByClassName("edit-interests");
            if (ei.length > 0) {
              // ei[0].childNodes[5].childNodes[1].childNodes[1].childNodes[0].title=I18n.t("custom_ap_profile.instructions_interests");
              ei[0].childNodes[6].childNodes[2].childNodes[1].childNodes[0].title = _I18n.default.t("custom_ap_profile.instructions_interests");
            }
          }
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'checkInterests done');
        };
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'flubber8e:');
        const checkDiaperChange = function () {
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'checkDiaperChange');
          const diaper_change = args.model.get("custom_fields.diaper_change");
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'camille #1');
          const diaper_change_old = component.get('diaper_change');
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'camille #2');
          let cur_diaper_change = diaper_change;
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'camille #3');
          let bChangeAdded = false;
          let bChangeChanged = false;
          let bChangeRemoved = false;
          let isDiaperChanger = false;
          let hasBoth = false;
          let changesBoth = false;
          let hasDiaperChange = typeof diaper_change !== 'undefined' && diaper_change !== "" && diaper_change !== null;
          const hasDiaperChangeOld = typeof diaper_change_old !== 'undefined' && diaper_change_old !== "";
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'hasDiaperChange: ' + hasDiaperChange);
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'hasDiaperChangeOld: ' + hasDiaperChangeOld);
          if (hasDiaperChange && hasDiaperChangeOld) {
            (0, _discourse_custom_ap_profile.showLog)(show_debug_info, cur_diaper_change);
            let hasBoth = cur_diaper_change.includes('#1') && cur_diaper_change.includes('#2');
            let changesBoth = cur_diaper_change.includes('boys') && cur_diaper_change.includes('girls');
            if (cur_diaper_change.length === 0) {
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'dc1');
              args.model.set("custom_fields.diaper_change", null);
              hasDiaperChange = false;
            } else if (cur_diaper_change.length === 1 && cur_diaper_change[0] === '') {
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'dc2');
              args.model.set("custom_fields.diaper_change", null);
              hasDiaperChange = false;
            } else {
              let lastValue = cur_diaper_change.pop();
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'camille #4');
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, diaper_change);
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, diaper_change_old);
              bChangeAdded = diaper_change.length > diaper_change_old.length;
              bChangeRemoved = diaper_change.length < diaper_change_old.length;
              bChangeChanged = diaper_change.filter(val => !diaper_change_old.includes(val)).length > 0;
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'camille #5');
              if (lastValue === 'none') {
                (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'camille #6a');
                cur_diaper_change = [];
                (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'camille #6b');
                hasDiaperChange = false;
                (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'camille #6c');
              } else {
                (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'camille #7a');
                cur_diaper_change = cur_diaper_change.filter(val => val !== 'none');
                (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'camille #7b');
              }
              let aChangeTypes = ['#1', '#2', 'both'];
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'camille #8');
              if (['#1', '#2'].includes(lastValue) && hasBoth) {
                (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'hasBoth: ' + hasBoth);
                cur_diaper_change = cur_diaper_change.filter(val => !['#1', '#2'].includes(val));
                (0, _discourse_custom_ap_profile.showLog)(show_debug_info, cur_diaper_change);
                lastValue = 'both';
              } else if (aChangeTypes.includes(lastValue)) cur_diaper_change = cur_diaper_change.filter(val => !aChangeTypes.includes(val));
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'camille #8b');
              if (['boys', 'girls'].includes(lastValue) && changesBoth) {
                (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'changesBoth: ' + changesBoth);
                cur_diaper_change = cur_diaper_change.filter(val => !['boys', 'girls'].includes(val));
                (0, _discourse_custom_ap_profile.showLog)(show_debug_info, cur_diaper_change);
                lastValue = 'all';
              } else if (aChangeTypes.includes(lastValue)) cur_diaper_change = cur_diaper_change.filter(val => !aChangeTypes.includes(val));

              //ttom ???
              // let aChangeTimes = ['247','oiaw','d','w','y','ttom','wic'];
              let aChangeTimes = ['247', 'oiaw', 'd', 'w', 'y', 'wic'];
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'camille #9');
              if (aChangeTimes.includes(lastValue)) cur_diaper_change = cur_diaper_change.filter(val => !aChangeTimes.includes(val));
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'camille #10');
              cur_diaper_change.push(lastValue);

              // hasDiaperChange = cur_diaper_change !== [];

              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'camille #11');
              isDiaperChanger = !cur_diaper_change.includes('none') && cur_diaper_change !== [];
              component.set("isDiaperChanger", isDiaperChanger);
              component.set("diaper_change", cur_diaper_change);
              args.model.set('custom_fields.diaper_change', cur_diaper_change);
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'camille #12');
            }
          } else {
            (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'else');
            (0, _discourse_custom_ap_profile.showLog)(show_debug_info, cur_diaper_change);
            if (typeof diaper_change === 'undefined') {
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'dc0');
              hasDiaperChange = false;
            } else if (cur_diaper_change.length === 0) {
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'dc1');
              hasDiaperChange = false;
            } else if (cur_diaper_change.length === 1 && cur_diaper_change[0] === '') {
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'dc2');
              hasDiaperChange = false;
            } else {
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'else');
              component.set("diaper_change", diaper_change);
            }
          }
          component.set("hasDiaperChange", hasDiaperChange);
          if (hasDiaperChange) {
            let edc = document.getElementsByClassName("edit-diaper_change");
            if (typeof edc !== 'undefined' && edc.length > 0) {
              edc[0].childNodes[5].childNodes[1].childNodes[1].childNodes[0].title = _I18n.default.t("custom_ap_profile.instructions_diaper_change");
            }
          } else {
            model.set('custom_fields.showOnUserCardDiaperChange', true);
          }
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'checkDiaperChange done');
        };
        const checkDiaperUsage = function () {
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'checkDiaperUsage');
          const diaper_usage = args.model.get("custom_fields.diaper_usage");
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'danni #1');
          const diaper_usage_old = component.get('diaper_usage');
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'danni #2');
          let cur_diaper_usage = diaper_usage;
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'danni #3');
          let bUsageAdded = false;
          let bUsageChanged = false;
          let bUsageRemoved = false;
          let isDiaperUser = false;
          let hasBoth = false;
          let hasDiaperUsage = typeof diaper_usage !== 'undefined' && diaper_usage !== "" && diaper_usage !== null;
          const hasDiaperUsageOld = typeof diaper_usage_old !== 'undefined' && diaper_usage_old !== "";
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'hasDiaperUsage: ' + hasDiaperUsage);
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'hasDiaperUsageOld: ' + hasDiaperUsageOld);
          if (hasDiaperUsage && hasDiaperUsageOld) {
            (0, _discourse_custom_ap_profile.showLog)(show_debug_info, cur_diaper_usage);
            let hasBoth = cur_diaper_usage.includes('#1') && cur_diaper_usage.includes('#2');
            if (cur_diaper_usage.length === 0) {
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'du1');
              args.model.set("custom_fields.diaper_usage", null);
              hasDiaperUsage = false;
            } else if (cur_diaper_usage.length === 1 && cur_diaper_usage[0] === '') {
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'du2');
              args.model.set("custom_fields.diaper_usage", null);
              hasDiaperUsage = false;
            } else {
              let lastValue = cur_diaper_usage.pop();
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'danni #4');
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, diaper_usage);
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, diaper_usage_old);
              bUsageAdded = diaper_usage.length > diaper_usage_old.length;
              bUsageRemoved = diaper_usage.length < diaper_usage_old.length;
              bUsageChanged = diaper_usage.filter(val => !diaper_usage_old.includes(val)).length > 0;
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'danni #5');
              if (lastValue === 'none') {
                (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'danni #6a');
                cur_diaper_usage = [];
                (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'danni #6b');
                hasDiaperUsage = false;
                (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'danni #6c');
              } else {
                (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'danni #7a');
                cur_diaper_usage = cur_diaper_usage.filter(val => val !== 'none');
                (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'danni #7b');
              }
              let aUsageTypes = ['#1', '#2', 'both'];
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'danni #8');
              if (['#1', '#2'].includes(lastValue) && hasBoth) {
                (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'hasBoth: ' + hasBoth);
                cur_diaper_usage = cur_diaper_usage.filter(val => !['#1', '#2'].includes(val));
                (0, _discourse_custom_ap_profile.showLog)(show_debug_info, cur_diaper_usage);
                lastValue = 'both';
              } else if (aUsageTypes.includes(lastValue)) cur_diaper_usage = cur_diaper_usage.filter(val => !aUsageTypes.includes(val));

              //ttom ???
              // let aUsageTimes = ['247','oiaw','d','w','y','ttom','wic'];
              let aUsageTimes = ['247', 'oiaw', 'd', 'w', 'y', 'wic'];
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'danni #9');
              if (aUsageTimes.includes(lastValue)) cur_diaper_usage = cur_diaper_usage.filter(val => !aUsageTimes.includes(val));
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'danni #10');
              cur_diaper_usage.push(lastValue);

              // hasDiaperUsage = cur_diaper_usage !== [];

              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'danni #11');
              isDiaperUser = !cur_diaper_usage.includes('none') && cur_diaper_usage !== [];
              component.set("isDiaperUser", isDiaperUser);
              component.set("diaper_usage", cur_diaper_usage);
              args.model.set('custom_fields.diaper_usage', cur_diaper_usage);
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'danni #12');
            }
          } else {
            (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'else');
            (0, _discourse_custom_ap_profile.showLog)(show_debug_info, cur_diaper_usage);
            if (typeof diaper_usage === 'undefined') {
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'du0');
              hasDiaperUsage = false;
            } else if (cur_diaper_usage.length === 0) {
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'du1');
              hasDiaperUsage = false;
            } else if (cur_diaper_usage.length === 1 && cur_diaper_usage[0] === '') {
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'du2');
              hasDiaperUsage = false;
            } else {
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'else');
              component.set("diaper_usage", diaper_usage);
              isDiaperUser = !cur_diaper_usage.includes('none') && cur_diaper_usage !== [];
              component.set("isDiaperUser", isDiaperUser);
            }
          }
          component.set("hasDiaperUsage", hasDiaperUsage);
          if (hasDiaperUsage) {
            let edu = document.getElementsByClassName("edit-diaper_usage");
            if (typeof edu !== 'undefined' && edu.length > 0) {
              edu[0].childNodes[5].childNodes[1].childNodes[1].childNodes[0].title = _I18n.default.t("custom_ap_profile.instructions_diaper_usage");
            }
          } else {
            model.set('custom_fields.showOnUserCardDiaperUsage', true);
          }
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'checkDiaperUsage done');
        };
        const checkWaist = function () {
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'checkWaist');
          const waist = args.model.get("custom_fields.waist");
          let hasWaist = typeof waist !== 'undefined' && waist !== "" && waist !== null;
          component.set("hasWaist", hasWaist);
          if (!hasWaist) {
            model.set('custom_fields.showOnUserCardWaist', true);
          }
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'checkWaist done');
        };
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'flubber8f:');
        const checkFavoriteDiapers = function () {
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'checkFavoriteDiapers:');
          const favorite_diapers = args.model.get("custom_fields.favorite_diapers");
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'favorite_diapers:');
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, favorite_diapers);
          let hasFavoriteDiaper = typeof favorite_diapers !== 'undefined' && favorite_diapers !== "";
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'hasFavoriteDiaper:');
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, hasFavoriteDiaper);

          // if (typeof favorite_diapers === 'undefined') {
          //   args.model.set("custom_fields.favorite_diapers", null);
          //   hasFavoriteDiaper = false;
          // }
          // else if (favorite_diapers.length === 0) {
          //   args.model.set("custom_fields.favorite_diapers", null);
          //   hasFavoriteDiaper = false;
          // }
          component.set("hasFavoriteDiaper", hasFavoriteDiaper);
          // showLog(show_debug_info, favorite_diapers.length);

          if (hasFavoriteDiaper) {
            let efd = document.getElementsByClassName("edit-favorite_diapers");
            (0, _discourse_custom_ap_profile.showLog)(show_debug_info, efd);
            (0, _discourse_custom_ap_profile.showLog)(show_debug_info, efd.length);
            if (typeof efd !== 'undefined' && efd.length > 0) {
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, efd[0].childNodes[5].childNodes[1].childNodes[1].childNodes[0]);
              efd[0].childNodes[5].childNodes[1].childNodes[1].childNodes[0].title = _I18n.default.t("custom_ap_profile.instructions_favorite_diapers");
            }
          } else {
            model.set('custom_fields.showOnUserCardFavoriteDiapers', true);
          }
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'checkFavoriteDiapers done');
        };
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'flubber8g:');
        const checkRelationship = function () {
          const relationship = args.model.get("custom_fields.relationship");
          const relationship_with = args.model.get("custom_fields.relationship_with");
          const hasRelationship = typeof relationship !== 'undefined' && relationship !== null && relationship !== '';
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'hasRelationship:');
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, hasRelationship);
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'relationship:');
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, relationship);
          if (hasRelationship && relationship.length === 0 || relationship === null) {
            //denne skulle måske være null i stedet for et array?
            args.model.set("custom_fields.relationship_with", []);
            component.set('hasRelationship', false);
            component.set('isPartnered', false);
            component.set('isNone', true);
            component.set('isMono', false);
            component.set('isVary', false);
            component.set('isTriad', false);
            component.set('isQuad', false);
          } else {
            //activate/remove when not testing
            // if (!hasRelationship) {
            // args.model.set('custom_fields.relationship_with', []);
            // args.model.set('custom_fields.in_relationship_with', []);
            // }

            (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'xzc1');
            //const relationship_with = model.siteSettings.custom_ap_profile_relationships_with;
            (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'xzc2');

            // const isPartnered = args.model.custom_fields.relationship ? (args.model.custom_fields.relationship + '').split('|').filter(val => relationship_with.includes(val)).length > 0 : false;
            const isPartnered = args.model.custom_fields.relationship_with !== null || (args.model.custom_fields.relationship ? (args.model.custom_fields.relationship + '').split('|').filter(val => relationships_with.includes(val)).length > 0 : false);
            (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'xzc3');
            const isNone = hasRelationship ? (args.model.custom_fields.relationship + '').split('|').filter(val => relationships_none.includes(val)).length > 0 : false;
            const isMono = hasRelationship ? (args.model.custom_fields.relationship + '').split('|').filter(val => relationships_mono.includes(val)).length > 0 : false;
            const isVary = hasRelationship ? (args.model.custom_fields.relationship + '').split('|').filter(val => relationships_vary.includes(val)).length > 0 : false;
            const isTriad = hasRelationship ? (args.model.custom_fields.relationship + '').split('|').filter(val => relationships_triad.includes(val)).length > 0 : false;
            const isQuad = hasRelationship ? (args.model.custom_fields.relationship + '').split('|').filter(val => relationships_quad.includes(val)).length > 0 : false;
            (0, _discourse_custom_ap_profile.showLog)(show_debug_info, relationship);
            (0, _discourse_custom_ap_profile.showLog)(show_debug_info, relationships_with);
            (0, _discourse_custom_ap_profile.showLog)(show_debug_info, isPartnered);
            component.set('hasRelationship', hasRelationship);
            component.set('isPartnered', isPartnered);
            component.set('isNone', isNone);
            component.set('isMono', isMono);
            component.set('isVary', isVary);
            component.set('isTriad', isTriad);
            component.set('isQuad', isQuad);
            if (isNone) {
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'is none');
              args.model.set('custom_fields.relationship_with', null);
            } else if (isMono && relationship_with && relationship_with.length > 1) {
              alertMsg('Being ' + relationship + ' means there can only be one partner, please remove ' + (relationship_with.length - 1));
            }

            //args.model.set("hasRelationship", hasRelationship);
          }
          if (hasRelationship) {
            (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'hasRelationship: ' + hasRelationship);
            let er = document.getElementsByClassName("edit-relationship");
            (0, _discourse_custom_ap_profile.showLog)(show_debug_info, er);
            if (typeof er !== 'undefined' && er.length > 0) {
              er[0].childNodes[5].childNodes[1].childNodes[1].childNodes[0].childNodes[4].title = _I18n.default.t("custom_ap_profile.instructions_relationship");
            }
          } else {
            model.set('custom_fields.showOnUserCardRelationship', true);
          }
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'checkRelationship done');
        };
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'flubber8h:');
        const processInRelationWith = function (u) {
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'got-it');
          if (typeof u === 'nothing') (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'u is nothing');else (0, _discourse_custom_ap_profile.showLog)(show_debug_info, u);
          const relationship = u.get("custom_fields.relationship");
          const relationship_with = u.get("custom_fields.relationship_with");
          const in_relationship_with = u.get("custom_fields.in_relationship_with");
          const hasRelationship = typeof relationship !== 'undefined' && relationship !== null && relationship !== '';
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, u.get('custom_fields.show_birthday_to_be_celebrated'));
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, in_relationship_with);
          const isNone = hasRelationship ? (relationship + '').split('|').filter(val => relationships_none.includes(val)).length > 0 : false;
          const isMono = hasRelationship ? (relationship + '').split('|').filter(val => relationships_mono.includes(val)).length > 0 : false;
          const isVary = hasRelationship ? (relationship + '').split('|').filter(val => relationships_vary.includes(val)).length > 0 : false;
          const isTriad = hasRelationship ? (relationship + '').split('|').filter(val => relationships_triad.includes(val)).length > 0 : false;
          const isQuad = hasRelationship ? (relationship + '').split('|').filter(val => relationships_quad.includes(val)).length > 0 : false;
          if (typeof in_relationship_with !== 'undefined') {
            (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'yup');
            //needs to have an exception if the choosen user has selected an open relationship
            // if (u.get('custom_fields.in_relationship_with') !== model.get('username'))
            (0, _discourse_custom_ap_profile.showLog)(show_debug_info, in_relationship_with.includes(model.get('username')));
            (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'isNone:' + isNone);
            (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'isMono:' + isMono);
            (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'isVary:' + isVary);
            (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'isTriad:' + isTriad);
            (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'isQuad:' + isQuad);

            // if (!in_relationship_with.includes(model.get('username')) && !(isMono || isVary || isTriad || isQuad))
            //not perfect, target user should
            //1: either contain source user
            //2: contain no user and be isMono
            //3: be isVary
            //4: be isTriad, and not be full
            //5: be isQuad, and not be full

            let isLegit = false;
            if (in_relationship_with.includes(model.get('username'))) {
              isLegit = true;
            } else if (isMono && in_relationship_with.length === 0) {
              isLegit = true;
            } else if (isVary) {
              isLegit = true;
            } else if (isTriad && in_relationship_with.length < 2) {
              isLegit = true;
            } else if (isQuad && in_relationship_with.length < 3) {
              isLegit = true;
            }

            // if (!(in_relationship_with.includes(model.get('username')) && (isMono || isVary || isTriad || isQuad)))
            if (!isLegit) {
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'should complain and remove');
              //Split target users relationship partners into two arrays, for the use of alertMsg
              let iarw = in_relationship_with;
              let iarw2 = null;
              if (iarw.length > 1) iarw2 = iarw.pop();

              //relationship_with_already_taken
              //"%{user} is in a relationship with %{relationship_with}"
              alertMsg(u.get('username') + ' is in a relationship with ' + iarw.join(', ') + (iarw2 !== null ? ' and ' + iarw2 : ''));

              // theese work, but are too agressive
              // showLog(show_debug_info, 'clearing relationship_with');
              // model.set('custom_fields.relationship_with', []);
              // showLog(show_debug_info, 'clearing in_relationship_with');
              // model.set('custom_fields.in_relationship_with', ['']);
              // showLog(show_debug_info, 'done clearing');

              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'iarw');
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, iarw);
              const ciarw = model.get('custom_fields.in_relationship_with');
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'current list prior to filtering');
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, ciarw);
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'filtering list excluding ' + u.get('username'));
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, ciarw.filter(val => val !== u.get('username')));
              model.set('custom_fields.relationship_with', ciarw.filter(val => val !== u.get('username')));
              model.set('custom_fields.in_relationship_with', model.get('custom_fields.relationship_with'));
            } else {
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'seems legit');
            }
          }

          //if u is in a relationship with another user, the complain about it, and perhaps remove user..

          // model.set('custom_fields.in_relationship_with', model.get('custom_fields.relationship_with');

          // if (typeof u.get('custom_fields.in_relationship_with') === 'undefined')
          // {
          // //definatly allow the request
          // showLog(show_debug_info, u);
          // showLog(show_debug_info, u.id);
          // //showLog(show_debug_info, 'setting custom_fields.in_relationship_with = Array of ' + u.get('id'));
          // showLog(show_debug_info, u.id);
          // //model.set('custom_fields.in_relationship_with', Array(u.get('id')));
          // //model.set('custom_fields.in_relationship_with', [u.get('id')]);
          // model.set('custom_fields.in_relationship_with', model.get('custom_fields.relationship_with');
          // showLog(show_debug_info, model.get('custom_fields.in_relationship_with'));
          // showLog(show_debug_info, model);
          // }
          // else
          // {
          // showLog(show_debug_info, 'cancelling request');
          // model.set('custom_fields.relationship_with', null);
          // }

          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'still here');
        };
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'flubber8i:');
        const checkRelationshipWith = function () {
          // const relationship = args.model.get("custom_fields.relationship");
          // const hasRelationship = relationship !== null;

          // showLog(show_debug_info, 'xzc1');
          //const relationship_with = component.siteSettings.custom_ap_profile_relationships_with;
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'qa1');
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, args);
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, model);
          let relationship = model.get("custom_fields.relationship");
          let relationship_with = model.get("custom_fields.relationship_with");
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'relationship:');
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, typeof relationship);
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, relationship);
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, relationship === '');
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'relationship_with:');
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, relationship_with);
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'qa2');

          // //const relationship_with = model.siteSettings.custom_ap_profile_relationships_with;
          // showLog(show_debug_info, 'xzc2');

          // const isPartnered = args.model.custom_fields.relationship ? (args.model.custom_fields.relationship + '').split('|').filter(val => relationship_with.includes(val)).length > 0 : false;
          // showLog(show_debug_info, 'xzc3');

          // showLog(show_debug_info, relationship);
          // showLog(show_debug_info, relationship_with);
          // showLog(show_debug_info, isPartnered);

          // //store.find('user', 'eviltrout').then(u => showLog(show_debug_info, u.get('avatar_template')));
          //showLog(show_debug_info, model.custom_fields.in_relationship_with);

          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'qa4');
          if (typeof relationship === 'undefined' || relationship === '') {
            args.model.set("custom_fields.in_relationship_with", null);
            args.model.set("custom_fields.relationship_with", null);
            component.set('hasRelationship', false);
            component.set('isPartnered', false);
            component.set('isNone', true);
            component.set('isMono', false);
            component.set('isVary', false);
            component.set('isTriad', false);
            component.set('isQuad', false);
          } else {
            if (typeof relationship_with === 'undefined') {
              const in_relationship_with = model.get('custom_fields.in_relationship_with');
              if (in_relationship_with !== null && in_relationship_with.length > 0 && in_relationship_with[0] !== '') {
                (0, _discourse_custom_ap_profile.showWarn)(show_debug_info, 'copying...');
                (0, _discourse_custom_ap_profile.showLog)(show_debug_info, model.get('custom_fields.in_relationship_with'));
                model.set('custom_fields.relationship_with', model.get('custom_fields.in_relationship_with'));
              }
            } else {
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, relationship_with);
              if (typeof relationship_with !== null) {
                (0, _discourse_custom_ap_profile.showWarn)(show_debug_info, 'not yet');
              } else if (relationship_with.length === 0) {
                (0, _discourse_custom_ap_profile.showWarn)(show_debug_info, 'setting rel to null');
                args.model.set("custom_fields.relationship_with", null);
                // args.model.set("custom_fields.in_relationship_with", null);
              }
            }
            if (model.get('custom_fields.in_relationship_with') !== model.get('custom_fields.relationship_with')) {
              (0, _discourse_custom_ap_profile.showWarn)(show_debug_info, 'transfering');
              model.set('custom_fields.in_relationship_with', model.get('custom_fields.relationship_with'));
            }
            const hasRelationshipWith = typeof relationship_with !== 'undefined' && relationship_with !== null && relationship_with !== '';
            (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'qa3');
            component.set('hasRelationshipWith', hasRelationshipWith);
            (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'qa5');

            // let ufilter = '';

            // model.store.findFiltered('user', { ufilter }).then(list => {
            // showLog(show_debug_info, list);
            // });

            (0, _discourse_custom_ap_profile.showWarn)(show_debug_info, '*** found user by name ***');
            //showLog(show_debug_info, model.store.find('user', 'test2'));
            // showLog(show_debug_info, model.store.find('user', 'santa'));

            //if model.custom_fields.relationship_with is an array
            //model.store.find('user', model.custom_fields.relationship_with[0]).then(u => showLog(show_debug_info, u));
            //model.store.find('user', model.custom_fields.relationship_with[0]).then(u => relationship_with = u);
            //model.store.find('user', model.custom_fields.relationship_with[0]).then(u => showLog(show_debug_info, u.get('custom_fields.show_birthday_to_be_celebrated')));

            //model.store.findAll('user').then(u => showLog(show_debug_info, u));

            // return this.store.findFiltered("topicList", {
            // filter: `topics/webinar-registrations/${this.modelFor("user").get(
            // "username_lower"
            // )}`
            // });

            (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'qa8');

            // needs to loop through all selected users
            // if (typeof model.custom_fields.relationship_with !== 'undefined' && model.custom_fields.relationship_with !== null && model.custom_fields.relationship_with.length > 0) {
            if (typeof relationship_with !== 'undefined' && relationship_with !== null && relationship_with.length > 0) {
              //needs to check all, not just [0]

              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, relationship_with);
              model.custom_fields.relationship_with.forEach(u => {
                (0, _discourse_custom_ap_profile.showWarn)(show_debug_info, 'u');
                (0, _discourse_custom_ap_profile.showWarn)(show_debug_info, u);
                model.store.find('user', u).then(u => processInRelationWith(u));
              });
            }

            //model.store.find('user', model.custom_fields.relationship_with[0]).then(u => relationship_with = u);
            //let relationship_with = model.store.find('user', model.custom_fields.relationship_with[0]);
            (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'getting tired yet?');
            // showLog(show_debug_info, relationship_with);
            // let in_relationship_with = [];

            // showLog(show_debug_info, relationship_with.custom_fields);
            // showLog(show_debug_info, relationship_with._result);

            // showLog(show_debug_info, relationship_with._result.custom_fields);
            // showLog(show_debug_info, 'in_relationship_with' in relationship_with._result.custom_fields);

            // in_relationship_with = Array(relationship_with._result.id);

            //if _state = 1
            //if (relationship_with !== null && typeof relationship_with._result.get('custom_fields.in_relationship_with') !== 'undefined')
            // {
            //showLog(show_debug_info, 'look santa');
            //let in_relationship_with2 = in_relationship_with._result.custom_fields.in_relationship_with;
            //showLog(show_debug_info, 'in_relationship_with2: ' + in_relationship_with2);

            //alertMsg a message will be sent to x to make it official
            // }
            //store.find('user', model.custom_fields.in_relationship_with).then(u => showLog(show_debug_info, u));

            //args.model.set("custom_fields.in_relationship_with", component.);

            // component.set('hasRelationship', hasRelationship);
            // component.set('isPartnered', isPartnered);

            //args.model.set("hasRelationship", hasRelationship);

            //***************** needs to be reactivated again...
            // showLog(show_debug_info, 'hasRelationshipWith: ' + hasRelationshipWith);
            // if (hasRelationshipWith) {
            // let er = document.getElementsByClassName("edit-relationship");
            // showLog(show_debug_info, er);
            // if (typeof er !== 'undefined' && er.length > 0) {
            // er[0].childNodes[12].childNodes[1].childNodes[1].childNodes[0].title=I18n.t("custom_ap_profile.instructions_relationship_with");
            // }
            // }

            if (!hasRelationshipWith) {
              model.set('custom_fields.showOnUserCardRelationshipWith', true);
            }
          }
        };
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'flubber8j:');
        const checkSexualOrientation = function () {
          //store.find('user', 'ivi').then(u => showLog(show_debug_info, u));

          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'flubber8ja:');
          let sexualOrientation = args.model.get("custom_fields.sexualOrientation");
          //let hasSexualOrientation = sexualOrientation !== null && sexualOrientation !== "";

          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'flubber8jb:');
          let hasSexualOrientation = typeof sexualOrientation !== 'undefined' && sexualOrientation !== null && sexualOrientation !== "";
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'flubber8jc:');
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, sexualOrientation);
          if (hasSexualOrientation) {
            if (sexualOrientation.length === 1) {
              if (sexualOrientation[0] === '') {
                model.set("custom_fields.sexualOrientation", null);
                hasSexualOrientation = false;
              }
            }
          }
          component.set("hasSexualOrientation", hasSexualOrientation);
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'flubber8jd:');
          if (hasSexualOrientation) {
            (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'flubber8je:');
            let eso = document.getElementsByClassName("edit-sexual_orientation");
            (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'flubber8jf:');
            if (typeof eso !== 'undefined' && eso.length > 0) {
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'flubber8jg:');
              eso[0].childNodes[5].childNodes[1].childNodes[1].childNodes[0].childNodes[4].title = _I18n.default.t("custom_ap_profile.instructions_sexual_orientation");
            }
          } else {
            model.set('custom_fields.showOnUserCardSexualOrientation', true);
          }
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'flubber8je:');
        };
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'flubber8k:');
        const checkLookingFor = function () {
          let lookingFor = args.model.custom_fields.lookingFor;
          let hasLookingFor = typeof lookingFor !== 'undefined' && lookingFor !== null && lookingFor !== '';
          component.set('hasLookingFor', hasLookingFor);
          let lastValue = lookingFor.pop();
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'lastValue: ' + lastValue);
          if (typeof lastValue !== 'undefined') {
            component.set("isLooking", true);

            //if (args.model.custom_fields.lookingFor.includes('nothing')) {
            if (lastValue === 'nothing') {
              //console.clear();
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'trying to clean looking for list');
              args.model.set("custom_fields.lookingFor", args.model.custom_fields.lookingFor.filter(val => val === 'nothing'));
              //isLooking = false
              //set model.custom_fields.showOnUserCardLookingFor = true
              component.set("isLooking", false);
              args.model.set("custom_fields.showOnUserCardLookingFor", true);
            } else if (lastValue === 'partner' && args.model.custom_fields.lookingFor.includes('bf') && args.model.custom_fields.lookingFor.includes('gf')) {
              args.model.set("custom_fields.lookingFor", args.model.custom_fields.lookingFor.filter(val => val !== 'bf' && val !== 'gf'));
              alertMsg(_I18n.default.t("custom_ap_profile.preference_alert_partner_bf_and_gf")); //"You have selected partner while also having selected bf and gf, bf and gf has been removed."
            } else if (lastValue === 'bf' && args.model.custom_fields.lookingFor.includes('partner') && args.model.custom_fields.lookingFor.includes('gf')) {
              args.model.set("custom_fields.lookingFor", args.model.custom_fields.lookingFor.filter(val => val !== 'partner'));
              alertMsg(_I18n.default.t("custom_ap_profile.preference_alert_bf_gf_and_partner")); //"You have selected bf while also having selected gf and partner, partner has been removed."
            } else if (lastValue === 'gf' && args.model.custom_fields.lookingFor.includes('bf') && args.model.custom_fields.lookingFor.includes('partner')) {
              args.model.set("custom_fields.lookingFor", args.model.custom_fields.lookingFor.filter(val => val !== 'partner'));
              alertMsg(_I18n.default.t("custom_ap_profile.preference_alert_gf_bf_and_partner")); //"You have selected gf while also having selected bf and partner, partner has been removed."
            } else {
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'a: ' + args.model.get("custom_fields.lookingFor").toString());
              args.model.set("custom_fields.lookingFor", args.model.custom_fields.lookingFor.filter(val => val !== 'nothing'));
              (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'b: ' + args.model.get("custom_fields.lookingFor").toString());
            }
            if (lastValue === 'bs' && args.model.custom_fields.lookingFor.includes('ls') || lastValue === 'ls' && args.model.custom_fields.lookingFor.includes('bs')) {
              args.model.set("custom_fields.lookingFor", args.model.custom_fields.lookingFor.filter(val => val !== 'bs' && val !== 'ls'));
              args.model.custom_fields.lookingFor.push('sister');
              alertMsg(_I18n.default.t("custom_ap_profile.preference_alert_ls_bs_and_sister")); //"You have selected ls while also having selected bs and sister, bs and ls has been removed."
            }
            if (lastValue === 'bb' && args.model.custom_fields.lookingFor.includes('lb') || lastValue === 'lb' && args.model.custom_fields.lookingFor.includes('bb')) {
              args.model.set("custom_fields.lookingFor", args.model.custom_fields.lookingFor.filter(val => val !== 'bb' && val !== 'lb'));
              args.model.custom_fields.lookingFor.push('brother');
              alertMsg(_I18n.default.t("custom_ap_profile.preference_alert_lb_bb_and_brother")); //"You have selected lb while also having selected bb and brother, bb and lb has been removed."
            } else {
              args.model.custom_fields.lookingFor.push(lastValue);
            }
            (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'custom_fields.lookingFor:');
            (0, _discourse_custom_ap_profile.showLog)(show_debug_info, args.model.custom_fields.lookingFor);
            (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'c: ' + args.model.get("custom_fields.lookingFor").toString());
            if (hasLookingFor) {
              let elf = document.getElementsByClassName("edit-looking_for");
              // showLog(show_debug_info, elf);
              // showLog(show_debug_info, elf[0]);
              // showLog(show_debug_info, elf[0].childNodes);
              // showLog(show_debug_info, elf[0].childNodes[5]);
              // showLog(show_debug_info, elf[0].childNodes[5].childNodes);
              // showLog(show_debug_info, elf[0].childNodes[5].childNodes[1]);
              // showLog(show_debug_info, elf[0].childNodes[5].childNodes[1].childNodes);
              // showLog(show_debug_info, elf[0].childNodes[5].childNodes[1].childNodes[1]);
              // showLog(show_debug_info, elf[0].childNodes[5].childNodes[1].childNodes[1].childNodes);
              // showLog(show_debug_info, elf[0].childNodes[5].childNodes[1].childNodes[1].childNodes[0]);
              // showLog(show_debug_info, elf[0].childNodes[5].childNodes[1].childNodes[1].childNodes[0].childNodes);
              // showLog(show_debug_info, elf[0].childNodes[5].childNodes[1].childNodes[1].childNodes[0].childNodes[4]);

              if (typeof elf !== 'undefined' && elf.length > 0) {
                elf[0].childNodes[5].childNodes[1].childNodes[1].childNodes[0].title = _I18n.default.t("custom_ap_profile.instructions_looking_for");
                // showLog(show_debug_info, );
              }
            } else {
              model.set('custom_fields.showOnUserCardLookingFor', true);
            }
          } else {
            args.model.set("custom_fields.lookingFor", null);
            component.set("isLooking", false);
          }
        };
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'flubber9:');
        var hasSocialNetwork = [];
        const checkSocial_networks = function () {
          //model.custom_fields.socialNetworks
          args.model.set("socialNetworks", component.socialNetworks);
          let socialNetworks = args.model.get("custom_fields.socialNetworks");
          let hasSocialNetworks = typeof socialNetworks !== 'undefined' && socialNetworks !== null && socialNetworks.length > 0 && socialNetworks[0].length > 0;
          component.set("hasSocialNetworks", hasSocialNetworks);

          // showLog(show_debug_info, 'component:');
          // showLog(show_debug_info, component);

          // showLog(show_debug_info, 'component.custom_fields.socialNetworks:');
          // showLog(show_debug_info, component.custom_fields.socialNetworks);
          // hasSocialNetwork = [];

          // this.siteSettings.custom_ap_profile_social_networks.split('|').forEach(function(social) {
          // // hasSocialNetwork[social] = component.custom_fields.socialNetworks.contains(social);

          // // model.set("custom_fields." + social, model.custom_fields[social]);
          // // // model.set(social, model.custom_fields.split('|').includes(social) ? model.custom_fields[social] : null);
          // });
          // component.custom_fields.socialNetworks.forEach(function(social) {
          // hasSocialNetwork[social] = true;
          // });
          // if (component.custom_fields.socialNetworks) {
          // args.model.set("hasSocialDiscord", component.custom_fields.socialNetworks.includes('discord'));
          // args.model.set("hasSocialDiscourse", component.custom_fields.socialNetworks.includes('discourse'));
          // args.model.set("hasSocialFacebook", component.custom_fields.socialNetworks.includes('facebook'));
          // args.model.set("hasSocialFetlife", component.custom_fields.socialNetworks.includes('fetlife'));
          // args.model.set("hasSocialFlickr", component.custom_fields.socialNetworks.includes('flickr'));
          // args.model.set("hasSocialInstagram", component.custom_fields.socialNetworks.includes('instagram'));
          // args.model.set("hasSocialSnapchat", component.custom_fields.socialNetworks.includes('snapchat'));
          // args.model.set("hasSocialSkype", component.custom_fields.socialNetworks.includes('skype'));
          // args.model.set("hasSocialTelegram", component.custom_fields.socialNetworks.includes('telegram'));
          // args.model.set("hasSocialTumblr", component.custom_fields.socialNetworks.includes('tumblr'));
          // args.model.set("hasSocialTwich", component.custom_fields.socialNetworks.includes('twitch'));
          // args.model.set("hasSocialYoutube", component.custom_fields.socialNetworks.includes('youtube'));
          // }

          social_networks_list.forEach(social => {
            //        args.model.set("hasSocialYoutube", component.custom_fields.socialNetworks.includes('youtube'));
            if (component.custom_fields.socialNetworks !== undefined) {
              args.model.set("hasSocial" + social.value.charAt(0).toUpperCase() + social.value.slice(1), component.custom_fields.socialNetworks.includes(social.value));
              component.set("hasSocial" + social.value.charAt(0).toUpperCase() + social.value.slice(1) + "Name", args.model.get("custom_fields." + social.value) !== undefined && args.model.get("custom_fields." + social.value) !== '');
            }
          });
        };
        const checkHolidaysRegion = function () {
          const holidaysRegion = model.get('custom_fields.holidays-region');
          const isDKRegion = typeof holidaysRegion === 'undefined' || holidaysRegion === null || holidaysRegion === '' || holidaysRegion === 'dk';
          if (!isDKRegion) {
            model.set('custom_fields.countryDistrict', null);
          }
          component.set('isDKRegion', isDKRegion);
        };
        (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'flubber10:');
        if (component.get('isCAPPAvailable')) {
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'flubber10a:');
          checkGenderPrefs();
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'flubber10b:');
          checkAgeplayRoles();
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'flubber10c:');
          checkCareAge();
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'flubber10d:');
          checkPlayAge();
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'flubber10e:');
          checkDiaperChange();
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'flubber10eb:');
          checkDiaperUsage();
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'flubber10f:');
          checkWaist();
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'flubber10fb:');
          checkFavoriteDiapers();
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'flubber10g:');
          checkInterests();
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'flubber10h:');
          checkRelationship();
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'flubber10i:');
          checkRelationshipWith();
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'flubber10j:');
          checkSexualOrientation();
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'flubber10j2:');
          checkSocial_networks();
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'flubber10k:');
          checkHolidaysRegion();
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'flubber10l:');
          //updateLocation();
          //                                                                                showLog(show_debug_info, 'flubber10l:');
          component.addObserver("model.custom_fields.care_age", checkCareAge);
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'flubber10m:');
          component.addObserver("model.custom_fields.play_age", checkPlayAge);
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'flubber10n:');
          component.addObserver("model.custom_fields.ageplayRoles", checkAgeplayRoles);
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'flubber10o:');
          // component.addObserver("model.location", updateLocation);
          component.addObserver("model.location", checkLocation);
          // showLog(show_debug_info, 'flubber10p:');
          // component.addObserver("model.custom_fields.countryDistrict", updateLocation);
          component.addObserver("model.custom_fields.countryDistrict", checkCountryDistrict);
          // showLog(show_debug_info, 'flubber10q:');
          component.addObserver("model.custom_fields.diaper_change", checkDiaperChange);
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'flubber10q1:');
          component.addObserver("model.custom_fields.diaper_usage", checkDiaperUsage);
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'flubber10q2:');
          component.addObserver("model.custom_fields.waist", checkWaist);
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'flubber10q3:');
          component.addObserver("model.custom_fields.favorite_diapers", checkFavoriteDiapers);
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'flubber10r:');
          component.addObserver("model.custom_fields.gender", checkGenderPrefs);
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'flubber10s:');
          component.addObserver("model.name", checkName);
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'flubber10t:');
          component.addObserver("model.custom_fields.handicap", checkHandicap);
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'flubber10u:');
          component.addObserver("model.custom_fields.interests", checkInterests);
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'flubber10v:');
          component.addObserver("model.custom_fields.lookingFor", checkLookingFor);
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'flubber10w:');
          component.addObserver("model.custom_fields.relationship", checkRelationship);
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'flubber10x:');
          component.addObserver("model.custom_fields.relationship_with", checkRelationshipWith);
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'flubber10y:');
          component.addObserver("model.custom_fields.sexualOrientation", checkSexualOrientation);
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'flubber10z:');
          component.addObserver("model.custom_fields.socialNetworks", checkSocial_networks);
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'flubber10x2:');
          component.addObserver("model.custom_fields.sortInterests", checkSortInterests);
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'flubber10x3:');
          //if discourse-calendar is enabled
          if (model.siteSettings.calendar_enabled) {
            component.addObserver("model.custom_fields.holidays-region", checkHolidaysRegion);
          }
          social_networks_list.forEach(social => {
            component.addObserver("model.custom_fields." + social.value, checkSocial_networks);
          });
          (0, _discourse_custom_ap_profile.showLog)(show_debug_info, 'flubber11:');
        }
        //        args.model.set("hasSocialDiscord", component.custom_fields.socialNetworks.includes('discord'));

        //
        //    component.set("userBirthdateText", userBirthdateText(this.currentUser, showYear));
      }
    }
    // actions: {
    // save() {
    // this.set("saved", false);

    // alert(this.newNameInput);
    // this.model.setProperties({
    // name: this.newNameInput,
    // title: this.newTitleInput,
    // primary_group_id: this.newPrimaryGroupInput,
    // flair_group_id: this.newFlairGroupId,
    // status: this.newStatus,
    // });

    // return this.model
    // .save(this.saveAttrNames)
    // .then(() => this.set("saved", true))
    // .catch(popupAjaxError);
    // },
    // },
  }, [["method", "updateDiscord", [_object.action]], ["method", "updateFetlifeId", [_object.action]]]);
});