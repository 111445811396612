define("discourse/plugins/discourse-custom-ap-profile/discourse/connectors/user-card-post-names/user-card-cakeday", ["exports", "discourse/plugins/discourse-custom-ap-profile/discourse/lib/cakeday"], function (_exports, _cakeday) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    setupComponent(args, component) {
      // component.set("isCakeday", cakeday(args.user.get("created_at")));
      // component.set(
      // "isBirthday",
      // birthday(args.user.get("date_of_birth"))
      // );
      // component.set("cakedayTitle", cakedayTitle(args.user, this.currentUser));
      // component.set(
      // "birthdayTitle",
      // birthdayTitle(args.user, this.currentUser)
      // );
      const isStaff = this.currentUser && this.currentUser.staff;
      const isAdmin = this.currentUser && this.currentUser.admin;
      const isMe = this.currentUser && this.currentUser.id === args.user.id;
      const isAllowedByGroup = true;

      // console.log(args.user);
      // console.log(args.user.get("custom_fields"));
      // console.log("groups_fullbirthday_visible:");
      // console.log(args.user.get("groups_fullbirthday_visible"));
      //const isTestGroups = args.user.
      //const encryptGroups = siteSettings.encrypt_groups
      // .split("|")
      // .map((groupName) => groupName.toLowerCase());
      // const groups = (user.groups || []).map((group) => group.name.toLowerCase());

      // if (groups.some((group) => encryptGroups.includes(group))) {
      // return true;
      // }

      if (isMe || isStaff || isAllowedByGroup) {
        component.set("userAgeTitle", (0, _cakeday.userAgeTitle)(args.user));
        component.set("userBirthdateTitle", (0, _cakeday.userBirthdateTitle)(args.user));
      }
      //component.set("userGenderIcon", 'baby');
    }
  };
});