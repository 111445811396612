define("discourse/plugins/discourse-custom-ap-profile/discourse/routes/discourse-custom-ap-profile-index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Route for the path `/profiles` as defined in `../discourse-custom-ap-profile-route-map.js.es6`.
   */
  var _default = _exports.default = Discourse.Route.extend({
    renderTemplate() {
      // Renders the template `../templates/discourse-custom-ap-profile.hbs`
      this.render('discourse-custom-ap-profile');
    }
  });
});