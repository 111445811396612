define("discourse/plugins/discourse-custom-ap-profile/discourse/connectors/user-card-additional-buttons/profile_edit", ["exports", "@ember/component", "I18n", "discourse/models/user", "discourse/plugins/discourse-custom-ap-profile/discourse/lib/discourse_custom_ap_profile"], function (_exports, _component, _I18n, _user, _discourse_custom_ap_profile) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    setupComponent(args, component) {
      component.set("isCAPPAvailable", (0, _discourse_custom_ap_profile.isCAPPAvailable)(this.currentUser));
      if (component.isCAPPAvailable) {
        const isStaff = this.currentUser && this.currentUser.staff;
        const isAdmin = this.currentUser && this.currentUser.admin;
        const isSelf = args.user && this.currentUser && args.user.id === this.currentUser.id;
        component.set("canEditProfile", isStaff || isSelf);
      }
    }
  };
});