define("discourse/plugins/discourse-custom-ap-profile/discourse/connectors/composer-fields/private-message-warning", ["exports", "I18n", "discourse/models/category", "discourse/plugins/discourse-custom-ap-profile/discourse/lib/discourse_custom_ap_profile", "discourse-common/lib/get-owner", "@ember/template", "@ember/object", "@ember/runloop"], function (_exports, _I18n, _category, _discourse_custom_ap_profile, _getOwner, _template, _object, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    setupComponent(args, component) {
      component.set("isCAPPAvailable", (0, _discourse_custom_ap_profile.isCAPPAvailable)(this.currentUser));
      const topic = args.topic;
      if (component.isCAPPAvailable && topic !== null) {
        const model = args.model;
        const siteSettings = component.siteSettings;
        const isAdmin = this.currentUser.admin;
        const isModerator = this.currentUser.moderator;
        const isSelf = args.model && this.currentUser && args.model.id === this.currentUser.id;
        const isStaff = this.currentUser.staff;
        const checkLookingFor = function (u) {
          console.log('u:');
          // console.log(u);
          console.log(u.get('custom_fields.lookingFor'));
          if (typeof u.get('custom_fields.lookingFor') !== 'undefined') {
            component.set("isUserNotLooking", true);
            console.warn('a user is not looking');
            if (model.targetRecipientsArray.length > 1) {
              component.set("lookingForWarning", 'custom_ap_profile.looking_for_warning_not_looking.other');
            } else {
              component.set("lookingForWarning", 'custom_ap_profile.looking_for_warning_not_looking.one');
            }
          }
        };
        const checkTargetRecipientsArray = function () {
          console.log('msg model');
          console.log(model);
          component.set("isUserNotLooking", false);
          model.targetRecipientsArray.forEach(u => {
            if (!component.get("isUserNotLooking")) {
              console.log('trying ' + u.name);
              console.log(u);
              model.store.find('user', u.name).then(u => checkLookingFor(u));
            }
          });
        };
        const cTR = function () {
          console.log('c.targetRecipients');
        };
        const TR = function () {
          console.log('targetRecipients');
        };
        const isPrivateMessage = model.get('action') === 'privateMessage';
        if (isPrivateMessage) {
          component.addObserver("component.model.targetRecipients", cTR);
          component.addObserver("model.targetRecipients", TR);
          component.addObserver("model.targetRecipientsArray", checkTargetRecipientsArray);
          //component.addObserver("model.categoryId", checkCategory);
          checkTargetRecipientsArray();
        }
        const CAPPEnabledGroups = siteSettings.custom_ap_profile_enabled_groups;
        component.setProperties({
          isPrivateMessage
        });
        console.log(component);
      }
    }
  };
});